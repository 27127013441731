import { Dialog, IconButton, Box, Typography, Stack, Button } from "@mui/material";
// import { DeskWord } from "components/DeskWord";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";
import { EXTERNAL_HREFS } from "constants";
import { useAnalytics } from "providers";

export const DialogMarketplace = ({ isMarketplaceDialogOpened, setIsMarketplaceDialogOpened }) => {
  const currentTheme = useTheme();
  const themeMode = currentTheme.palette.mode;
  const analytics = useAnalytics();

  return (
    <Dialog open={isMarketplaceDialogOpened} onClose={() => setIsMarketplaceDialogOpened(false)}>
      <Box pt={2} pr={2} width="100%" display="flex" justifyContent="right">
        <IconButton color="contrastInvert" onClick={() => setIsMarketplaceDialogOpened(false)}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box
        px={{ xs: 2, sm: 5 }}
        pb={5}
        pt={0}
        sx={{ maxWidth: "557px" }}
        display="flex"
        alignItems="center"
        flexDirection="column"
      >
        {/* <Typography variant="h1" textAlign="center" pb={2}>
          Use your <DeskWord type="standalone" />™
        </Typography> */}
        <Typography variant="h1" textAlign="center" pb={2}>
          Redeem real rewards
        </Typography>
        <Stack spacing={{ xs: 3, sm: 6 }} py={2} textAlign="center" maxWidth="90%">
          <Box display="flex" justifyContent="center">
            <img src={`/market.png`} alt="Shopping Bag" height="140" />
          </Box>
          <Typography mt={2} textAlign="left">
            Use the DESK™ you've earned to pick up something special - community PFPs, featured
            artist NFTs and more!
          </Typography>
          <Box display="flex" justifyContent="center">
            <Stack spacing={1} minWidth={260}>
              <Button
                sx={{
                  width: "100%",
                  whiteSpace: "nowrap",
                  "@media(max-width:400px)": {
                    transform: "scale(0.9)",
                  },
                }}
                variant="outlined"
                color="contrastInvert"
                component="a"
                margin="narrow"
                href={EXTERNAL_HREFS.nftmarket}
                target="_blank"
                onClick={() => {
                  setIsMarketplaceDialogOpened(false);
                  if (analytics) {
                    analytics.track("marketplace_type_click", {
                      marketplace_type: "NFT Market",
                      link_position: "",
                    });
                  }
                }}
              >
                NFT Marketplace by&nbsp;
                <img
                  src={themeMode === "dark" ? "/nftify-logo-contrast.png" : "/nftify-logo.png"}
                  alt="NFTify logo"
                  width="69"
                  height="21"
                />
              </Button>
              <Button
                sx={{
                  width: "100%",
                  whiteSpace: "nowrap",
                  "@media(max-width:400px)": {
                    transform: "scale(0.9)",
                  },
                }}
                variant="outlined"
                color="contrastInvert"
                component="a"
                margin="narrow"
                href={EXTERNAL_HREFS.merch}
                target="_blank"
                onClick={() => {
                  setIsMarketplaceDialogOpened(false);
                  if (analytics) {
                    analytics.track("marketplace_type_click", {
                      marketplace_type: "Merch",
                      link_position: "",
                    });
                  }
                }}
                disabled
              >
                Merch Store (Coming Soon)
              </Button>
            </Stack>
          </Box>
        </Stack>
      </Box>
    </Dialog>
  );
};

export default DialogMarketplace;
