import StorefrontIcon from "@mui/icons-material/Storefront";
import { Box, Button, Grid, Stack, Typography, Link } from "@mui/material";
import { styled } from "@mui/system";

import { EXTERNAL_HREFS } from "constants";
import { Discord } from "icons";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

export const ConsensusDash = () => {
  return (
    <>
      <Box display="flex" justifyContent="center" pb={3}>
        <Grid container spacing={6} maxWidth={1200} p={{ xs: 2, sm: 4, md: 8 }}>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                width: "100%",
                height: "100%",
                backgroundImage: "url(/UpcomingEvent.png)",
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
            ></Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h1" pb={2}>
              DESK™ for Consensus 2022
            </Typography>
            <Typography pb={3} lineHeight={2}>
              DESK™ is the social fuel that propels attendees through their Consensus experience. It
              doesn't matter if you attend a panel discussion, a keynote speech, or visit the DAO
              House. Everywhere you go, you'll have the opportunity to claim DESK™ rewards, so
              you'll have what you need to keep exploring.
            </Typography>
            <Button
              variant="outlined"
              color="contrastInvert"
              endIcon={<ArrowForwardIcon />}
              component="a"
              href={EXTERNAL_HREFS["exploreabout"]}
              target="_blank"
            >
              Explore
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h3" display="flex" alignItems="center" fontSize={30}>
              <StorefrontIcon fontSize="inherit" /> &nbsp; Marketplace
            </Typography>
            <Typography py={2}>Buy NFTs with DESK™</Typography>
            <Box display="flex" justifyContent="space-between">
              <MarketItem p={1} component="a" href={EXTERNAL_HREFS.nftmarket} target="_blank">
                <Stack>
                  <img src={`/nft_1.gif`} alt="DESK™ Marketplace" />
                  <Box display="flex" alignItems="center" justifyContent="center" py={1}>
                    {/* <MarketCoin>
                      <Desk fontSize="inherit" />
                    </MarketCoin> */}
                    <Typography variant="h4" pt={1}>
                      Collect with DESK™
                    </Typography>
                  </Box>
                </Stack>
              </MarketItem>
              <MarketItem
                alter="alter"
                p={1}
                component="a"
                href={EXTERNAL_HREFS.nftmarket}
                target="_blank"
              >
                <Stack>
                  <img src={`/nft_2.png`} alt="DESK™ Marketplace" />
                  <Box display="flex" alignItems="center" justifyContent="center" py={1}>
                    {/* <MarketCoin>
                      <Desk fontSize="inherit" />
                    </MarketCoin> */}
                    <Typography variant="h4" pt={1}>
                      Collect with DESK™
                    </Typography>
                  </Box>
                </Stack>
              </MarketItem>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h3" display="flex" alignItems="center" fontSize={30}>
              <Discord fontSize="inherit" /> &nbsp; Community
            </Typography>
            <Typography py={2}>Use DESK™ to access our server</Typography>
            <Stack>
              <Balloon
                mb={3}
                py={2}
                px={3}
                alter="alter"
                component="a"
                href={EXTERNAL_HREFS["discord"]}
                target="_blank"
              >
                Claimed my DESK™ Key! How do I see the token-gated channels?
              </Balloon>
              <Balloon
                my={1}
                py={2}
                pl={2}
                pr={4}
                component="a"
                href={EXTERNAL_HREFS["discord"]}
                target="_blank"
              >
                Go to #desk-verified channel, hit the "Let's go!" button, and connect your wallet!
              </Balloon>
              <Balloon
                mt={3}
                p={1}
                px={3}
                alter="alter"
                display="flex"
                alignItems="center"
                fontWeight={700}
                component="a"
                href={EXTERNAL_HREFS["discord"]}
                target="_blank"
              >
                Join the conversation &nbsp; <ArrowForwardIcon />
              </Balloon>
            </Stack>
          </Grid>
        </Grid>
      </Box>
      <Box
        width="300%"
        sx={{ marginLeft: "-100%", marginRight: "-100%" }}
        display="flex"
        justifyContent="center"
      >
        <Box width="100vw">
          <Box bgcolor="#FD76FA" display="flex" flexDirection="column" alignItems="center" p={3}>
            <Typography variant="h3" pb={2}>
              Need some help?
            </Typography>
            <Button
              variant="contained"
              size="large"
              component="a"
              href={EXTERNAL_HREFS["help"]}
              target="_blank"
              sx={{
                bgcolor: "white.main",
                color: "#FD76FA",
                borderRadius: "31px",
                fontSize: "1.5rem",
                width: 220,
                border: "2px solid #FD76FA",
                "&:hover": {
                  bgcolor: "#FD76FA",
                  color: "white.main",
                  border: "2px solid #ffffff",
                },
              }}
            >
              Get support
            </Button>
          </Box>
          <ConsensusConsensusConsensus />
        </Box>
      </Box>
    </>
  );
};

// const MarketCoin = styled(Box)`
//   border-radius: 50%;
//   display: inline-block;
//   font-size: 30px;
//   line-height: 30px;
//   width: 30px;
//   height: 30px;
//   color: #262626;
//   background-color: #f8bf1e;
// `;

const MarketItem = styled(Box)`
  background-color: rgba(255, 255, 255, 0.3);
  cursor: pointer;
  border-radius: 15px;
  box-shadow: 0px 0px 10px 5px transparent;
  transition: 0.5s;
  outline: 2px solid transparent;
  transform: translateY(0) scale(1);
  width: 48%;
  color: #ffffff;
  text-decoration: none;
  img {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }
  &:hover {
    box-shadow: 0px 0px 10px 5px #5865f2;
    outline: 2px solid #5865f2;
    transform: translateY(-10px) scale(1.1);
    background-color: rgba(0, 0, 0, 0.3);
  }
`;

const Balloon = styled(Typography)`
  background-color: #fff;
  color: #242424;
  cursor: pointer;
  border-radius: 15px;
  border: 1px solid #5865f2;
  filter: drop-shadow(${(props) => (props.alter ? "3" : "-3")}px 3px 0 #5865f2);
  position: relative;
  transition: 0.5s;
  transform: translateY(0) scale(1);
  text-decoration: none;
  text-align: ${(props) => (props.alter ? "left" : "right")};
  &:after {
    content: "";
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 10px solid transparent;
    border-top: 30px solid #fff;
    position: absolute;
    bottom: -20px;
    ${(props) => (props.alter ? "left" : "right")}: 10px;
    transform: rotate(${(props) => (props.alter ? "45" : "-45")}deg);
    transition: 0.5s;
  }
  &:hover {
    background-color: #5865f2;
    color: #fff;
    transform: translateY(-10px) scale(1.1);
    &:after {
      border-top: 30px solid #5865f2;
    }
  }
`;

const ConsensusConsensusConsensus = styled("div")`
  background-image: url("/ConsensusConsensusConsensus.png");
  background-position: center;
  background-size: contain;
  height: 425px;
  width: 100%;
`;
