import { Box } from "@mui/material";


const MagicLinkError = ({message}) => {
  return (<>
    <Box width="100%" sx={{ textAlign: "center" }}>
      <div>
        <h1>Oops!</h1>
        {message && <h3>{message}</h3>}
        <h4>That magic link is no longer valid.</h4>
        <p>The magic link has either expired or already been used.</p>
        <p>You can close this window or <a href="/">click here</a> to attempt to view your account.</p>
      </div>
    </Box>
  </>);
};

export default MagicLinkError;
