import { STAGE } from "./constants";
import { Wallets } from "icons";

const robotsSet = (robotsContent) => {
  const robotsTag = document.querySelector("meta[name='robots']");
  if (robotsTag) {
    robotsTag.setAttribute("content", robotsContent);
  } else {
    const meta = document.createElement("meta");
    meta.setAttribute("name", "robots");
    meta.setAttribute("content", robotsContent);
    document.head.appendChild(meta);
  }
};

const robotsDefault = () => {
  const robotsTag = document.querySelector("meta[name='robots']");
  if (robotsTag) {
    robotsTag.remove();
  }
};

const canonicalSet = (canonicalHref = window.location.href) => {
  const canonicalTag = document.querySelector("link[rel='canonical']");
  if (canonicalTag) {
    canonicalTag.setAttribute("href", canonicalHref);
  } else {
    const link = document.createElement("link");
    link.setAttribute("rel", "canonical");
    link.setAttribute("href", canonicalHref);
    document.head.appendChild(link);
  }
};

const canonicalRemove = () => {
  const canonicalTag = document.querySelector("link[rel='canonical']");
  if (canonicalTag) {
    canonicalTag.remove();
  }
};

const robotsBlock = () => {
  robotsSet("noindex,nofollow,noimageindex,noarchive,nocache,nosnippet");
};

const canonicalDefault = () => {
  canonicalSet(window.location.href);
};

const setupNoSEO = () => {
  robotsBlock();
  canonicalRemove();
};

const setupRedirectSEO =
  STAGE === "production"
    ? () => {
        robotsBlock();
        canonicalDefault();
      }
    : setupNoSEO;

const setupSEO =
  STAGE === "production"
    ? () => {
        robotsDefault();
        canonicalDefault();
      }
    : setupNoSEO;

const setupAuthSEO = () => {
  canonicalDefault();

  if (window.location.pathname === "/login") {
    robotsDefault();
  } else {
    robotsBlock();
  }
};

const textFileSize = (number) => {
  if (number < 1024) {
    return `${number} bytes`;
  } else if (number >= 1024 && number < 1048576) {
    return `${(number / 1024).toFixed(1)} KB`;
  } else if (number >= 1048576) {
    return `${(number / 1048576).toFixed(1)} MB`;
  }
};

const WithCommas = (num, decimals = 0) => {
  let value = Number(num); // in case it's a string
  return isNaN(value) ? "?" : value.toLocaleString("en-US", { maximumFractionDigits: decimals });
};

const roundDownToNearest = (value, interval) => {
  return Math.floor(value / interval) * interval;
};

const roundUpToNearest = (value, interval) => {
  return Math.ceil(value / interval) * interval;
};

// cyrb53 hash function https://stackoverflow.com/a/52171480/3029882
const goodHash = (str, seed = 0) => {
  let h1 = 0xdeadbeef ^ seed,
    h2 = 0x41c6ce57 ^ seed;
  for (let i = 0, ch; i < str.length; i++) {
    ch = str.charCodeAt(i);
    h1 = Math.imul(h1 ^ ch, 2654435761);
    h2 = Math.imul(h2 ^ ch, 1597334677);
  }

  h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^ Math.imul(h2 ^ (h2 >>> 13), 3266489909);
  h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^ Math.imul(h1 ^ (h1 >>> 13), 3266489909);

  return 4294967296 * (2097151 & h2) + (h1 >>> 0);
};

// This debounces, taking the last event that times out
// Call this like: const doSomethingSafely = debounce(() => doSomething());
const debounce = (func, delay) => {
  let debounceTimer;
  return function () {
    const context = this;
    const args = arguments;
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => func.apply(context, args), delay);
  };
};

// If the above is debounce_last, this is debounce_first
// This version executes the first case, not the last one.
function debounceFirst(func, timeout = 300) {
  let timer;
  return (...args) => {
    if (!timer) {
      func.apply(this, args);
    }
    clearTimeout(timer);
    timer = setTimeout(() => {
      timer = undefined;
    }, timeout);
  };
}

const isDesktop = (function () {
  let isMobile = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
        a.substr(0, 4)
      )
    )
      isMobile = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return !isMobile;
})();

const calcWalletName = (connector) => {
  const connectorName = connector?.name;

  return connectorName ? connectorName.split(" ")[0] : "";
};

const calcWalletIcon = (walletName) => {
  const WalletIcon = walletName && Wallets[walletName];
  return WalletIcon ? <WalletIcon /> : null;
};

const readCookie = (name) => {
  var cookieArr = document.cookie.split(";");
  for (var i = 0; i < cookieArr.length; i++) {
    var cookiePair = cookieArr[i].split("=");
    if (name === cookiePair[0].trim()) {
      return decodeURIComponent(cookiePair[1]);
    }
  }
  return null;
};

const calcGradient = (themePaletteMode) => {
  // requires style object notation to work
  if (themePaletteMode === "dark") {
    return `
      linear-gradient(to right, #515254, rgba(81, 82, 84, 0));
`;
  } else {
    return `linear-gradient(
      to right top,
      rgb(209, 250, 229),
      rgb(254, 243, 199),
      rgb(254, 215, 170)
    )`;
  }
};

export {
  calcGradient,
  isDesktop,
  calcWalletName,
  calcWalletIcon,
  setupRedirectSEO,
  setupSEO,
  setupNoSEO,
  setupAuthSEO,
  textFileSize,
  WithCommas,
  roundDownToNearest,
  roundUpToNearest,
  goodHash,
  debounce,
  debounceFirst,
  readCookie,
};
