import SvgIcon from "@mui/material/SvgIcon";

export const LinkedIn = (props) => (
  <SvgIcon {...props}>
    <svg
      width="24"
      height="24"
      fill="none"
      viewBox="-2 -2 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M.254 6.218H4.54v12.898H.254V6.218ZM2.425 0C.958 0 0 .963 0 2.227c0 1.238.93 2.23 2.37 2.23h.027c1.495 0 2.426-.992 2.426-2.23C4.795.963 3.892 0 2.425 0Zm12.639 5.915c-2.275 0-3.295 1.251-3.863 2.129V6.218H6.913c.057 1.21 0 12.898 0 12.898h4.288v-7.203c0-.386.028-.77.14-1.047.311-.77 1.016-1.567 2.2-1.567 1.552 0 2.172 1.183 2.172 2.916v6.901H20V11.72c0-3.962-2.114-5.805-4.936-5.805Z"
        fill="#fff"
      />
    </svg>
  </SvgIcon>
);
