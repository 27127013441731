import SvgIcon from "@mui/material/SvgIcon";

export const Bitski = (props) => (
  <SvgIcon {...props}>
    <svg
      version="1.1"
      baseProfile="basic"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 90 90"
    >
      <rect fill="#FF3567" width="90" height="90"></rect>
      <path
        transform="translate(15, 15)"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#ffffff"
        d="M11.5,5.2L0.6,16.1c-0.8,0.8-0.8,1.5,0.1,2.3L34,51.8
	c0.8,0.8,1.5,0.9,2.3,0.1l15.3-15.3c8-8,7.3-16.4,1-22.7c-4.5-4.5-9.2-6.1-14.6-4.6c-0.9,0.3-1.3,0.2-1.7-0.3
	c-0.1-0.1-0.3-0.3-0.4-0.6c-0.1-0.1-0.1-0.2-0.2-0.3c-0.6-0.9-1.6-2.3-2.5-3.2C26.9-1.4,18.7-2,11.5,5.2z M25.5,19.3l-2.6,2.6
	c-0.8,0.8-1.5,0.8-2.3-0.1l-2.5-2.5c-0.8-0.8-0.9-1.5-0.1-2.3l2.6-2.6c1.3-1.3,3.2-1.5,4.7,0.1C27,16.1,26.8,17.9,25.5,19.3z
	 M42.2,27.5l-6.8,6.8c-0.8,0.8-1.5,0.8-2.3-0.1l-2.5-2.5c-0.8-0.8-0.9-1.5-0.1-2.3l6.8-6.8c2-2,3.9-2.3,5.5-0.7
	C44.6,23.7,44.2,25.5,42.2,27.5z M3.5,33.6c-1.2,1.2-1.2,3.1,0,4.3l21.3,21.3c1.2,1.2,3.1,1.2,4.3,0c1.2-1.2,1.2-3.1,0-4.3L7.8,33.6
	C6.6,32.4,4.7,32.4,3.5,33.6z"
      />
    </svg>
  </SvgIcon>
);
