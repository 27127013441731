import { useState } from "react";

import { Box, Tab, Tabs } from "@mui/material";

import { ConsensusHomeTab, JourneyWrapper, Transactions } from "components";

import { styled } from "@mui/system";
import { hasConsensusHub, journeyId } from "constants";

const HubTabsWrapper = ({ goToJourney }) => {
  const [tab, setTab] = useState(
    goToJourney && !!journeyId ? "journey" : hasConsensusHub ? "home" : "balance"
  );

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const goToJourneyFn = () => {
    setTab("journey");
  };

  return (
    <Box sx={{ width: "100%", maxWidth: 1600, margin: "0 auto" }}>
      <Tabs
        value={tab}
        onChange={handleTabChange}
        TabIndicatorProps={{ sx: { height: 4 } }}
        variant="scrollable"
      >
        {hasConsensusHub && <StyledTab label="Home" value="home" />}
        {hasConsensusHub && !!journeyId && (
          <StyledTab
            label={
              <Box>
                Journey: <Box component="img" src="/images/consensus2023.svg" />
              </Box>
            }
            value="journey"
          />
        )}
        <StyledTab label="Balance History" value="balance" />
      </Tabs>
      <Box py={3}>
        {tab === "home" && (
          <Box>
            <ConsensusHomeTab goToJourneyFn={goToJourneyFn} />
          </Box>
        )}

        {tab === "journey" && (
          <Box id="journey">
            <JourneyWrapper />
          </Box>
        )}

        {tab === "balance" && (
          <Box>
            <Transactions />
          </Box>
        )}
      </Box>
    </Box>
  );
};

const StyledTab = styled(Tab)`
  text-transform: none;
  font-weight: 700;
  font-size: 1rem;
  &.MuiTab-root {
    color: ${hasConsensusHub ? "#ffffff" : "#222222"};
  }
  img {
    filter: brightness(10);
  }

  &.Mui-selected {
    color: ${({ theme }) => theme.palette.primary.main};
    img {
      filter: brightness(1);
    }
  }
`;

export { HubTabsWrapper };
