import { createTheme, ThemeProvider as MuiThemeProvider, CssBaseline } from "@mui/material";
import { deepmerge } from "@mui/utils";
import { useMatch } from "react-router-dom";
import { grey } from "@mui/material/colors";
import { publicUrl, hasConsensusHub } from "constants";

const NotoSansRegular_woff2 = `${publicUrl}/fonts/NotoSans-Regular.woff2`;
const NotoSansBold_woff2 = `${publicUrl}/fonts/NotoSans-Bold.woff2`;

const colors = {
  light: "#ffffff",
  dark: "#262626",
  primary: {
    light: "#FDECBC",
    main: "#F8BF1E",
    dark: "#BD5B00",
  },
};

const white = {
  main: "#ffffff",
  contrastText: colors.dark,
};

const dark = {
  main: colors.dark,
  contrastText: "#ffffff",
};

const baseOptions = {
  shadows: new Array(25).fill("none"),
  palette: {
    type: "light",
    mode: "light",
    divider: colors.dark,
    primary: colors.primary,
    secondary: white,
    white: white,
    contrastInvert: dark,
    contrastMatch: white,
    dark: {
      main: colors.dark,
      contrastText: "#ffffff",
    },
    consensus: {
      blue: "#122595",
      purple: "#974796",
    },
    background: {
      default: "#f8bf1e",
    },
    success: {
      light: "#00D964",
      main: "#1a8917",
    },
  },
  typography: {
    fontFamily: ['"Noto Sans"', "sans-serif"].join(","),
    fontSize: 14,
    h1: {
      fontFamily: ["Noto Sans", "serif"].join(","),
      fontSize: 32,
      fontWeight: 700,
    },
    h2: {
      fontFamily: ["Noto Sans", "serif"].join(","),
      fontSize: 20,
      fontWeight: 700,
      letterSpacing: "0.03em",
    },
    h3: {
      fontSize: 22,
      fontWeight: 700,
    },
    h4: {
      fontSize: 18,
      fontWeight: 700,
    },
    h5: {
      fontWeight: 700,
      fontSize: 16,
    },
    h6: {
      fontWeight: 700,
      fontSize: 14,
    },
    subtitle1: {
      fontSize: 16,
      fontWeight: 700,
    },
    subtitle2: {
      color: "rgba(38, 38, 38, 0.55)",
    },
    body2: {
      fontSize: 12,
    },
    montserrat: {
      fontFamily: "'Montserrat', sans-serif",
      fontWeight: 500,
    },
    montserrat_bold: {
      fontFamily: "'Montserrat', sans-serif",
      fontWeight: 800,
    },
  },
  components: {
    Tab: {},
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          fontWeight: 700,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          "&.MuiInputLabel-shrink": {
            fontWeight: 700,
          },
          "&.Mui-focused": {
            color: colors.dark,
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontWeight: 700,
          borderWidth: 1,
          borderStyle: "solid",
          marginRight: 8,
          marginBottom: 16,
          backgroundColor: "transparent",
        },
        colorPrimary: {
          backgroundColor: colors.primary.light,
          borderColor: colors.primary.main,
          "&:hover": {
            backgroundColor: colors.primary.main,
          },
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          fontWeight: 700,
          fontSize: 16,
          padding: 3,
          borderColor: grey[500],
          color: grey[500],
          "&.Mui-selected.MuiToggleButtonGroup-grouped": {
            borderColor: colors.primary.dark,
            color: colors.primary.dark,
            backgroundColor: colors.primary.light,
          },
        },
      },
    },

    MuiCssBaseline: {
      styleOverrides: `
      @font-face {
        font-family: "Noto Sans";
        src:
             local("Noto Sans"),
             url("${NotoSansRegular_woff2}") format('woff2'),
        font-style: normal;
        font-weight: 400;
        font-display: swap;
    }
    @font-face {
      font-family: "Noto Sans";
      src:
           local("Noto Sans Bold"),
           url("${NotoSansBold_woff2}") format('woff2'),
      font-style: normal;
      font-weight: 700;
      font-display: swap;
  }
        `,
    },
  },
};

const theme = createTheme(baseOptions);

const darkTheme = createTheme(
  deepmerge(baseOptions, {
    palette: {
      type: "dark",
      mode: "dark",
      divider: colors.light,
      background: {
        default: "#212121",
      },
      contrastInvert: white,
      contrastMatch: {
        ...dark,
        main: "#212121",
      },
    },
    typography: {
      subtitle2: {
        color: "rgba(255, 255, 255, 0.55)",
      },
    },

    components: {
      MuiContainer: {
        styleOverrides: {
          root: {
            backgroundColor: "#212121",
            backgroundImage: "none",
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundColor: "#212121",
            backgroundImage: "none",
          },
        },
      },
    },
  })
);

const themes = {
  light: theme,
  dark: darkTheme,
};

const ThemeProvider = ({ children }) => {
  const isHubMatch = useMatch("/");
  const isQRMatch = useMatch("/qr/*");
  const isMatch = isHubMatch || isQRMatch;

  return (
    <MuiThemeProvider theme={isMatch && hasConsensusHub ? themes["dark"] : themes["light"]}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  );
};

export const LightThemeProvider = ({ children }) => (
  <MuiThemeProvider theme={themes["light"]}>
    <CssBaseline />
    {children}
  </MuiThemeProvider>
);

export default ThemeProvider;
