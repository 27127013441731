import { Mainnet, Mumbai } from "bitski";

// Auth0 redirect route supporting ?deskReturnTo=...
export const REDIRECT_ROUTE = "/go";

const COINDESK_URL_PROD = "https://www.coindesk.com";
const COINDESK_URL_STAGING = "https://uat.coindesk.com";
const COINDESK_URL_DEV = "https://sandbox.coindesk.com";

// part 1: config definition

//const FORCE_AUTH = process.env.REACT_APP_FORCE_AUTH || false;
const SUBDOMAIN = process.env.REACT_APP_SUBDOMAIN || "account";
const AUDIENCE = "https://account.coindesk.com";

const AUTH0_DOMAIN_PROD = "auth.coindesk.com";
const AUTH0_DOMAIN_STAGING = "uat.auth.coindesk.com";
const AUTH0_DOMAIN_DEV = "sandbox.auth.coindesk.com";

const AUTH0_CLIENT_ID_PROD = "SRvulnFiiMaqs4Bd2I7CceXbesZCWqz8";
const AUTH0_CLIENT_ID_STAGING = "ZTGCwOkaH5YywHzbkPqUOd43cNSfZWAP";
const AUTH0_CLIENT_ID_DEV = "aJYfsJU3uJl5FWQHdeINpwUFRXcaI9kr";
//const AUTH0_CLIENT_ID_LOCAL = "TI8iuVgBndnASMwCjxn6oYFaTdybehnc";

const DESKAPI_PROD = "https://deskapi.coindesk.com";
const DESKAPI_STAGING = "https://deskapi-staging.coindesk.com";
const DESKAPI_DEV = "https://deskapi-dev.coindesk.com";
const DESKAPI_LOCAL = "http://localhost:3001";
const DESKAPI_DESKAPI = "http://deskapi:3001"; // local BE server, put 'deskapi' in /etc/hosts

const PUBLIC_PROD = `https://${SUBDOMAIN}.coindesk.com`;
const PUBLIC_STAGING = `https://uat.${SUBDOMAIN}.coindesk.com`;
const PUBLIC_DEV = `https://sandbox.${SUBDOMAIN}.coindesk.com`;
const PUBLIC_LOCAL = "http://localhost:3000";

const LOGOUT_LOCATION_DEV = COINDESK_URL_DEV;
const LOGOUT_LOCATION_STAGING = COINDESK_URL_STAGING;
const LOGOUT_LOCATION_PROD = COINDESK_URL_PROD;

const GTM_ID = "GTM-W9J8GSW";
const PROVIDER_URL_TEST =
  "https://polygon-mumbai.g.alchemy.com/v2/q4kXkodMCjduVTUR35tJVUhIQyNX6oEu";
const PROVIDER_URL_MAIN =
  "https://polygon-mainnet.g.alchemy.com/v2/gkPRvk6de-o_O6iQNBqqdw7OLGHTxNMv";

const BLOCK_EXPLORER_TEST = "https://mumbai.polygonscan.com/tx/";
const BLOCK_EXPLORER_MAIN = "https://polygonscan.com/tx/";

const CONFIG_ID_CAPTCHA_PASS = "captcha-pass";
const CONFIG_ID_CAPTCHA_FAIL = "captcha-fail";
const CONFIG_ID_TRANSFERS_PAUSED = "transfers-paused";
const CONFIG_ID_FREE_TRANSFERS = "free-transfers";
const CONFIG_ID_GAS_LIMIT = "gas-limit";
const CONFIG_ID_GAS_PRICE = "gas-price";
const CONFIG_ID_WALLET_LIMIT = "wallet-limit";

const BITSKI_ID_LOCAL = "6cede22c-b558-4f8c-a1f4-06add2b157a2";
const BITSKI_ID_DEV = "6851a6e6-fa23-44e7-9fb0-afc53f50adf9";
const BITSKI_ID_STAGING = "46c03c7a-be52-4341-96fe-d652955fcca2";
const BITSKI_ID_PROD = "7b752001-599a-4d31-abab-968253892a69";

// const JOURNEY_ID_LOCAL = "2f0eac01-177c-4a0b-bab5-103aa70a6083";
// const JOURNEY_ID_SANDBOX = "2f0eac01-177c-4a0b-bab5-103aa70a6083";
// const JOURNEY_ID_UAT = "2f0eac01-177c-4a0b-bab5-103aa70a6083";
// const JOURNEY_ID_PROD = "2f0eac01-177c-4a0b-bab5-103aa70a6083";

// part 2: config determination
const config_dev = {
  environment: "development",
  env: "dev",
  // dev Auth0 info
  PUBLIC_URL: PUBLIC_DEV,
  AUTH0_DOMAIN: AUTH0_DOMAIN_DEV,
  AUTH0_CLIENT_ID: AUTH0_CLIENT_ID_DEV,
  // Rarely change
  AUTH0_AUDIENCE: AUDIENCE,
  COINDESK_URL: COINDESK_URL_DEV,
  LOGOUT_LOCATION: LOGOUT_LOCATION_DEV,
  // dev API servers
  DESK_API_SERVER: DESKAPI_DEV, // dev Desk API server
  // Wallet config
  PROVIDER_URL: PROVIDER_URL_TEST,
  BLOCK_EXPLORER: BLOCK_EXPLORER_TEST,
  // feature flags
  CONSENSUS_ENABLED: false,
  JOURNEY_ID: false,
  JOURNEY_SHOW_CLOCK: false,
  ONBOARDING_ENABLED: true,
  TRANSFERS_ENABLED: true,
  QUESTS_ENABLED: true,
  MARKETPLACE_ENABLED: false,
  UPLOADS_ENABLED: true,
  DAILY_DESK_ENABLED: false,
  DEBUG_ENABLED: false,
  BITSKI_ENABLED: true,
  GTM_ID: GTM_ID,
  BITSKI_ID: BITSKI_ID_DEV,
  BITSKI_NETWORK: Mumbai,
};

const config_staging = {
  environment: "staging",
  env: "staging",
  // staging (UAT) Auth0 info
  PUBLIC_URL: PUBLIC_STAGING,
  AUTH0_DOMAIN: AUTH0_DOMAIN_STAGING,
  AUTH0_CLIENT_ID: AUTH0_CLIENT_ID_STAGING,
  // Rarely change
  AUTH0_AUDIENCE: AUDIENCE,
  COINDESK_URL: COINDESK_URL_STAGING,
  LOGOUT_LOCATION: LOGOUT_LOCATION_STAGING,
  // staging API servers
  DESK_API_SERVER: DESKAPI_STAGING, // staging Desk API server
  // Wallet config
  PROVIDER_URL: PROVIDER_URL_MAIN,
  BLOCK_EXPLORER: BLOCK_EXPLORER_MAIN,
  // feature flags
  CONSENSUS_ENABLED: false,
  JOURNEY_ID: false,
  JOURNEY_SHOW_CLOCK: false,
  ONBOARDING_ENABLED: true,
  TRANSFERS_ENABLED: true,
  QUESTS_ENABLED: true,
  MARKETPLACE_ENABLED: false,
  UPLOADS_ENABLED: true,
  DAILY_DESK_ENABLED: false,
  DEBUG_ENABLED: false,
  BITSKI_ENABLED: true,
  GTM_ID: GTM_ID,
  BITSKI_ID: BITSKI_ID_STAGING,
  BITSKI_NETWORK: Mainnet,
};

const config_prod = {
  environment: "production",
  env: "", // no suffix/prefix for production
  // production Auth0 info
  PUBLIC_URL: PUBLIC_PROD,
  AUTH0_DOMAIN: AUTH0_DOMAIN_PROD,
  AUTH0_CLIENT_ID: AUTH0_CLIENT_ID_PROD,
  // Rarely change
  AUTH0_AUDIENCE: AUDIENCE,
  COINDESK_URL: COINDESK_URL_PROD,
  LOGOUT_LOCATION: LOGOUT_LOCATION_PROD,
  // production API servers
  DESK_API_SERVER: DESKAPI_PROD, // production Desk API server
  // Wallet config
  PROVIDER_URL: PROVIDER_URL_MAIN,
  BLOCK_EXPLORER: BLOCK_EXPLORER_MAIN,
  // feature flags
  CONSENSUS_ENABLED: false,
  JOURNEY_ID: false,
  JOURNEY_SHOW_CLOCK: false,
  ONBOARDING_ENABLED: true,
  TRANSFERS_ENABLED: true,
  QUESTS_ENABLED: true,
  MARKETPLACE_ENABLED: false,
  UPLOADS_ENABLED: true,
  DAILY_DESK_ENABLED: false,
  DEBUG_ENABLED: false,
  BITSKI_ENABLED: true,
  GTM_ID: GTM_ID,
  BITSKI_ID: BITSKI_ID_PROD,
  BITSKI_NETWORK: Mainnet,
};

// MODE="local" or STAGE="local" should help front-end testing against stable (dev/sandbox) back-ends
const config_local = {
  environment: "local",
  env: "local",
  PUBLIC_URL: PUBLIC_LOCAL,
  // dev (sandbox) Auth0 info
  AUTH0_DOMAIN: AUTH0_DOMAIN_DEV,
  AUTH0_CLIENT_ID: AUTH0_CLIENT_ID_DEV,
  // Rarely change
  AUTH0_AUDIENCE: AUDIENCE,
  COINDESK_URL: COINDESK_URL_DEV,
  LOGOUT_LOCATION: LOGOUT_LOCATION_DEV,
  // back-end API servers, or as desired
  DESK_API_SERVER: DESKAPI_DEV, // dev/sandbox Desk API server
  AUTH0_SELFHOSTED: true,
  // Wallet config
  PROVIDER_URL: PROVIDER_URL_TEST,
  BLOCK_EXPLORER: BLOCK_EXPLORER_TEST,
  // feature flags
  CONSENSUS_ENABLED: false,
  JOURNEY_ID: false,
  JOURNEY_SHOW_CLOCK: false,
  ONBOARDING_ENABLED: true,
  TRANSFERS_ENABLED: true,
  QUESTS_ENABLED: true,
  MARKETPLACE_ENABLED: false,
  UPLOADS_ENABLED: true,
  DAILY_DESK_ENABLED: false,
  DEBUG_ENABLED: false,
  BITSKI_ENABLED: true,
  GTM_ID: false,
  BITSKI_ID: BITSKI_ID_LOCAL,
  BITSKI_NETWORK: Mumbai,
};

const config_localbe = {
  environment: "localhost-backend",
  env: "localbe",
  PUBLIC_URL: PUBLIC_LOCAL,
  // local Auth0 info
  AUTH0_DOMAIN: AUTH0_DOMAIN_DEV,
  AUTH0_CLIENT_ID: AUTH0_CLIENT_ID_DEV,
  // back-end API servers, or as desired
  DESK_API_SERVER: DESKAPI_LOCAL, // Desk API server at localhost
  // Rarely change
  AUTH0_AUDIENCE: AUDIENCE,
  COINDESK_URL: COINDESK_URL_DEV,
  // Wallet config
  PROVIDER_URL: PROVIDER_URL_TEST,
  BLOCK_EXPLORER: BLOCK_EXPLORER_TEST,
  // feature flags
  CONSENSUS_ENABLED: false,
  JOURNEY_ID: false,
  JOURNEY_SHOW_CLOCK: false,
  ONBOARDING_ENABLED: true,
  TRANSFERS_ENABLED: true,
  QUESTS_ENABLED: true,
  MARKETPLACE_ENABLED: false,
  UPLOADS_ENABLED: true,
  DAILY_DESK_ENABLED: false,
  DEBUG_ENABLED: false,
  BITSKI_ENABLED: true,
  GTM_ID: false,
  BITSKI_ID: BITSKI_ID_LOCAL,
  BITSKI_NETWORK: Mumbai,
};
// Use this one if you have a local backend defined as 'deskapi' in your /etc/hosts
const config_deskapi = {
  environment: "local-deskapi",
  env: "deskapi",
  PUBLIC_URL: PUBLIC_LOCAL,
  // local Auth0 info
  AUTH0_DOMAIN: AUTH0_DOMAIN_DEV,
  AUTH0_CLIENT_ID: AUTH0_CLIENT_ID_DEV,
  // back-end API servers, or as desired
  DESK_API_SERVER: DESKAPI_DESKAPI, // backend as at known 'deskapi:3001' host
  // Rarely change
  AUTH0_AUDIENCE: AUDIENCE,
  COINDESK_URL: COINDESK_URL_DEV,
  LOGOUT_LOCATION: LOGOUT_LOCATION_DEV,
  // Wallet config
  PROVIDER_URL: PROVIDER_URL_TEST,
  BLOCK_EXPLORER: BLOCK_EXPLORER_TEST,
  // feature flags
  CONSENSUS_ENABLED: false,
  JOURNEY_ID: false,
  JOURNEY_SHOW_CLOCK: false,
  ONBOARDING_ENABLED: true,
  TRANSFERS_ENABLED: true,
  QUESTS_ENABLED: true,
  MARKETPLACE_ENABLED: false,
  UPLOADS_ENABLED: true,
  DAILY_DESK_ENABLED: false,
  DEBUG_ENABLED: false,
  BITSKI_ENABLED: true,
  GTM_ID: false,
  BITSKI_ID: BITSKI_ID_LOCAL,
  BITSKI_NETWORK: Mumbai,
};

// part 3: config auto-detection
function autoStage() {
  if (!window?.location) {
    // for non-browser environments, documenting this same process for the backend
    return process.env.MODE || process.env.STAGE || "dev";
  }

  if (window.location.origin === config_dev.PUBLIC_URL) {
    return "dev";
  }
  if (window.location.origin === config_staging.PUBLIC_URL) {
    return "staging";
  }
  if (window.location.origin === config_prod.PUBLIC_URL) {
    return "production";
  }
  if (window.location.origin === config_local.PUBLIC_URL) {
    return "local";
  }

  const stage =
    process.env.REACT_APP_MODE || process.env.REACT_APP_STAGE || process.env.STAGE || "dev";
  console.log(
    `getConfig: Could not determine stage environment for ${window.location.origin}, using "${stage}"`
  );
  return stage;
}

//let STAGE = process.env.REACT_APP_MODE || process.env.REACT_APP_STAGE || process.env.NODE_ENV || autoStage();
let STAGE = process.env.REACT_APP_MODE || process.env.REACT_APP_STAGE || autoStage();
if (!STAGE) {
  throw new Error("STAGE or MODE must be defined.");
}

const configs = {
  dev: config_dev,
  staging: config_staging,
  prod: config_prod,
  local: config_local, // local FE testing against dev/sandbox BE
  localbe: config_localbe, // BE server on localhost:3001
  deskapi: config_deskapi, // where ever you want to define host 'deskapi'
};

const stageByEnv = String(
  process.env.REACT_APP_MODE || process.env.REACT_APP_STAGE || process.env.STAGE || autoStage()
)
  .trim()
  .toLowerCase();

const configsByStage = {
  dev: configs.dev,
  development: configs.dev,
  staging: configs.staging,
  prod: configs.prod,
  production: configs.prod,
  local: configs.local,
  localbe: configs.localbe,
  deskapi: configs.deskapi,
};

const configsByDomain = {
  [configs.dev.PUBLIC_URL]: configs.dev,
  [configs.staging.PUBLIC_URL]: configs.staging,
  [configs.prod.PUBLIC_URL]: configs.prod,
  [configs.local.PUBLIC_URL]: configs.local,
};

export const currentConfig =
  configsByStage[stageByEnv] || configsByDomain[window?.location?.origin] || configs.dev;

// part 4: config fine tune and exported values

const coindeskBaseUrl = process.env.REACT_APP_COINDESK_URL || currentConfig.COINDESK_URL || "https://www.coindesk.com/";
const authLogoutLocation = process.env.REACT_APP_LOGOUT_LOCATION || currentConfig.LOGOUT_LOCATION || coindeskBaseUrl;

const publicUrl = process.env.REACT_APP_PUBLIC_URL || currentConfig.PUBLIC_URL || "";
const authDomain = process.env.REACT_APP_AUTH0_DOMAIN || currentConfig.AUTH0_DOMAIN;
const authClientId = process.env.REACT_APP_AUTH0_CLIENT_ID || currentConfig.AUTH0_CLIENT_ID;
const authRedirectUri = window.location.origin + REDIRECT_ROUTE; // Auth0 allowed redirect URI
const authAudience = process.env.REACT_APP_AUTH0_AUDIENCE || currentConfig.AUTH0_AUDIENCE;

const authRealm = "Username-Password-Authentication";
const isAuthSelfHosted =
  String(process.env.REACT_APP_AUTH0_SELFHOSTED) === "true" || currentConfig.AUTH0_SELFHOSTED;
const authReturnTo = window.location.origin + window.location.pathname;

const apiUrl = process.env.REACT_APP_DESK_API_SERVER || currentConfig.DESK_API_SERVER;

const infuraId = process.env.REACT_APP_INFURA_KEY || currentConfig.INFURA_KEY || "";

const providerUrl = process.env.REACT_APP_PROVIDER_URL || currentConfig.PROVIDER_URL;
const explorerUrl = process.env.REACT_APP_BLOCK_EXPLORER || currentConfig.BLOCK_EXPLORER;
const bitskiClientId = process.env.REACT_APP_BITSKI_ID || currentConfig.BITSKI_ID;

const hasConsensusHub =
  (String(process.env.REACT_APP_CONSENSUS_ENABLED) === "true" || currentConfig.CONSENSUS_ENABLED) &&
  String(process.env.REACT_APP_CONSENSUS_ENABLED) !== "false";

const journeyId =
  String(process.env.REACT_APP_JOURNEY_ID) !== "false" &&
  (process.env.REACT_APP_JOURNEY_ID || currentConfig.JOURNEY_ID);

const hasJourneyClock =
  (String(process.env.REACT_APP_JOURNEY_SHOW_CLOCK) === "true" ||
    currentConfig.JOURNEY_SHOW_CLOCK) &&
  String(process.env.REACT_APP_JOURNEY_SHOW_CLOCK) !== "false";

const hasOnboardingEnabled =
  (String(process.env.REACT_APP_ONBOARDING_ENABLED) === "true" ||
    currentConfig.ONBOARDING_ENABLED) &&
  String(process.env.REACT_APP_ONBOARDING_ENABLED) !== "false";

const hasQuestsEnabled =
  (String(process.env.REACT_APP_QUESTS_ENABLED) === "true" || currentConfig.QUESTS_ENABLED) &&
  String(process.env.REACT_APP_QUESTS_ENABLED) !== "false";

const hasMarketplaceEnabled =
  (String(process.env.REACT_APP_MARKETPLACE_ENABLED) === "true" ||
    currentConfig.MARKETPLACE_ENABLED) &&
  String(process.env.REACT_APP_MARKETPLACE_ENABLED) !== "false";

const hasUploadsEnabled =
  (String(process.env.REACT_APP_UPLOADS_ENABLED) === "true" || currentConfig.UPLOADS_ENABLED) &&
  String(process.env.REACT_APP_UPLOADS_ENABLED) !== "false";

const hasDailyDeskEnabled =
  (String(process.env.REACT_APP_DAILY_DESK_ENABLED) === "true" ||
    currentConfig.DAILY_DESK_ENABLED) &&
  String(process.env.REACT_APP_DAILY_DESK_ENABLED) !== "false";

const hasDebugLogsEnabled =
  (String(process.env.REACT_APP_DEBUG_ENABLED) === "true" || currentConfig.DEBUG_ENABLED) &&
  String(process.env.REACT_APP_DEBUG_ENABLED) !== "false";

const hasBitskiEnabled =
  (String(process.env.REACT_APP_BITSKI_ENABLED) === "true" || currentConfig.BITSKI_ENABLED) &&
  String(process.env.REACT_APP_BITSKI_ENABLED) !== "false";

const gtmId = process.env.REACT_APP_GTM_ID || currentConfig.GTM_ID || false;

const actualTZOffset = new Date().getTimezoneOffset();
let clientTZOffset =
  process.env.REACT_APP_TZOFFSET !== undefined
    ? Number(process.env.REACT_APP_TZOFFSET)
    : actualTZOffset;
if (clientTZOffset !== actualTZOffset) {
  console.log(`TZOffset is ${clientTZOffset} vs ${actualTZOffset}`);
}

const bitskiNetwork = currentConfig.BITSKI_NETWORK;

// external paths
export const EXTERNAL_HREFS = {
  // Main coindesk.com site links
  coindesk: coindeskBaseUrl,
  markets: `${coindeskBaseUrl}/markets/`,
  business: `${coindeskBaseUrl}/business/`,
  tech: `${coindeskBaseUrl}/tech/`,
  policy: `${coindeskBaseUrl}/policy/`,
  indices: `${coindeskBaseUrl}/indices/`,
  indices_cmi: `${coindeskBaseUrl}/indices/cmi/`,
  calculator: `${coindeskBaseUrl}/calculator/`,
  tv: `${coindeskBaseUrl}/tv/`,
  video: `${coindeskBaseUrl}/video/`,
  podcasts: `${coindeskBaseUrl}/podcasts/`,
  learn: `${coindeskBaseUrl}/learn/`,
  events: `${coindeskBaseUrl}/events/`,
  research: `${coindeskBaseUrl}/research/`,
  about: `${coindeskBaseUrl}/about/`,
  search: `${coindeskBaseUrl}/search?s=`,
  newsletters: `${coindeskBaseUrl}/newsletters/`,
  sponsored: `${coindeskBaseUrl}/sponsored-content/`,
  masthead: `${coindeskBaseUrl}/masthead/`,
  contributors: `${coindeskBaseUrl}/contributors/`,
  careers: "https://boards.greenhouse.io/coindesk",
  company: `${coindeskBaseUrl}/company-news/`,
  follow: `${coindeskBaseUrl}/follow/`,
  advertise: `${coindeskBaseUrl}/advertise/`,
  accessibility: `${coindeskBaseUrl}//accessibility-help/`,
  ethics: "https://www.coindesk.com/ethics/",
  privacy: "https://www.coindesk.com/privacy/",
  terms: "https://www.coindesk.com/terms/",
  dnsmpi: "https://www.coindesk.com/privacy/#dnsmpi",
  dcg: "https://dcg.co/",
  dcgassets: "https://dcg.co/#digital-assets-portfolio",
  dcgportfolio: "https://dcg.co/portfolio/",
  web3: `${coindeskBaseUrl}//tag/web3`,

  // Our marketplace (not to be confused with the Markets coindesk.com page above)
  merch: "https://coindesk-desk.myshopify.com/",
  nftmarket: "https://coindesk.nftify.network/",

  // Other special links
  consensus2022: `${coindeskBaseUrl}//consensus2022/`,
  explore: `${coindeskBaseUrl}//consensus2022/experiences/desk/`,
  explorefaq: `${coindeskBaseUrl}/consensus2022/desk/faq/`,
  exploreabout: `${coindeskBaseUrl}/consensus2022/desk/about/`,
  consensus23: `${coindeskBaseUrl}/consensus/`,
  consensus: `${coindeskBaseUrl}/consensus/`,
  consensus_magazine: `${coindeskBaseUrl}/consensus-magazine/`,
  consensus_register: `https://consensus.coindesk.com/register/`,
  consensus_mint: `${coindeskBaseUrl}/consensusnft/`,

  prices: `${coindeskBaseUrl}/data/`,
  top20: `${coindeskBaseUrl}/coindesk20/`,
  discord: "https://discord.gg/tRuUMkkQd9", // invite link
  support: "https://coindesk.atlassian.net/servicedesk/customer/portals",
  supportNewRequest: "https://coindesk.atlassian.net/servicedesk/customer/portal/7",
  supportFeedback: "https://coindesk.atlassian.net/servicedesk/customer/portal/7/group/21/create/80",
  help: "https://support.coindesk.com/",
  helpCenter: "https://coindesk.atlassian.net/servicedesk/customer/portals",
  vote: "https://snapshot.org/#/deskvoting.eth",
  layer2: `${coindeskBaseUrl}/layer2/`,
  twitter: "https://twitter.com/coindesk",
  facebook: "https://www.facebook.com/coindesk",
  linkedin: "https://www.linkedin.com/company/coindesk/",
  rssfeed: `${coindeskBaseUrl}/arc/outboundfeeds/rss/?outputType=xml`,
  award_winning: "https://www.coindesk.com/business/2023/02/20/coindesk-wins-a-polk-award-a-top-journalism-prize-for-explosive-ftx-coverage/",
  bullish_news: "https://bullish.com/news-insights/news/bullish-acquires-coindesk-from-digital-currency-group/",
  bullish: "https://bullish.com/",
  block_one: "https://b1.com/",
  block_one_portfolio: "https://b1.com/portfolio/",
  cookies: "https://www.coindesk.com/privacy/#cookies",
};

export const knowledgeLevelsMaps = {
  Beginner: 1,
  Intermediate: 2,
  Advanced: 3,
  0: "Add your knowledge level",
  1: "Beginner",
  2: "Intermediate",
  3: "Advanced",
};

export const userIdentifications = [
  "Institutional investor",
  "Professional",
  "Crypto trader",
  "Aspiring crypto professional",
  "Crypto/Web3 professional",
  "Individual investor",
  "NFT Creator/Collector",
  "Gamer",
  "Crypto/Web3 curious",
  "Other",
];

export const allInterests = [
  "AI",
  "Art / Generative Art",
  "Blockchain",
  "Crypto / Web3 Software",
  "DAOs",
  "DeFi",
  "Dev Tools / Opportunities",
  "Entrepreneurship",
  "Gaming",
  "Institutional Investing",
  "Legal / Compliance",
  "Marketing / Advertising",
  "Metaverse",
  "Mining",
  "Music",
  "NFT's",
  "Ownership / Governance",
  "Policy / Regulation",
  "Protocols / Foundations",
  "Retail Investing",
  "Smart Contracts",
  "Sports / Entertainment",
  "Staking",
  "Sustainability",
  "Technology",
  "Token Usage",
  "Venture Capital",
  "Wallets and Security",
  "Web3 Creator Economy",
  "Web3 Creative Services",
].sort();

export {
  STAGE,
  publicUrl,
  authDomain,
  authClientId,
  authRedirectUri,
  authLogoutLocation,
  authAudience,
  authRealm,
  isAuthSelfHosted,
  authReturnTo,
  apiUrl,
  infuraId,
  clientTZOffset,
  hasConsensusHub,
  journeyId,
  hasJourneyClock,
  providerUrl,
  explorerUrl,
  hasOnboardingEnabled,
  hasQuestsEnabled,
  hasMarketplaceEnabled,
  hasUploadsEnabled,
  hasDailyDeskEnabled,
  hasDebugLogsEnabled,
  hasBitskiEnabled,
  CONFIG_ID_CAPTCHA_PASS,
  CONFIG_ID_CAPTCHA_FAIL,
  CONFIG_ID_GAS_LIMIT,
  CONFIG_ID_GAS_PRICE,
  CONFIG_ID_WALLET_LIMIT,
  CONFIG_ID_TRANSFERS_PAUSED,
  CONFIG_ID_FREE_TRANSFERS,
  gtmId,
  bitskiClientId,
  bitskiNetwork,
};
