import { StrictMode, Suspense, lazy } from "react";
import { createRoot } from "react-dom/client";

import { LoadingOverlay } from "components";
import { PACKAGE_VERSION } from "version";
import LoadingProvider from "providers/LoadingProvider";

const App = lazy(() => import("./App"));

console.log("Desk client version:", PACKAGE_VERSION);

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <StrictMode>
    <LoadingProvider>
      <Suspense fallback={<LoadingOverlay useTopWithId="index" />}>
        <App />
      </Suspense>
    </LoadingProvider>
  </StrictMode>
);
