import { Dialog, IconButton, Box, Typography, CardMedia, Stack } from "@mui/material";
import { styled } from "@mui/system";

import CloseIcon from "@mui/icons-material/Close";
import { calcGradient } from "utils";
import { hasConsensusHub } from "constants";

const DialogWrapper = ({
  children,
  onClose,
  hasGradient,
  title,
  image,
  content,
  description,
  dialogProps = {},
  // hasYellowBackground = false, // future feature
  // isFullScreenOnMobile = false, // future feature
  ...props
}) => {
  const BoxComponent = hasGradient ? StyledBox : Box;

  const titleComponent =
    typeof title === "string" ? (
      <Typography variant="h1" textAlign="center">
        {title}
      </Typography>
    ) : (
      title
    );

  const imageComponent = !image ? null : typeof image === "string" ? (
    <Box display="flex" justifyContent="center">
      <CardMedia
        component="img"
        image={image}
        alt={title}
        sx={{ maxWidth: 200, maxHeight: 200, objectFit: "contain" }}
      />
    </Box>
  ) : (
    image
  );

  const descriptionComponent = !description ? null : typeof description === "string" ? (
    <Typography>{description}</Typography>
  ) : (
    description
  );

  return (
    <Dialog {...props} {...dialogProps}>
      <BoxComponent sx={{ width: 500, maxWidth: "100%" }}>
        <Box pt={2} pr={2} width="100%" display="flex" justifyContent="right">
          <IconButton color="contrastInvert" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box p={{ xs: 2, sm: 4, md: 6 }} pt={{ xs: 0, sm: 0, md: 0 }}>
          <Stack spacing={2} py={2}>
            {titleComponent}
            {imageComponent}
            {descriptionComponent}
            {children}
          </Stack>
        </Box>
      </BoxComponent>
    </Dialog>
  );
};

const StyledBox = styled(Box)((props) => ({
  backgroundImage: calcGradient(props.theme.palette.mode),
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
}));

export { DialogWrapper };
