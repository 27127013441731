import SvgIcon from "@mui/material/SvgIcon";

export const TvIcon = (props) => (
  <SvgIcon {...props}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 14.5769L15.5769 11L10 7.42307V14.5769ZM9 20V18H4.61538C4.15513 18 3.77083 17.8458 3.4625 17.5375C3.15417 17.2292 3 16.8449 3 16.3846V5.61537C3 5.15512 3.15417 4.77083 3.4625 4.4625C3.77083 4.15417 4.15513 4 4.61538 4H19.3846C19.8449 4 20.2292 4.15417 20.5375 4.4625C20.8458 4.77083 21 5.15512 21 5.61537V16.3846C21 16.8449 20.8458 17.2292 20.5375 17.5375C20.2292 17.8458 19.8449 18 19.3846 18H15V20H9ZM4.61538 17H19.3846C19.5385 17 19.6795 16.9359 19.8077 16.8077C19.9359 16.6795 20 16.5385 20 16.3846V5.61537C20 5.46154 19.9359 5.32052 19.8077 5.1923C19.6795 5.0641 19.5385 5 19.3846 5H4.61538C4.46154 5 4.32052 5.0641 4.1923 5.1923C4.0641 5.32052 4 5.46154 4 5.61537V16.3846C4 16.5385 4.0641 16.6795 4.1923 16.8077C4.32052 16.9359 4.46154 17 4.61538 17Z"
        fill="#262626"
      />
    </svg>
  </SvgIcon>
);
