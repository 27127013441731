import {
  CircularProgress,
  Dialog,
  Paper,
  Box,
  Typography,
  IconButton,
  Stack,
  CardMedia,
  Button,
  Link,
} from "@mui/material";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import { LoadingOverlay } from "components";
import { useEffect, useState, useCallback } from "react";
import { useUser } from "providers";
import { explorerUrl } from "../../constants";
import CloseIcon from "@mui/icons-material/Close";
import { Polygon } from "icons";

const Spend = () => {
  const navigate = useNavigate();
  const { productId } = useParams();
  const {
    api: { getRedeem, postRedeem },
  } = useUser();
  const [product, setProduct] = useState(null);
  const [isPurchased, setIsPurchased] = useState(false);
  const [isPurchasing, setIsPurchasing] = useState(false);
  const [redeemResult, setRedeemResult] = useState(null);

  const onCloseDialog = useCallback(() => {
    navigate("/");
  }, [navigate]);

  useEffect(() => {
    getRedeem(productId)
      .then((data) => {
        setProduct(data);
      })
      .catch((err) => {
        console.log(err.message);
        setRedeemResult(err.message);
        onCloseDialog();
      });
  }, [onCloseDialog, productId, getRedeem]);

  const handlePurchase = () => {
    setIsPurchasing(true);
    postRedeem(productId)
      .then((data) => {
        setRedeemResult(data);
        setIsPurchasing(false);
        setIsPurchased(true);
      })
      .catch((err) => {
        console.log(err.message);
        setRedeemResult(err.message);
        setIsPurchasing(false);
        setIsPurchased(true);
      });
  };

  return (
    <Dialog
      open
      sx={{ zIndex: 1301 }}
      onClose={() => {}}
      PaperComponent={!product ? () => <CircularProgress color="dark" /> : Paper}
      PaperProps={{ sx: { overflowY: "visible" } }}
      slots={{
        backdrop: LoadingOverlay,
      }}
    >
      <Box pt={2} pr={2} width="100%" display="flex" justifyContent="right">
        <IconButton color="contrastInvert" onClick={onCloseDialog}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box p={5} pt={0} sx={{ width: 500, maxWidth: "100vw" }}>
        <Stack spacing={4} pb={2}>
          <Typography variant="h2" textAlign="center">
            Real engagement = real rewards
          </Typography>
          <Box display="flex" justifyContent="center">
            <CardMedia
              component="img"
              image={product?.image || "/market_hq.png"}
              alt={`Cover for "${product?.title} reward.`}
              sx={{ maxWidth: 200, maxHeight: 200, objectFit: "contain" }}
            />
          </Box>{" "}
          <Stack spacing={2}>
            <Typography variant="body1" textAlign="center" sx={{ whiteSpace: "pre-wrap" }}>
              {product?.description}
            </Typography>
            {redeemResult?.success ? (
              <>
                <Typography variant="h4" textAlign="center" mt={2}>
                  Successfully redeemed!
                </Typography>
                {redeemResult.result?.hash && (
                  <Button
                    component={Link}
                    href={explorerUrl + redeemResult.result.hash}
                    target="_blank"
                    rel="noopener noreferer"
                    underline="hover"
                    variant="contained"
                    sx={{
                      backgroundColor: "#8247e5",
                      color: "#ffffff",
                      width: "100%",
                      "&:hover": {
                        backgroundColor: "#9B6BEA",
                      },
                    }}
                    endIcon={<Polygon sx={{ filter: "brightness(10)" }} />}
                  >
                    View transaction
                  </Button>
                )}
              </>
            ) : redeemResult !== null ? (
              <Typography variant="body1" textAlign="center" mt={2} color="red">
                {redeemResult}
              </Typography>
            ) : (
              <Typography variant="body1" textAlign="center" mt={2}>
                Note: the DESK™ will be deducted from your CoinDesk.com Balance
              </Typography>
            )}

            {isPurchased ? (
              <Button component={RouterLink} to="/quest" variant="contained" fullWidth>
                View more ways to earn!
              </Button>
            ) : (
              <Button
                variant="contained"
                onClick={handlePurchase}
                disabled={isPurchasing}
                sx={{ position: "relative", zIndex: 1 }}
              >
                {isPurchasing ? (
                  <>
                    &nbsp;
                    <CircularProgress
                      color="dark"
                      sx={{ width: "20px !important", height: "20px !important" }}
                    />
                    &nbsp;
                  </>
                ) : (
                  `Redeem for ${product?.cost} DESK™`
                )}
              </Button>
            )}
            {/* <InlineButtonLink
              component={Link}
              to="/edu/faqs/general"
              sx={{ fontWeight: 400, fontSize: 14, pt: 2 }}
            >
              What is DESK™?
            </InlineButtonLink> */}
          </Stack>
        </Stack>
      </Box>
    </Dialog>
  );
};

export { Spend };
