import { styled } from "@mui/system";
import { Paper, Box, Typography, CircularProgress, Fab, IconButton } from "@mui/material";
import { Link } from "react-router-dom";

import { useUser } from "providers";
import { publicUrl } from "constants";

import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import EditIcon from "@mui/icons-material/Edit";

export const ProfileQRComponent = ({ absolute = false }) => (
  <ProfileLogoStroke
    radius={20}
    ignoreChildElement
    gradient="radial-gradient(circle at bottom right, #1a8917, #f8bf1e 66%)"
    sx={[{ position: absolute ? "absolute" : "relative" }, absolute && { bottom: 0, right: -10 }]}
  >
    <ProfileQRButton
      component={Link}
      to="/qr/my-code"
      color="secondary"
      size="small"
      sx={{ boxShadow: 0, position: "absolute", top: 0, right: 0 }}
    >
      <QrCodeScannerIcon />
    </ProfileQRButton>
  </ProfileLogoStroke>
);

const LogoWrapper = ({ children, unwrap }) =>
  unwrap ? (
    children
  ) : (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {children}
    </Box>
  );

export const ProfileLogo = ({
  large = false,
  medium = false,
  withQR = false,
  unwrap = false,
  editable = false,
  onEdit,
}) => {
  const { isLoading, user: { firstName, lastName, avatarImage } = {} } = useUser();
  // console.log("Avatar image:", avatarImage);

  const calcInitials = (input = "") =>
    input
      .toString()
      .toUpperCase()
      .trim()
      .replace(/\s{2,}/g, " ") // remove extra spaces
      .replace(/\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDE4F]/g, "") // remove emojis
      .split(" ")
      .slice(0, 1)
      .map((word) => word[0])
      .join("");

  const initials = `${calcInitials(firstName)}${calcInitials(lastName)}`;

  const radius = large ? 50 : medium ? 35 : 25;

  const handleEdit = () => {
    console.log("Edit avatar");
    onEdit("avatarImage");
  };

  return (
    <LogoWrapper>
      <ProfileLogoStroke
        radius={radius}
        gradient="linear-gradient(to bottom, #f8bf1e88, #1a891788 38.54%, #e06c0088 75%)"
      >
        {avatarImage ? (
          <img src={avatarImage} alt="avatar" />
        ) : (
          <ProfileLogoInside>
            <Typography variant={!large && !medium ? "h2" : "h1"} color="inherit">
              {initials || <CircularProgress color="primary" />}
            </Typography>
          </ProfileLogoInside>
        )}
        {editable && (
          <Typography variant="subtitle1" pt={2}>
            <IconButton
              onClick={handleEdit}
              disabled={isLoading}
              sx={{ position: "absolute", bottom: -10, right: -14 }}
            >
              <EditIcon color="contrastInvert" />
            </IconButton>
          </Typography>
        )}
        {withQR && <ProfileQRComponent absolute />}
      </ProfileLogoStroke>
    </LogoWrapper>
  );
};

export const ProfileLogoStroke = styled(Box)`
  border-radius: ${(props) => props.radius + 2}px;
  height: ${(props) => (props.radius + 2) * 2}px;
  width: ${(props) => (props.radius + 2) * 2}px;
  background: ${(props) => props.gradient};
  padding: 2px;
  position: relative;
  & > *:first-of-type {
    border-radius: inherit;
    height: ${(props) => props.radius * 2}px;
    width: ${(props) => props.radius * 2}px;
  }
`;

export const ProfileLogoInside = styled(Paper)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  color: #242424;
`;

const ProfileQRButton = styled(Fab)`
  background-color: #ffffff;
  background-image: url("${publicUrl}/bg-profile-qr-button.svg");
  background-size: cover;
  margin: 2px;
`;
