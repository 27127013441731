import { useEffect, useCallback, useState, useMemo, useRef } from "react";
import axios from "axios";
import { apiUrl, journeyId } from "constants";
import { useUser } from "providers";

import { JourneyUI } from "components";

const journeysPlaceholder = [
  {
    id: "rootPlaceholder",
    isClaimed: false,
    depends: ["levelPlaceholder1", "levelPlaceholder2", "levelPlaceholder3"],
  },
  {
    id: "levelPlaceholder1",
    isClaimed: false,
    amount: "0",
    depends: ["groupPlaceholder1", "groupPlaceholder2", "groupPlaceholder3"],
  },
  {
    id: "levelPlaceholder2",
    isClaimed: false,
    amount: "0",
    depends: [],
  },
  {
    id: "levelPlaceholder3",
    isClaimed: false,
    amount: "0",
    depends: [],
  },
  {
    id: "groupPlaceholder1",
    isClaimed: false,
    description: "...",
    linkText: "...",
    image: "/images/desk_logo.svg",
    amount: "0",
    needClaims: 0,
    isOrdered: true,
    depends: [],
  },
  {
    id: "groupPlaceholder2",
    isClaimed: false,
    description: "...",
    linkText: "...",
    image: "/images/desk_logo.svg",
    amount: "0",
    needClaims: 0,
    isOrdered: true,
    depends: [],
  },
  {
    id: "groupPlaceholder3",
    isClaimed: false,
    description: "...",
    linkText: "...",
    image: "/images/desk_logo.svg",
    amount: "0",
    needClaims: 0,
    isOrdered: true,
    depends: [],
  },
];

const JourneyWrapper = () => {
  const rootRewardId = sessionStorage.getItem("_journeyID") || journeyId;

  useEffect(() => () => window.history.replaceState(null, null, window.location.pathname), []);

  const { token } = useUser();

  const [rewards, setRewards] = useState(null);
  const [claimStatusLoading, setClaimStatusLoading] = useState(false);
  const [lastClaimMs, setLastClaimMs] = useState(0);

  useEffect(() => {
    if (token) {
      axios
        .get(`${apiUrl}/api/journey?take=1000&skip=0`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          setRewards(res.data.journeys);
        });
    }
  }, [token]);

  const claimRewardFn = useCallback(
    (id, retries = 0) => {
      setRewards((prev) =>
        prev.map((r) => (r.id === id ? { ...r, isClaiming: true, isClaimingError: false } : r))
      );
      return axios
        .post(
          `${apiUrl}/api/claim/${id}`,
          {},
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .catch((e) => {
          if (retries >= 3) {
            setRewards((prev) =>
              prev.map((r) =>
                r.id === id
                  ? { ...r, isClaimingError: true, isClaiming: false, isClaimed: false }
                  : r
              )
            );
          } else if (e.response.status === 429) {
            const minNextClaimMs = lastClaimMs + 11000;
            const calcTimeout = Math.max(minNextClaimMs - Date.now(), 0) + retries * 3000;
            setTimeout(() => claimRewardFn(id, retries + 1), calcTimeout);
          }
        })
        .then((response) => {
          if (!response?.data?.reason) {
            setLastClaimMs(Date.now());
            setRewards((prev) =>
              prev.map((r) =>
                r.id === id
                  ? { ...r, isClaimed: true, isClaiming: false, isClaimingError: false }
                  : r
              )
            );
          }
        });
    },
    [token, lastClaimMs]
  );

  const getRewardClaimStatus = useCallback(
    async (id) => {
      const rewardData = await axios.get(`${apiUrl}/api/claim/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      return {
        isClaimed: !!rewardData.data.transaction,
        isClaimable: rewardData.data.isClaimable,
        isDepleted: rewardData.data.isDepleted,
        childrenClaimed: rewardData.data.myClaims,
        // isDepleted: rewardData.data.reward.type === "JOURNEY_BONUS" ? true : rewardData.data.isDepleted, //<--- debugging
        // isClaimable:
        //   rewardData.data.reward.type === "JOURNEY_BONUS" ? false : rewardData.data.isClaimable, //<--- debugging
      };
    },
    [token]
  );

  const updateRewardsClaimStatus = useCallback(
    async (rewards) => {
      const rewardsWithoutClaimStatus = rewards.filter(
        (reward) => reward.isClaimed === undefined && !reward.claimStatusLoading
      );

      if (rewardsWithoutClaimStatus.length > 0) {
        setClaimStatusLoading(true);
        rewardsWithoutClaimStatus.map(async (reward, idx) => {
          setRewards((prev) =>
            prev.map((r) => (r.id === reward.id ? { ...r, claimStatusLoading: true } : r))
          );
          const { isClaimed, isClaimable, isDepleted, childrenClaimed } =
            await getRewardClaimStatus(reward.id);
          setRewards((prev) =>
            prev.map((r) =>
              r.id === reward.id ? { ...r, isClaimed, isClaimable, isDepleted, childrenClaimed } : r
            )
          );
          if (idx === rewardsWithoutClaimStatus.length - 1) {
            setClaimStatusLoading(false);
          }
        });
      }
    },
    [getRewardClaimStatus]
  );

  return (
    <>
      {rewards ? (
        <JourneyUI
          rootRewardId={rootRewardId}
          updateRewardsClaimStatus={updateRewardsClaimStatus}
          claimRewardFn={claimRewardFn}
          rewards={rewards}
          setRewards={setRewards}
          isLoading={claimStatusLoading}
        />
      ) : (
        <JourneyUI rewards={journeysPlaceholder} isLoading rootRewardId="rootPlaceholder" />
      )}
    </>
  );
};

export { JourneyWrapper };
