import { Box, CircularProgress, Dialog } from "@mui/material";
import { styled } from "@mui/system";

import { useUser, useLoading } from "providers";
import { AuthBackground } from "components";
import { useEffect } from "react";

const LoadingFeedback = ({ isError }) => (
  <Box
    sx={{
      width: "100vw",
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    {isError ? "Error loading profile" : <CircularProgress sx={{ color: "#262626" }} />}
  </Box>
);

export const LoadingOverlay = ({
  isTop = false,
  showIf = true,
  showFeedback = false,
  useTopWithId = "",
}) => {
  const { isError } = useUser();
  const { setLoadersRunning } = useLoading();

  useEffect(() => {
    if (useTopWithId) {
      setLoadersRunning((loadersRunning) => ({
        ...loadersRunning,
        [useTopWithId]: true,
      }));
      return () => {
        setLoadersRunning((loadersRunning) => ({
          ...loadersRunning,
          [useTopWithId]: false,
        }));
      };
    }
  }, [setLoadersRunning, showIf, useTopWithId]);

  return !useTopWithId ? (
    <StyledDialog
      fullScreen
      open={showIf}
      transitionDuration={{ enter: 0, exit: 300 }}
      sx={isTop ? { zIndex: 1301 } : undefined}
    >
      <AuthBackground>{showFeedback && <LoadingFeedback isError={isError} />}</AuthBackground>
    </StyledDialog>
  ) : null;
};

const StyledDialog = styled(Dialog)`
  ${(props) => props.showFeedback && "z-index: 10000;"}
`;
