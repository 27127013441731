import { Box } from "@mui/material";

const GradientBorder = ({ withBackground, withHoverBackground, reverseBorder, children }) => (
  <Box
    sx={{
      borderRadius: "6px",
      position: "relative",
      background:
        "linear-gradient(to bottom, #00000000 0, #F8BF1E33 66.6%, #F8BF1E33 66.6%, #FF660033 100%)",
      backgroundPositionY: withBackground ? "100%" : "-100%",
      backgroundSize: "100% 300%",
      backgroundRepeat: "no-repeat",
      transition: "background 0.3s ease-in-out",
      "&:hover": withHoverBackground
        ? {
            backgroundPositionY: "50%",
          }
        : undefined,
      "&:before": {
        content: "''",
        position: "absolute",
        inset: 0,
        borderRadius: "4px",
        border: "2px solid transparent",
        // borderImage: "",
        background: reverseBorder
          ? "linear-gradient(to bottom, #FF6600FF, #F8BF1EFF) border-box"
          : "linear-gradient(to bottom, #F8BF1EFF, #FF6600FF) border-box",
        WebkitMask: "linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0)",
        WebkitMaskComposite: "xor",
        maskComposite: "exclude",
      },
    }}
  >
    {children}
  </Box>
);

export { GradientBorder };
