import { useState, useEffect, useMemo } from "react";
import { useQuery } from "react-query";
import { styled } from "@mui/system";
import {
  Paper,
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TablePagination,
  TableRow,
  Link,
  Stack,
  Chip,
} from "@mui/material";

import InputIcon from "@mui/icons-material/Input";

import { useCoinviseAPI } from "hooks";
import { apiUrl, explorerUrl } from "constants";
import { Desk, Polygon } from "icons";

function formatTimestamp(timestamp) {
  if (!timestamp) return "";
  return new Date(timestamp).toLocaleString(undefined, {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "2-digit",
    second: "2-digit",
  });
}

export function Transactions() {
  const [chip, setChip] = useState("all");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [transactions, setTransactions] = useState([]);

  const authenticatedAxios = useCoinviseAPI(true);

  const authRequiredConfig = {
    enabled: Boolean(authenticatedAxios),
  };

  const getTransactions = async () => {
    const data = await authenticatedAxios.get(`${apiUrl}/api/transaction`).then((res) => res.data);
    return data;
  };

  const { data: transactionsData } = useQuery("transactions", getTransactions, authRequiredConfig);
  useEffect(() => {
    if (transactionsData?.length > 0) setTransactions(transactionsData);
  }, [transactionsData]);

  const [filteredTransactions, setFilteredTransactions] = useState([]);

  useEffect(() => {
    if (transactions && chip === "all") {
      setFilteredTransactions(transactions);
    } else if (transactions && chip === "earn") {
      setFilteredTransactions(transactions.filter((t) => parseInt(t.amount, 10) > 0));
    } else if (transactions && chip === "transfer") {
      setFilteredTransactions(transactions.filter((t) => parseInt(t.amount, 10) < 0));
    }
  }, [transactions, chip]);

  const [isTransfered] = useState(true);
  // const [isTransfered, setIsTransfered] = useState(true);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = useMemo(
    () => (page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredTransactions.length) : 0),
    [page, rowsPerPage, filteredTransactions.length]
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <Stack direction="row" spacing={1}>
        <Chip
          label="All"
          variant={chip === "all" ? "filled" : "outlined"}
          color={chip === "all" ? "primary" : "default"}
          onClick={() => setChip("all")}
        />
        <Chip
          label="Earn"
          variant={chip === "earn" ? "filled" : "outlined"}
          color={chip === "earn" ? "primary" : "default"}
          onClick={() => setChip("earn")}
        />
        <Chip
          label="Transfer"
          variant={chip === "transfer" ? "filled" : "outlined"}
          color={chip === "transfer" ? "primary" : "default"}
          onClick={() => setChip("transfer")}
        />
      </Stack>
      <TableContainer component={Paper}>
        <Table sx={{ maxWidth: "100%" }} aria-label="custom pagination table">
          <TableBody>
            {(rowsPerPage > 0
              ? filteredTransactions.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : filteredTransactions
            )
              // .reverse()
              .map((transaction) => {
                const transactionDate = formatTimestamp(transaction.timestamp);
                const amount = transaction.transferAmount;
                return (
                  <TableRow key={transaction.seqId}>
                    <TableCell component="th" scope="row">
                      {transaction.txHash ? (
                        <Link
                          href={explorerUrl + transaction.txHash}
                          target="_blank"
                          rel="noopener noreferer"
                          underline="hover"
                          sx={{ color: "#8247e5", display: "flex" }}
                        >
                          <Typography pr={1} sx={{ fontWeight: 700 }}>
                            {transaction.title}
                          </Typography>
                          <Polygon />
                        </Link>
                      ) : (
                        <Typography>{transaction.title}</Typography>
                      )}
                      <Typography color="gray" variant="caption" sx={{ marginBottom: "-12px" }}>
                        {transactionDate}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ width: 160, pr: "16px" }} align="right">
                      <TableCellAlign>
                        {transaction.balance === 0 && isTransfered ? <InputIcon /> : ""}
                        <Typography>{amount}</Typography>
                      </TableCellAlign>
                      <TableCellAlign>
                        <IconWrapper>
                          <Desk color="dark" sx={{ fontSize: 16, mb: 0.2 }} />
                        </IconWrapper>
                        <Typography color="gray" sx={{ mb: "-12px" }}>
                          {transaction.balance}
                        </Typography>
                      </TableCellAlign>
                    </TableCell>
                  </TableRow>
                );
              })}
            {(rowsPerPage > 0
              ? filteredTransactions.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : filteredTransactions
            ).length === 0 && (
              <TableRow>
                <TableCell colSpan={6} sx={{ textAlign: "center" }}>
                  <Typography variant="h3" sx={{fontWeight: 400}} py={4}>No transactions found</Typography>
                </TableCell>
              </TableRow>
            )}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                colSpan={2}
                count={filteredTransactions.length}
                rowsPerPage={rowsPerPage}
                page={page}
                sx={{
                  ".MuiTablePagination-toolbar": {
                    justifyContent: {
                      xs: "center",
                      sm: "flex-end",
                    },
                    // flexDirection: {
                    //   xs: "column",
                    //   sm: "row",
                    // },
                    flexWrap: "wrap",
                  },
                  ".MuiTablePagination-selectLabel": {
                    display: {
                      xs: "none",
                      sm: "block",
                    },
                  },
                  ".MuiInputBase-root": {
                    display: {
                      xs: "none",
                      sm: "inline-flex",
                    },
                  },
                  ".MuiTablePagination-displayedRows": {
                    order: {
                      xs: 10,
                      sm: "unset",
                    },
                    marginTop: {
                      xs: 0,
                      sm: "12px",
                    },
                  },
                  ".MuiTablePagination-actions": {
                    marginLeft: {
                      xs: 0,
                      sm: "20px",
                    },
                    flexGrow: {
                      xs: 1,
                      sm: "unset",
                    },
                    width: {
                      xs: "100%",
                      sm: "unset",
                    },
                    textAlign: {
                      xs: "center",
                      sm: "unset",
                    },
                  },
                }}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                showFirstButton
                showLastButton
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelDisplayedRows={({ from, to, count }) =>
                  `Showing ${from} to ${to} transaction of ${count} total`
                }
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
}

const IconWrapper = styled(Box)((props) => ({
  display: "inline-block",
  backgroundColor: props.theme.palette.primary.main,
  color: "dark",
  height: "16px",
  width: "16px",
  borderRadius: "50%",
  marginRight: "4px",
  marginBottom: "-12px",
}));

const TableCellAlign = styled(Box)((props) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-end",
}));
