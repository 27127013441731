import { Box, Typography, IconButton, Button, Dialog, TextField } from "@mui/material";
import { useState } from "react";

import CloseIcon from "@mui/icons-material/Close";

import { useDeskAuth, useUser } from "providers";

export const DialogDeleteAccount = ({ open, onClose, setSnack, shortName }) => {
  const { deskLogout } = useDeskAuth();
  const {
    api: { profileDelete },
  } = useUser();
  const [error, setError] = useState(null);
  const [usernameInput, setUsernameInput] = useState("");
  const [isConfirmation, setIsConfirmation] = useState(false);

  const onCloseHandler = () => {
    setUsernameInput("");
    setError(null);
    onClose();
  };

  const safetyString = shortName || "delete my account";
  const helperText = shortName
    ? `Enter your username (${shortName}) to proceed`
    : "Enter 'delete my account' to proceed";

  const onUserDelete = async () => {
    try {
      await profileDelete();
      deskLogout();
      onCloseHandler();
      setIsConfirmation(true);
    } catch (err) {
      setSnack({ open: true, type: "error", message: "Could not delete user: " + err.message });
      onCloseHandler();
    }
  };

  const onKeyDown = (event) => {
    if (event.keyCode === 13) {
      event.stopPropagation();
      onSubmitHandler();
      event.preventDefault();
    }
  };

  const onSubmitHandler = async () => {
    if (safetyString === usernameInput) {
      await onUserDelete();
    } else {
      setError(`You spelled '${safetyString}' incorrectly`);
    }
  };

  return (
    <>
      <Dialog open={isConfirmation} onClose={deskLogout}>
        <Box p={2} pb={5}>
          <Box width="100%" display="flex" justifyContent="right">
            <IconButton color="contrastInvert" onClick={deskLogout}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box>
            <Box textAlign="center">
              <Typography variant="h2" pb={2}>
                Goodbye...
              </Typography>

              <Box p={5} sx={{ width: 500, maxWidth: "100%", minHeight: 150 }}>
                <Typography>
                  Your account was successfully deleted.
                  <br />
                  We hope to see you again in the future.
                </Typography>
              </Box>
              <Box px={8}>
                <Button
                  color="primary"
                  variant="contained"
                  sx={{ width: "100%" }}
                  onClick={deskLogout}
                >
                  Bye for Now
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Dialog>
      <Dialog open={open} onClose={onCloseHandler}>
        <form onKeyDown={onKeyDown}>
          <Box p={2} pb={5}>
            <Box width="100%" display="flex" justifyContent="right">
              <IconButton color="dark" onClick={onCloseHandler}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Box>
              <Box>
                <Typography variant="h2" textAlign="center" pb={2}>
                  Delete Account
                </Typography>

                <Box p={5} sx={{ width: 500, maxWidth: "100%", minHeight: 250 }}>
                  <Typography pb={2}>
                    WARNING: This will delete your account and account data. You won't be able to
                    access your balance once your account is deleted. Be sure to transfer any
                    balance to your wallet before continuing.
                  </Typography>
                  <Typography pb={2}>Are you sure you want to delete this user account?</Typography>

                  <TextField
                    sx={{ width: "100%" }}
                    value={usernameInput}
                    onChange={(e) => setUsernameInput(e.target.value)}
                    label="Safety phrase"
                    variant="standard"
                    name="safetyPhrase"
                    id="safetyPhrase"
                    error={!!error}
                    helperText={error || helperText}
                  />
                </Box>
                <Box px={8}>
                  <Button
                    color="primary"
                    variant="contained"
                    sx={{ width: "100%" }}
                    onClick={onSubmitHandler}
                  >
                    Delete
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </form>
      </Dialog>
    </>
  );
};
