import { Box, Button, Typography, IconButton, Fab } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import { useAccount, useDisconnect } from "wagmi";
import { Desk } from "icons";
import { WalletSelection, WalletDisconnect, Transfer, WalletSelectionPre, EventsLinksList } from "components";
import { useUser, useOnboarding, OnboardingItem } from "providers";
import { apiUrl, hasBitskiEnabled } from "constants";
import { useCoinviseAPI } from "hooks";
import { useState, useEffect } from "react";

let prevWalletId = null;
export const DashProfileAndWallet = ({ setSnack, refreshedBalanceHackyHacky }) => {
  const { disconnect } = useDisconnect(); // wagmi disconnect
  const { connector, address } = useAccount({
    // wagmi state
    fetchEns: true,
  });
  const {
    user,
    api: { connectWallet },
  } = useUser();
  const authenticatedAxios = useCoinviseAPI(true);
  const [balance, setBalance] = useState("");
  const { isRunning: isOnboardingRunning } = useOnboarding();

  const [isConnectModalOpened, setIsConnectModalOpened] = useState(false);
  const [isDisconnectModalOpened, setIsDisconnectModalOpened] = useState(false);
  const [isWalletPreselectionModalOpened, setIsWalletPreselectionModalOpened] = useState(false);

  const onWalletConnect = ({ address, connector, isReconnected }) => {
    if (address === prevWalletId) {
      // console.log("onWalletConnect: Duplicate wallet connect notification, ignoring:", address);
      return; // duplicate notification
    }
    prevWalletId = address;
    // console.log("onWalletConnect:", address, "connector:", connector?.name, "isReconnected:", isReconnected)
    if (address) {
      connectWallet({ walletId: address })
        .then(() => {
          console.log(
            `Wallet ${isReconnected ? "re" : ""}connected via ${connector?.name} to ${address}.`
          );
        })
        .catch((err) => {
          console.error(`Error connecting to wallet: ${err.message}`);
          disconnect();
          setSnack({
            open: true,
            message: err.message,
            severity: "error",
            timeout: 10000,
          });
        });
    }
  };
  const onWalletDisconnect = () => {
    console.log("onWalletDisconnect: Wallet disconnected.");
  };
  const { isConnected } = useAccount({
    onConnect: onWalletConnect,
    onDisconnect: onWalletDisconnect,
    fetchEns: true,
  });

  useEffect(() => {
    if (!authenticatedAxios) return;
    try {
      if (user) {
        if (refreshedBalanceHackyHacky) {
          setBalance(refreshedBalanceHackyHacky);
          return;
        }

        authenticatedAxios.get(`${apiUrl}/api/transaction/user/balance`).then((res) => {
          setBalance(res.data?.balance || "0");
        });
      }
    } catch (error) {
      console.error("error getting balance:", error);
    }
  }, [authenticatedAxios, user, refreshedBalanceHackyHacky]);

  const openAppropriateModal = () => {
    hasBitskiEnabled ? setIsWalletPreselectionModalOpened(true) : setIsConnectModalOpened(true);
  };

  return (
    <>
      <WalletDisconnect
        isDisconnectModalOpened={isDisconnectModalOpened}
        setIsDisconnectModalOpened={setIsDisconnectModalOpened}
      />
      <WalletSelection
        isConnectModalOpened={isConnectModalOpened}
        setIsConnectModalOpened={setIsConnectModalOpened}
      />
      {hasBitskiEnabled && (
        <WalletSelectionPre
          open={isWalletPreselectionModalOpened}
          onClose={() => {
            setIsWalletPreselectionModalOpened(false);
          }}
          onExistingWalletSelection={() => {
            setIsWalletPreselectionModalOpened(false);
            setIsConnectModalOpened(true);
          }}
          onBitskiSelection={() => {
            setIsWalletPreselectionModalOpened(false);
            ///
          }}
        />
      )}
      <Box
        p={3}
        width="100%"
        display="flex"
        justifyContent={connector || isOnboardingRunning ? "space-between" : "right"}
      >
        {(connector || isOnboardingRunning) && (
          <OnboardingItem
            onboarding={{
              step: 1,
              radius: "50%",
              id: "settings",
              title: "Settings",
              description: "Change your wallet connection settings ",
              maxWidth: "200px",
            }}
          >
            <IconButton size="small" onClick={() => setIsDisconnectModalOpened(true)}>
              <SettingsIcon color="contrastInvert" />
            </IconButton>
          </OnboardingItem>
        )}
        {/* <OnboardingItem
          onboarding={{
            step: 1,
            radius: "50%",
            id: "qr",
            title: "QR",
            description:
              "Scan QR codes at Consensus '23 and claim rewards directly to your CoinDesk account",
            textAlign: "right",
            maxWidth: "200px",
          }}
        >
          <StyledIconButton component={Link} to="/qr/scan-code" size="small">
            <QrCodeScannerIcon color="dark" />
          </StyledIconButton>
        </OnboardingItem> */}
      </Box>
      {/* <Box pb={2}>
        <ProfileLogo large />
      </Box> */}
      <Box>
        {/* {(isConnected || isOnboardingRunning) && ( */}
        <Box display="flex" justifyContent="center">
          <OnboardingItem
            onboarding={{
              id: "desk-balance",
              step: 2,
              title: "Balance",
              placement: "top",
              description:
                "Keep track of the DESK™ you claim between transfers using your CoinDesk balance",
            }}
          >
            <Box>
              <Typography textAlign="center" pb={2}>
                My CoinDesk Balance
              </Typography>

              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                position="relative"
                pb={2}
                pr={1}
              >
                <Fab color="primary" size="medium" sx={{ color: "dark", pointerEvents: "none" }}>
                  <Desk sx={{ fontSize: 48 }} />
                </Fab>
                <Typography variant="h1" pl={1.5} sx={{ fontSize: 42 }}>
                  {balance}
                </Typography>
              </Box>
            </Box>
          </OnboardingItem>
        </Box>
        {/* )} */}
      </Box>
      <Box p={3} mx="auto" display="flex" justifyContent="center" maxWidth={400}>
        <OnboardingItem
          onboarding={{
            step: 2,
            id: "wallet",
            description:
              "Connect a wallet and transfer your tokens when you want to use DESK™ in the CoinDesk ecosystem",
          }}
        >
          {isConnected && address ? (
            <Transfer setSnack={setSnack} walletId={address} balance={balance} />
          ) : (
            <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
              <Button
                id="connectWallet"
                variant="contained"
                color="primary"
                sx={{ width: "100%" }}
                onClick={openAppropriateModal}
              >
                Connect wallet
              </Button>
            </Box>
          )}
        </OnboardingItem>
      </Box>{" "}
      <EventsLinksList />
    </>
  );
};
