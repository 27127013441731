import { Box, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Consensus, TvIcon } from "icons";

export const EventsLinksList = () => {
  const links = [
    {
      text: "Consensus 2024",
      url: "https://consensus.coindesk.com/",
      icon: <Consensus />,
    },
    {
      text: "Consensus Hong Kong",
      url: "https://consensus-hongkong.coindesk.com/",
      icon: <Consensus />,
    },
    {
      text: "CoinDesk Webinars",
      url: "https://events.coindesk.com/",
      icon: <TvIcon />,
    },
  ];

  return (
    <Box p={3} pt={0} mx="auto" width="100%" display="flex" justifyContent="center" maxWidth={400}>
      <Box sx={{ display: "flex", flexDirection: "column", width: "100%", gap: "16px" }}>
        <Title>
          <div className="line" /> Events <div className="line" />
        </Title>
        {links.map((link) => (
          <Button
            key={link.text}
            id={link.text}
            variant="outlined"
            color="inherit"
            sx={{ width: "100%", display: "flex", gap: "8px" }}
            href={link.url}
            target="_blank"
          >
            <Box display="flex" justifyContent="center" gap="8px">
              {link.icon}
              {link.text}
            </Box>
          </Button>
        ))}
      </Box>
    </Box>
  );
};

// styles
const Title = styled("div")`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 6px 0;

  font-size: 16px;

  .line {
    flex-grow: 1;
    height: 1px;
    background-color: black;
  }
`;
