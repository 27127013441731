import { useCallback, useEffect, useState } from "react";

const sleep = (ms = 100) => new Promise((resolve) => setTimeout(resolve, ms));

export const useSailthru = () => {
  const [api, setApi] = useState(
    // eslint-disable-next-line no-restricted-globals
    typeof window !== "undefined" && window.Sailthru ? window.Sailthru : null
  );

  useEffect(() => {
    /* eslint-disable no-restricted-globals */
    if (typeof window !== "undefined" && !api) {
      (async () => {
        while (!window.Sailthru) {
          await sleep(100);
        }
        setApi(window.Sailthru);
      })();
    }
    /* eslint-enable no-restricted-globals */
  }, [api]);

  return api;
};

export const useSubscribe = ({
  lists = [],
  unselectedLists = [],
  defaultLists = [],
  source = null,
  onSuccess = null,
  onError = null,
  marketing = true,
  debug = false,
}) => {
  const api = useSailthru();

  const unsubscribeList = unselectedLists.length
    ? unselectedLists.reduce((acc, list) => {
        acc[list] = 0;
        return acc;
      }, {})
    : {};

  const subscribeList = defaultLists
    .reduce((acc, title) => {
      if (marketing) {
        acc.push(title);
      }
      return acc;
    }, [])
    .concat(lists)
    .reduce((acc, list) => {
      acc[list] = 1;
      return acc;
    }, {});

  const subscribe = useCallback(
    (email, vars = {}) => {
      if (debug) {
        // eslint-disable-next-line no-console
        console.log({
          email,
          lists: Object.assign(subscribeList, unsubscribeList),
          source,
          vars,
        });
      }
      api.integration("userSignUp", {
        email,
        lists: Object.assign(subscribeList, unsubscribeList),
        source,
        vars,
        onSuccess: (data) => {
          if (typeof onSuccess === "function") {
            onSuccess(data);
          }
        },
        onError: (err) => {
          if (typeof onError === "function") {
            onError(err);
          }
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [api, source, lists, unselectedLists, marketing, onSuccess, onError, defaultLists]
  );

  return { subscribe, ready: api !== null };
};
