import { MetaMask } from "./wallets/MetaMask";
import { WalletConnect } from "./wallets/WalletConnect";
import { Coinbase } from "./wallets/Coinbase";
import { Ethereum } from "./wallets/Ethereum";
import { GnosisSafe } from "./wallets/GnosisSafe";
import { Bitski } from "./wallets/Bitski";

export { Discord } from "./Discord";
export { UserMenu } from "./UserMenu";
export { Facebook } from "./Facebook";
export { LinkedIn } from "./LinkedIn";
export { Desk } from "./Desk";
export { CoinDesk } from "./CoinDesk";
export { Polygon } from "./Polygon";
export { IconsSSO } from "./IconsSSO";
export { DeskCoin } from "./DeskCoin";
export { Consensus } from "./Consensus";
export { TvIcon } from "./TvIcon";

export { MetaMask, WalletConnect, Coinbase };

export const Wallets = {
  MetaMask,
  WalletConnect,
  Coinbase,
  Ethereum,
  GnosisSafe,
  Bitski,
};
