import SvgIcon from "@mui/material/SvgIcon";

export const WalletConnect = (props) => (
  <SvgIcon {...props}>
    <svg width="24" height="24" viewBox="0 0 26 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.32585 3.11325C9.56459 -1.03775 16.4354 -1.03775 20.6796 3.11325L21.1896 3.61225C21.4035 3.82063 21.4035 4.15512 21.1896 4.36349L19.4459 6.07434C19.3417 6.17853 19.1662 6.17853 19.062 6.07434L18.3601 5.38891C15.4045 2.49362 10.6065 2.49362 7.65085 5.38891L6.89961 6.12369C6.79542 6.22788 6.61995 6.22788 6.51577 6.12369L4.76653 4.41284C4.55267 4.20447 4.55267 3.86998 4.76653 3.6616L5.32585 3.11325ZM24.2878 6.64463L25.8396 8.16356C26.0535 8.37193 26.0535 8.70642 25.8396 8.91479L18.8372 15.7746C18.6233 15.983 18.2833 15.983 18.0695 15.7746L13.096 10.9053C13.0411 10.856 12.9589 10.856 12.904 10.9053L7.93599 15.7746C7.72213 15.983 7.38216 15.983 7.1683 15.7746L0.160392 8.92028C-0.0534641 8.7119 -0.0534641 8.37741 0.160392 8.16904L1.71222 6.65011C1.92608 6.44174 2.26605 6.44174 2.47991 6.65011L7.45344 11.5195C7.50828 11.5688 7.59053 11.5688 7.64537 11.5195L12.6134 6.65011C12.8273 6.44174 13.1672 6.44174 13.3811 6.65011L18.3546 11.5195C18.4095 11.5688 18.4917 11.5688 18.5466 11.5195L23.5201 6.65011C23.7285 6.43625 24.0739 6.43625 24.2878 6.64463Z"
        fill="#3A99FB"
      />
    </svg>
  </SvgIcon>
);
