import { Button } from "@mui/material";
export const InlineButtonLink = (props) => (
  <Button
    color="success"
    {...props}
    sx={{
      padding: 0,
      fontSize: 16,
      verticalAlign: "inherit",
      minWidth: 0,
      lineHeight: "inherit",
      ...props.sx,
    }}
  >
    {props.children}
  </Button>
);
