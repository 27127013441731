import { Box, Button } from "@mui/material";
import { InsufficientBalance, TransferModal } from "components";
import { useUser } from "providers";
import { Desk } from "icons";
import { forwardRef, useState } from "react";
import { useAccount } from "wagmi";
import { calcWalletIcon, calcWalletName } from "utils";

const TRANSFER_ACTION = "withdraw"; // must match backend's CaptchaController.ts constant
const MINIMUM_TRANSFER_AMOUNT = 4; // Service fee (3) + 1 DESK™

export const Transfer = forwardRef(({ balance, walletId, setSnack }, ref) => {
  const [openModal, setOpenModal] = useState(false);
  const [insufficientBalanceModalOpened, setInsufficientBalanceModalOpened] = useState(false);
  const [isWithdrawInProgress, setWithdrawInProgress] = useState(false);
  const {
    api: { verifyCaptchaV2, verifyCaptchaV3, transferWithdraw },
  } = useUser();

  const handleOpen = () => {
    if (balance < MINIMUM_TRANSFER_AMOUNT) {
      setInsufficientBalanceModalOpened(true);
    } else {
      setOpenModal(true);
    }
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const { connector, isDisconnected } = useAccount({
    fetchEns: true,
  });

  const withdraw = async (e) => {
    let widgetId;
    let score;
    let withdrawToken = null;

    const onCaptchaVerified = async (token, vers) => {
      // This is the common location where we'd go ahead and request the transfer (from the backend)
      transferWithdraw({ withdrawToken: token, walletId })
        .then((res) => {
          if (!res?.success) {
            console.log("Transfer failed:", res.message);
            throw new Error(res.message);
          }
          setSnack({
            open: true,
            message: "Transfer in progress. Check wallet in a few minutes to confirm.",
            severity: "success",
            timeout: 10000,
          });
          console.log("Transfer successfully requested.");
          setWithdrawInProgress(false);
          handleClose();
        })
        .catch((err) => {
          setSnack({
            open: true,
            message: err.message || "Tranfer error. Please try again later.",
            severity: "error",
          });
          if (err.message) {
            console.error(err.message);
          }
          setWithdrawInProgress(false);
          handleClose();
        });
    };

    const v2Callback = function (token) {
      // This is the reCAPTCHA v2 callback
      const response = window.grecaptcha.getResponse(widgetId); // should be the same value as token
      const verifyOptions = {
        action: "withdraw",
        token: response,
      };
      verifyCaptchaV2(verifyOptions)
        .then((result) => {
          if (result.success) {
            onCaptchaVerified(withdrawToken, 2);
          } else {
            setWithdrawInProgress(false);
            handleClose();
          }
        })
        .catch((err) => {
          setSnack({
            open: true,
            message: "reCAPTCHA v2 callback error: " + err.message,
            severity: "error",
          });
          setWithdrawInProgress(false);
          handleClose();
        });
    };

    setWithdrawInProgress(true);
    try {
      e.preventDefault();
      window.grecaptcha.ready(async () => {
        // reCAPTCHA Enterprise v3 - This is effectively the reCAPTCHA v3 callback
        window.grecaptcha
          .execute("6Lea9d8hAAAAAJNu5i9tkpa6RZeSw_-szImoEyRL", { action: TRANSFER_ACTION })
          .then(function (v3Token) {
            // This is where we'll need to use the backend to do the transfer, but first verify via reCAPTCHA
            const verifyOptions = {
              action: "withdraw",
              token: v3Token,
            };
            // Invoke our backend with the result of the reCAPTCHA v3 to obtain a score
            verifyCaptchaV3(verifyOptions)
              .then((response) => {
                // We have the score, now check if we can proceed
                withdrawToken = response.jwt;

                score = response?.percentScore || 0;
                console.log(`reCAPTCHA v3 score = ${score} %.`);
                if (response?.success) {
                  if (response?.useFallback) {
                    // score 70-90% is medium risk
                    // go ahead, but require further reCAPTCHA v2 checks
                    widgetId = window.grecaptcha.render(document.getElementById("recaptcha-v2"), {
                      sitekey: "6LcDSzwiAAAAAK_2HDJxpYR1k41XNkKpBvfB9XRx",
                      callback: v2Callback,
                    });
                  } else {
                    // score >= 90% is low risk
                    // high confidence v3 score, go ahead and do it without further reCAPTCHA v2 checks
                    onCaptchaVerified(withdrawToken, 3);
                  }
                } else {
                  // high risk, very poor reCAPTCHA v3 score, do not go ahead at all
                  const message = response.message || "reCAPTCHA failed with score: " + score;
                  setSnack({
                    open: true,
                    message: `reCAPTCHA v3 low score: ${message}.`,
                    severity: "error",
                  });
                  setWithdrawInProgress(false);
                  handleClose();
                }
              })
              .catch((err) => {
                setSnack({
                  open: true,
                  message: "reCAPTCHA v3 verify error: " + err.message,
                  severity: "error",
                });
                setWithdrawInProgress(false);
                handleClose();
              });
          })
          .catch((err) => {
            setSnack({
              open: true,
              message: "reCAPTCHA v3 error: " + err.message,
              severity: "error",
            });
            setWithdrawInProgress(false);
            handleClose();
          });
      });
    } catch (error) {
      console.error("withdraw() ERROR:\n", error);
      setWithdrawInProgress(false);
      handleClose();
    }
  };

  if (isDisconnected) return <div>No account connected</div>;

  const walletIcon = (connector && calcWalletIcon(calcWalletName(connector))) || null;

  return (
    <>
      <InsufficientBalance
        open={insufficientBalanceModalOpened}
        close={() => setInsufficientBalanceModalOpened(false)}
      />
      <TransferModal
        open={openModal}
        close={handleClose}
        balance={balance}
        withdraw={withdraw}
        walletIcon={walletIcon}
        inProgress={isWithdrawInProgress}
      />
      <Box width="100%">
        <Button
          variant="contained"
          color="dark"
          onClick={handleOpen}
          sx={{ width: "100%" }}
          ref={ref}
        >
          <>
            Spend &nbsp;
            <Desk
              color="dark"
              sx={{ backgroundColor: "primary.main", borderRadius: "50%", width: 20, height: 20 }}
            />
            {MINIMUM_TRANSFER_AMOUNT - 1}&nbsp; to transfer balance
          </>
        </Button>
      </Box>
    </>
  );
});
