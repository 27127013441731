import React, {
  useState,
  useContext,
  useCallback,
  useLayoutEffect,
  useEffect,
  useRef,
  useMemo,
} from "react";
import { Tooltip, Box, Typography, Button, Backdrop, Stack } from "@mui/material";
import { styled } from "@mui/system";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import { hasOnboardingEnabled, hasDailyDeskEnabled } from "constants";
import { useLanding, useUser } from "providers";
import { useNavigate } from "react-router-dom";

const OnboardingContext = React.createContext({
  registerInProvider: () => {},
  updateRectInProvider: () => {},
  isRunning: false,
  setIsResizing: () => {},
  start: () => {},
  currentStep: 1,
  isScrollingStep: false,
});

export const useOnboarding = () => useContext(OnboardingContext);

export const OnboardingItem = ({
  children, // can have only one child
  onboarding: {
    radius = "20px",
    id, // required
    title,
    description,
    step, // required
    shrinkPadding = 0,
    placement = "bottom",
    distance = 0,
    maxWidth = "300px",
    horizontalPlacement, // "left" or "right"
    textAlign = "inherit",
    centerOnStep = false,
    scrollIntoView = false,
  },
}) => {
  const {
    registerInProvider,
    updateRectInProvider,
    isRunning,
    setIsResizing,
    currentStep,
    isScrollingStep,
  } = useOnboarding();
  const [refNode, setRefNode] = useState(null);

  const [top, setTop] = useState(null);
  const [left, setLeft] = useState(null);
  const [width, setWidth] = useState(null);
  const [height, setHeight] = useState(null);

  const [measuringInProgress, setMeasuringInProgress] = useState(false);

  const [isRegistered, setIsRegistered] = useState(false);
  const throttle = useRef();

  const onboardingData = useMemo(
    () => ({
      radius,
      id,
      title,
      description,
      step,
      shrinkPadding,
      placement,
      distance,
      maxWidth,
      horizontalPlacement,
      textAlign,
      centerOnStep,
      measuringInProgress,
      scrollIntoView,
    }),
    [
      radius,
      id,
      title,
      description,
      step,
      shrinkPadding,
      placement,
      distance,
      maxWidth,
      horizontalPlacement,
      textAlign,
      centerOnStep,
      measuringInProgress,
      scrollIntoView,
    ]
  );
  const rectData = useMemo(
    () => (top && left && width && height ? { top, left, width, height } : null),
    [top, left, width, height]
  );

  const elementToPass = useMemo(
    () =>
      rectData
        ? {
            data: onboardingData,
            rect: rectData,
          }
        : null,
    [onboardingData, rectData]
  );

  const initializeRef = useCallback((node) => {
    if (node) {
      setRefNode(node);
    }
  }, []);

  const calcRequiredScrollValue = useCallback(
    (refNode) =>
      refNode
        ? refNode.offsetTop -
          refNode.scrollTop +
          refNode.clientTop -
          window.innerHeight / 2 +
          refNode.offsetHeight / 2
        : 0,
    []
  );

  const measure = useCallback((refNode) => {
    const calcRect = () => {
      const gotRect = refNode.getBoundingClientRect();
      setTop(Math.round(gotRect.top));
      setLeft(Math.round(gotRect.left));
      setWidth(Math.round(gotRect.width));
      setHeight(Math.round(gotRect.height));
      setMeasuringInProgress(false);
    };

    setMeasuringInProgress(true);

    if (!throttle.current) {
      calcRect();
    }

    clearTimeout(throttle.current);
    throttle.current = setTimeout(calcRect, 500);
  }, []);

  useLayoutEffect(() => {
    if (isRunning && refNode) {
      if (scrollIntoView) refNode.scrollIntoView({ block: "nearest", inline: "center" });
      const observer = new ResizeObserver(() => {
        setIsResizing(true);
        measure(refNode);
      });

      measure(refNode);
      observer.observe(document.body);
      return () => {
        observer.unobserve(document.body);
      };
    }
  }, [refNode, isRunning, setIsResizing, measure, scrollIntoView]);

  const isElementInCurrentStep = useMemo(() => step === currentStep, [step, currentStep]);

  useEffect(() => {
    if (isRegistered && isElementInCurrentStep && refNode) {
      if (isScrollingStep && centerOnStep) {
        console.log("scrolling step with centered element");
        window.scrollTo({
          top: calcRequiredScrollValue(refNode),
        });
      } else if (!isScrollingStep) {
        window.scrollTo({
          top: 0,
        });
      }
      measure(refNode);
    }
  }, [
    setIsResizing,
    isRegistered,
    isElementInCurrentStep,
    calcRequiredScrollValue,
    isScrollingStep,
    centerOnStep,
    refNode,
    measure,
  ]);

  useEffect(() => {
    if (!isRegistered && elementToPass) {
      setIsRegistered(true);
      registerInProvider(elementToPass);
      setIsResizing(false);
    }
  }, [registerInProvider, elementToPass, isRegistered, setIsResizing]);

  useEffect(() => {
    if (isRegistered) {
      updateRectInProvider(elementToPass);
      setIsResizing(false);
    }
  }, [updateRectInProvider, isRegistered, elementToPass, setIsResizing]);

  if (!children || children.length > 1) {
    throw new Error("OnboardingItem must have one child");
  }
  if (!id || !step) {
    throw new Error("id and step must be provided to onboarding prop in OnboardingItem");
  }

  return <>{React.cloneElement(children, { ref: initializeRef })}</>;
};

const OnboardingProvider = ({ children }) => {
  const {
    user,
    setUser,
    api: { profilePatch },
  } = useUser();
  const [elements, setElements] = useState([]);
  const [isRunning, setIsRunning] = useState(false);

  const [isResizing, setIsResizing] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [isScrollingStep, setIsScrollingStep] = useState(false);
  const { requestOnboardingStart: landingHasRequestedStart } = useLanding();
  const navigate = useNavigate();

  const stepsTotal = hasDailyDeskEnabled ? 5 : 4;

  useEffect(() => {
    if (isRunning) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  });

  const nextStep = () => {
    setIsResizing(true);
    setCurrentStep((currentStep) => currentStep + 1);
  };

  const previousStep = () => {
    setIsResizing(true);
    setCurrentStep((currentStep) => currentStep - 1);
  };

  const tutorialClosed = () => {
    setUser.progress(2);
    profilePatch({ progress: 2 });
    setIsRunning(false);
  };
  const start = useCallback(() => {
    if (hasOnboardingEnabled) {
      window.scrollTo(0, 0);
      navigate("/");
      setIsRunning(true);
      setCurrentStep(1);
    }
  }, [navigate]);

  useEffect(() => {
    if (landingHasRequestedStart && user.progress === 1) {
      start();
    }
  }, [landingHasRequestedStart, start, user.progress]);

  const registerInProvider = useCallback(
    (registeredElement) => {
      if (!elements.find((e) => e.data.id === registeredElement?.data.id)) {
        setElements((elements) => [...elements, registeredElement]);
      }
    },
    [elements]
  );

  const updateRectInProvider = useCallback(
    (updatedElement) =>
      setElements((elements) => [
        ...elements.map((element) =>
          updatedElement?.data?.id === element.data.id ? updatedElement : element
        ),
      ]),
    []
  );

  const elementsInStep = useMemo(
    () => elements.filter(({ data: { step } }) => step === currentStep),
    [currentStep, elements]
  );

  const hasCenteredElementInCurrentStep = useMemo(
    () => elementsInStep.some((el) => el.data.centerOnStep),
    [elementsInStep]
  );

  useEffect(() => {
    setIsScrollingStep(hasCenteredElementInCurrentStep);
  }, [hasCenteredElementInCurrentStep]);

  const value = {
    registerInProvider,
    updateRectInProvider,
    isRunning,
    setIsResizing,
    start,
    currentStep,
    isScrollingStep,
  };

  const paddingForPlacement = {
    top: "Bottom",
    bottom: "Top",
  };

  return (
    <OnboardingContext.Provider value={value}>
      {isRunning && (
        <>
          <Backdrop
            open={isRunning}
            sx={{
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(0, 0, 0, 0.8)",
              mixBlendMode: "hard-light",
              zIndex: 1498,
              overscrollBehavior: "contain",
            }}
          >
            {!isResizing &&
              elementsInStep.map(
                ({
                  data: {
                    title,
                    description,
                    id,
                    radius,
                    shrinkPadding,
                    placement,
                    distance,
                    maxWidth,
                    horizontalPlacement,
                    textAlign,
                    measuringInProgress,
                  },
                  rect: { top, left, width, height },
                }) => (
                  <Box key={id} sx={{ visibility: measuringInProgress ? "hidden" : "initial" }}>
                    <Tooltip
                      placement={placement}
                      title={
                        <Box>
                          <Typography variant="h6">{title}</Typography>
                          <Typography variant="body2">{description}</Typography>
                        </Box>
                      }
                      open
                      arrow={!!(title || description)}
                      components={{ Arrow: ArrowComponent }}
                      componentsProps={{
                        arrow: {
                          distance,
                        },
                        tooltip: {
                          sx: {
                            [`padding${paddingForPlacement[placement]}`]: `${distance + 20}px`,
                            backgroundColor: "transparent",
                            maxWidth,
                            textAlign,
                            visibility: measuringInProgress ? "hidden" : "initial",
                            div: {
                              transform:
                                horizontalPlacement === "right"
                                  ? "translateX(40%)"
                                  : horizontalPlacement === "left"
                                  ? "translateX(-40%)"
                                  : "translateX(0)",
                            },
                          },
                        },
                      }}
                    >
                      <Box
                        sx={{
                          backgroundColor: "gray",
                          position: "absolute",
                          padding: "15px",
                          borderRadius: radius,
                          top: top - 15 + shrinkPadding,
                          left: left - 15 + shrinkPadding,
                          width: width + 30 - shrinkPadding * 2,
                          height: height + 30 - shrinkPadding * 2,
                        }}
                      ></Box>
                    </Tooltip>
                  </Box>
                )
              )}
          </Backdrop>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-between",
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              zIndex: 1499,
            }}
          >
            <Typography variant="h3" color="secondary" p={4}>
              Introducing: the DESK™ Hub
            </Typography>
            <Box display="flex" flexDirection="column" alignItems="center" p={4}>
              <Typography variant="h5" color="secondary" pb={2}>
                {currentStep}/{stepsTotal}
              </Typography>
              <Stack spacing={2} direction="row">
                {currentStep !== 1 && (
                  <Button
                    variant="outlined"
                    color="white"
                    startIcon={<ChevronLeftIcon />}
                    onClick={previousStep}
                  >
                    Back
                  </Button>
                )}
                {currentStep < stepsTotal && (
                  <Button
                    variant="outlined"
                    color="white"
                    endIcon={<ChevronRightIcon />}
                    onClick={nextStep}
                  >
                    Next
                  </Button>
                )}
                {currentStep === stepsTotal && (
                  <Button variant="outlined" color="white" onClick={tutorialClosed}>
                    Close
                  </Button>
                )}
              </Stack>

              <Button
                variant="link"
                onClick={tutorialClosed}
                disabled={currentStep === stepsTotal}
                sx={{ opacity: currentStep !== stepsTotal ? 1 : 0 }}
              >
                <Typography variant="subtitle" color="secondary" pt={1}>
                  Skip tutorial
                </Typography>
              </Button>
            </Box>
          </Box>
        </>
      )}
      {children}
    </OnboardingContext.Provider>
  );
};

export default OnboardingProvider;

const ArrowComponent = styled("span")`
  height: ${(props) => props.distance + 30}px;
  width: 1px;
  position: absolute;
  &:before {
    content: "";
    margin: auto;
    display: block;
    width: 100%;
    height: 100%;
    background-color: #fff;
  }
`;
