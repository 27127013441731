import { Box, Typography, Fab, Stack, Grid, Button, CircularProgress } from "@mui/material";
import { useMemo } from "react";
import { Desk } from "icons";
import { useState } from "react";
import { DialogWrapper } from "components/dialogs";
import { JourneyBonusReward } from "./JourneyBonusReward";

const JourneyLevelRewards = ({
  isLoading,
  activeLevelBody,
  activeLevelIndex,
  activeLevelBonuses,
  claimRewardFn,
  setRewards,
  setActiveLevelIndex,
  showFinalBonusUI,
}) => {
  const [bonusToShowInModal, setBonusToShowInModal] = useState(null);
  const [isClaiming, setIsClaiming] = useState(false);

  const firstClaimableBonusId = useMemo(
    () => activeLevelBonuses.find((bonus) => bonus.isClaimable === true)?.id,
    [activeLevelBonuses]
  );

  const claimedBonusId = useMemo(
    () => activeLevelBonuses.find((bonus) => bonus.isClaimed === true)?.id,
    [activeLevelBonuses]
  );

  if (!activeLevelBonuses) return null;
  if (!activeLevelBody) return null;
  if (activeLevelBonuses.length === 0 && activeLevelBody.amount === "0") return null;

  const claimBonus = (currentIndex) => {
    const thisBonusId = bonusToShowInModal.id;
    setIsClaiming(true);
    claimRewardFn(thisBonusId)
      .then(() => {
        setBonusToShowInModal(null);
        setIsClaiming(false);
      })
      .catch((e) => {
        setIsClaiming(false);
        console.error(e);
        if (
          e.response.status === 410 ||
          e.response.data.reason ===
            "This reward has already claimed the maximum number of times by users."
        ) {
          const nextBonusId = activeLevelBonuses[currentIndex + 1]?.id;
          if (nextBonusId) {
            setRewards((prev) => ({
              ...prev,
              [nextBonusId]: { ...prev[nextBonusId], isClaimable: true },
            }));
            setRewards((prev) =>
              prev.map((reward) =>
                reward.id === nextBonusId ? { ...reward, isClaimable: true } : reward
              )
            );
          }

          setRewards((prev) =>
            prev.map((reward) =>
              reward.id === thisBonusId ? { ...reward, isClaimable: true } : reward
            )
          );

          setBonusToShowInModal(null);
        } else {
          alert(e.response.data.reason);
        }
      });
  };

  return (
    <Box
      sx={{
        border: "2px solid #F8BF1E",
        borderRadius: "6px",

        backgroundColor: "#000000",
      }}
    >
      <DialogWrapper
        {...{
          open: !!bonusToShowInModal,
          onClose: () => setBonusToShowInModal(null),
          image: bonusToShowInModal?.image,
          title: bonusToShowInModal?.title,
          description: (
            <>
              <Typography>
                By being one of the first to complete this level, you've earned one our exclusive
                DESK rewards.
              </Typography>
              <Typography>
                To claim, go to the DESK Hub in the Austin Convention Center and ask for the Prize
                Master.
              </Typography>
              <Typography>
                Head's up: you'll need to verify your account info, which you can access from the
                DESK Profile page.
              </Typography>
              <Typography>Congrats!</Typography>
            </>
          ),
        }}
      >
        <Stack spacing={2} px={5}>
          {bonusToShowInModal?.isClaimed ? (
            <Button
              variant="outlined"
              color="contrastInvert"
              onClick={() => setBonusToShowInModal(null)}
            >
              Got it
            </Button>
          ) : (
            <>
              <Button variant="contained" onClick={() => claimBonus(bonusToShowInModal?.index)}>
                {isClaiming ? (
                  <>
                    &nbsp;
                    <CircularProgress color="dark" size={20} />
                    &nbsp;
                  </>
                ) : (
                  "Claim"
                )}
              </Button>
            </>
          )}
        </Stack>
      </DialogWrapper>
      <Box p={2}>
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "baseline" }}>
          <Typography variant="montserrat_bold" sx={{ fontSize: 18 }}>
            {showFinalBonusUI
              ? "Bonus Level Reward"
              : `Level ${!isLoading && activeLevelIndex + 1} Rewards`}
          </Typography>
        </Box>

        <Stack
          direction="row"
          spacing={2}
          mt={1}
          sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
        >
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ width: 80, height: 64, position: "relative", top: 15 }}>
              <Fab
                color="primary"
                size="small"
                sx={{
                  color: "dark",
                  pointerEvents: "none",
                  transform: "skew(0, -10deg) scaleX(0.8)",
                  boxShadow: "-6px -2px 0 #e39a24, -3px -1px 0 #e39a24",
                }}
              >
                <Desk sx={{ fontSize: 40, transform: "scaleX(0.95)" }} />
              </Fab>
            </Box>
            <Typography variant="montserrat_bold" sx={{ fontSize: 16, mr: 1 }}>
              {isLoading ? "..." : `+${activeLevelBody.amount}`}
            </Typography>
          </Box>
        </Stack>
      </Box>
      {activeLevelBonuses.length > 0 && (
        <Typography px={4}>
          Earn these rewards by being the
          <br />
          first to complete this level
        </Typography>
      )}
      <Grid container spacing={2} p={1} pb={3} sx={{ justifyContent: "center" }}>
        {activeLevelBonuses.map((bonus, index) => (
          <Grid item xs={6} key={bonus.id}>
            <JourneyBonusReward
              bonus={bonus}
              index={index}
              activeLevelBody={activeLevelBody}
              firstClaimableBonusId={firstClaimableBonusId}
              claimedBonusId={claimedBonusId}
              setBonusToShowInModal={setBonusToShowInModal}
            />
          </Grid>
        ))}
        {activeLevelBody.isClaimed && (
          <Grid item xs={12}>
            <Button variant="outlined" onClick={() => setActiveLevelIndex((prev) => prev + 1)}>
              Get even more rewards at next level!
            </Button>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export { JourneyLevelRewards };
