import { Box, Fab, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { forwardRef, useEffect, useState } from "react";
import { useQueryClient } from "react-query";

import { Desk } from "icons";

import { apiUrl } from "constants";
import { useCoinviseAPI } from "hooks";
import { useUser } from "providers";
import { SpinningCoin } from "components/SpinningCoin";
import { calcGradient } from "utils";
import { hasConsensusHub } from "constants";

export const DailyCheckinCard = forwardRef(
  ({ children, day, isClaimed, isActive, isExpired, dailyReward, width, setBalance }, ref) => {
    const [claimed, setClaimed] = useState(null);
    const { token } = useUser();
    const queryClient = useQueryClient();
    const isFuture = !isClaimed && !isActive && !isExpired;

    useEffect(() => {
      setClaimed(isClaimed);
    }, [isClaimed]);

    const api = useCoinviseAPI(true);

    const handleClaim = async () => {
      if (!api || claimed || isExpired) return;

      // only allow 1 claim click
      try {
        const response = await api.request({
          method: "POST",
          url: `${apiUrl}/api/daily/claim`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        console.log("CLAIM_RESPONSE:", response);
        setBalance(response.data.balance);
        // {
        //   balance: "10000000000000000000"
        // createdAt: "2022-07-29T17:44:39.886Z"
        // dailyRewardClaimStreak: 1
        // id: "bf95381d-3310-4e57-9f06-170ff2d919e2"
        // isActive: true
        // lastDailyRewardClaimDate: "2022-07-29T00:00:00.000Z"
        // sharedId: "a15424e6-2fbf-4a13-bb69-7d636628ba6f"
        // updatedAt: "2022-07-29T17:44:57.465Z"
        // walletAddress: null
        // }

        // Update the claimed data to reflect in the UI instantly
        await queryClient.invalidateQueries("claimed-daily-reward");
      } catch (error) {
        console.error("CLAIM ERROR:", error);
      }

      setClaimed(true);
    };

    const isFullWidth = false;

    return (
      <DailyCheckinBox
        gradient={!isExpired && !isClaimed && !isFuture ? 1 : 0}
        onClick={isActive ? handleClaim : null}
        ref={ref}
        sx={{
          backgroundColor: isFullWidth
            ? "rgba(38,38,38,0.05)"
            : claimed
            ? hasConsensusHub
              ? "dark.main"
              : "primary.light"
            : isExpired || isFuture
            ? "rgba(38,38,38,0.05)"
            : "",
          border: "1px solid",
          borderColor: isActive
            ? hasConsensusHub
              ? "primary.white"
              : "primary.main"
            : hasConsensusHub
            ? "rgba(255,255,255,0.2)"
            : "transparent",
          minWidth: isFullWidth ? "367px" : "84px",
          cursor: isActive && !claimed ? "pointer" : "default",
        }}
      >
        <Typography
          component="span"
          variant="p"
          fontWeight={isActive ? 700 : 400}
          sx={{ color: isExpired || isFuture ? "#999999" : "inherit" }}
        >
          Day {day + 1}
        </Typography>

        {isActive && !claimed ? (
          <Box sx={{ transform: "scale(0.425) translateY(-50px)", height: 0 }}>
            <SpinningCoin />
          </Box>
        ) : (
          <Fab
            color="primary"
            size="small"
            sx={{
              color: "dark",
              pointerEvents: "none",
              transform: "skew(0, -10deg) scaleX(0.8)",
              boxShadow: "-6px -2px 0 #e39a24, -3px -1px 0 #e39a24",
              opacity: isExpired || isFuture ? 0.7 : 1,
            }}
          >
            <Desk sx={{ fontSize: 40, transform: "scaleX(0.95)" }} />
          </Fab>
        )}
        <Typography
          sx={{
            width: "84px",
            textAlign: "center",
            color: isExpired || isFuture ? "#999999" : "inherit",
            fontWeight: isExpired || claimed ? 400 : 700,
          }}
        >
          {claimed
            ? "Claimed"
            : isActive
            ? `+${dailyReward}`
            : isExpired
            ? "Expired"
            : `+${dailyReward}`}
        </Typography>
      </DailyCheckinBox>
    );
  }
);

const DailyCheckinBox = styled(Box)((props) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  width: "84px",
  height: "114px",
  padding: "7px 11px 10px 12px",
  borderRadius: "5px",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundImage: props.gradient && calcGradient(props.theme.palette.mode),
}));
