import { Box, Typography, Divider, Grid, Link, IconButton } from "@mui/material";
import TwitterIcon from "@mui/icons-material/Twitter";
import RssFeedIcon from "@mui/icons-material/RssFeed";
import { Facebook, LinkedIn } from "icons";
import { EXTERNAL_HREFS, publicUrl } from "constants";
import { useAnalytics } from "providers";

export const Footer = () => {
  const analytics = useAnalytics();

  const trackExternal = (event, url, name) => {
    if (analytics) {
      analytics.track("footer_clicks", {
        link_position: "",
        link_class: "",
        link_domain: "",
        link_id: "",
        link_url: url,
        link_name: name,
        module_name: "",
        module_version: "",
      });
    }
  };

  return (
    <>
      <Box bgcolor="dark.main" color="dark.contrastText" p={3}>
        <Box mt={3}>
          <img src={`${publicUrl}/logo-alt.svg`} alt="CoinDesk logo" width="172" height="28" />
        </Box>
        <Grid container pb={6}>
          <Grid item xs={6} md={3}>
            <Typography variant="h3" mt={6} mb={5}>
              About
            </Typography>

            <Link
              mb={3}
              href={EXTERNAL_HREFS["about"]}
              color="inherit"
              underline="hover"
              onClick={(event) => trackExternal(event, EXTERNAL_HREFS["about"], "About Coindesk")}
            >
              <Typography pb={1}>About CoinDesk</Typography>
            </Link>

            <Link
              mb={3}
              href={EXTERNAL_HREFS["masthead"]}
              color="inherit"
              underline="hover"
              onClick={(event) => trackExternal(event, EXTERNAL_HREFS["masthead"], "Masthead")}
            >
              <Typography pb={1}>Masthead</Typography>
            </Link>

            <Link
              mb={3}
              href={EXTERNAL_HREFS["contributors"]}
              color="inherit"
              underline="hover"
              onClick={(event) =>
                trackExternal(event, EXTERNAL_HREFS["contributors"], "Contributors")
              }
            >
              <Typography pb={1}>Contributors</Typography>
            </Link>

            <Link
              mb={3}
              href={EXTERNAL_HREFS["about"]}
              color="inherit"
              underline="hover"
              onClick={(event) => trackExternal(event, EXTERNAL_HREFS["about"], "Career")}
            >
              <Typography pb={1}>Career</Typography>
            </Link>

            <Link
              mb={3}
              href={EXTERNAL_HREFS["company"]}
              color="inherit"
              underline="hover"
              onClick={(event) => trackExternal(event, EXTERNAL_HREFS["company"], "Company News")}
            >
              <Typography pb={1}>Company News</Typography>
            </Link>
          </Grid>
          <Grid item xs={6} md={3}>
            <Typography variant="h3" mt={6} mb={5}>
              Stay Updated
            </Typography>

            <Link
              mb={3}
              href={EXTERNAL_HREFS["consensus23"]}
              color="inherit"
              underline="hover"
              onClick={(event) => trackExternal(event, EXTERNAL_HREFS["consensus23"], "Consensus")}
            >
              <Typography pb={1}>Consensus</Typography>
            </Link>

            <Link
              mb={3}
              href={EXTERNAL_HREFS["newsletters"]}
              color="inherit"
              underline="hover"
              onClick={(event) =>
                trackExternal(event, EXTERNAL_HREFS["newsletters"], "Newsletters")
              }
            >
              <Typography pb={1}>Newsletters</Typography>
            </Link>

            <Link
              mb={3}
              href={EXTERNAL_HREFS["follow"]}
              color="inherit"
              underline="hover"
              onClick={(event) => trackExternal(event, EXTERNAL_HREFS["follow"], "Follow Us")}
            >
              <Typography pb={1}>Follow Us</Typography>
            </Link>
          </Grid>

          <Grid item xs={6} md={3}>
            <Typography variant="h3" mt={6} mb={5}>
              Get In Touch
            </Typography>

            <Link
              mb={3}
              href={EXTERNAL_HREFS["follow"]}
              color="inherit"
              underline="hover"
              onClick={(event) => trackExternal(event, EXTERNAL_HREFS["follow"], "Advertise")}
            >
              <Typography pb={1}>Advertise</Typography>
            </Link>

            <Link
              mb={3}
              href={EXTERNAL_HREFS["accessibility"]}
              color="inherit"
              underline="hover"
              onClick={(event) =>
                trackExternal(event, EXTERNAL_HREFS["accessibility"], "Accessibility Help")
              }
            >
              <Typography pb={1}>Accessibility Help</Typography>
            </Link>
          </Grid>

          <Grid item xs={6} md={3}>
            <Typography variant="h3" mt={6} mb={5}>
              The Fine Print
            </Typography>

            <Link
              mb={3}
              href={EXTERNAL_HREFS["ethics"]}
              color="inherit"
              underline="hover"
              onClick={(event) => trackExternal(event, EXTERNAL_HREFS["ethics"], "Ethics Policy")}
            >
              <Typography pb={1}>Ethics Policy</Typography>
            </Link>

            <Link
              mb={3}
              href={EXTERNAL_HREFS["privacy"]}
              color="inherit"
              underline="hover"
              onClick={(event) => trackExternal(event, EXTERNAL_HREFS["privacy"], "Privacy Policy")}
            >
              <Typography pb={1}>Privacy</Typography>
            </Link>
            <Link
              mb={3}
              href={EXTERNAL_HREFS["terms"]}
              color="inherit"
              underline="hover"
              onClick={(event) => trackExternal(event, EXTERNAL_HREFS["terms"], "Terms of Service")}
            >
              <Typography pb={1}>Terms of Use</Typography>
            </Link>
            <Link
              align="left"
              mb={3}
              component="button"
              color="inherit"
              underline="hover"
              onClick={() => {
                if (window.Cookiebot) {
                  window.Cookiebot.renew();
                } else {
                  console.log("Cookiebot not found.");
                }
              }}
            >
              <Typography pb={1}>Update My Cookie Consent</Typography>
            </Link>
            <Link
              mb={3}
              href={EXTERNAL_HREFS["dnsmpi"]}
              color="inherit"
              underline="hover"
              onClick={(event) => trackExternal(event, EXTERNAL_HREFS["dnsmpi"], "Do Not Sell My Personal Information")}
            >
              <Typography pb={1}>Do Not Sell My Personal Information</Typography>
            </Link>
          </Grid>
        </Grid>
        <Divider sx={{ borderColor: "rgba(255, 255, 255, 0.3)" }} />
        <Typography variant="body2" pt={2}>
        Please note that our{" "}
        <Link
            href={EXTERNAL_HREFS["privacy"]}
            underline="hover"
            color="success.light"
            onClick={(event) =>
              trackExternal(event, EXTERNAL_HREFS["privacy"], "privacy policy")
            }
          >
            privacy policy
          </Link>
        ,{" "}
        <Link
            href={EXTERNAL_HREFS["terms"]}
            underline="hover"
            color="success.light"
            onClick={(event) =>
              trackExternal(event, EXTERNAL_HREFS["terms"], "terms of use")
            }
          >
            terms of use
          </Link>
        ,{" "}
        <Link
            href={EXTERNAL_HREFS["cookies"]}
            underline="hover"
            color="success.light"
            onClick={(event) =>
              trackExternal(event, EXTERNAL_HREFS["cookies"], "cookies")
            }
          >
            cookies
          </Link>
        , and{" "}
        <Link
            href={EXTERNAL_HREFS["dnsmpi"]}
            underline="hover"
            color="success.light"
            onClick={(event) =>
              trackExternal(event, EXTERNAL_HREFS["dnsmpi"], "do not sell my personal information ")
            }
          >
            do not sell my personal information
          </Link>
        {" "}has been updated.
        </Typography>
        <Typography variant="body2" pt={2}>
        CoinDesk is an{" "}
          <Link
            href={EXTERNAL_HREFS["award_winning"]}
            underline="hover"
            color="success.light"
            onClick={(event) =>
              trackExternal(event, EXTERNAL_HREFS["award_winning"], "award-winning")
            }
          >
            award-winning
          </Link>
          {" "}media outlet that covers the cryptocurrency industry. Its journalists abide by a{" "}
          <Link
            href={EXTERNAL_HREFS["ethics"]}
            underline="hover"
            color="success.light"
            onClick={(event) =>
              trackExternal(event, EXTERNAL_HREFS["ethics"], "strict set of editorial policies")
            }
          >
            strict set of editorial policies
          </Link>
          . In November 2023,{" "}
          <Link
            href={EXTERNAL_HREFS["bullish_news"]}
            underline="hover"
            color="success.light"
            onClick={(event) =>
              trackExternal(event, EXTERNAL_HREFS["bullish_news"], "CoinDesk was acquired")
            }
          >
            CoinDesk was acquired
          </Link>{" "}
          by the Bullish group, owner of{" "}
          <Link
            href={EXTERNAL_HREFS["bullish"]}
            underline="hover"
            color="success.light"
            onClick={(event) => trackExternal(event, EXTERNAL_HREFS["bullish"], "Bullish")}
          >
            Bullish
          </Link>
          , a regulated, digital assets exchange. The Bullish group is majority-owned by{" "}
          <Link
            href={EXTERNAL_HREFS["block_one"]}
            underline="hover"
            color="success.light"
            onClick={(event) => trackExternal(event, EXTERNAL_HREFS["block_one"], "Block.one")}
          >
            Block.one
          </Link>
          ; both companies have{" "}
          <Link
            href={EXTERNAL_HREFS["block_one_portfolio"]}
            underline="hover"
            color="success.light"
            onClick={(event) => trackExternal(event, EXTERNAL_HREFS["block_one_portfolio"], "interests")}
          >
            interests
          </Link>
          {" "}in a variety of blockchain and digital asset businesses and significant holdings of digital assets, including bitcoin. CoinDesk operates as an independent subsidiary with an editorial committee to protect journalistic independence. CoinDesk employees, including journalists, may receive options in the Bullish group as part of their compensation.
        </Typography>
        <Box display="flex" justifyContent="space-between" alignItems="center" pt={5}>
          <Typography variant="body2">&copy;2024 CoinDesk</Typography>
          <Box display="flex" justifyContent="center" p={4}>
            <IconButton
              sx={{ padding: 2 }}
              href={EXTERNAL_HREFS["twitter"]}
              onClick={(event) => trackExternal(event, EXTERNAL_HREFS["twitter"], "Twitter")}
            >
              <TwitterIcon color="secondary" />
            </IconButton>
            <IconButton
              sx={{ padding: 2 }}
              href={EXTERNAL_HREFS["facebook"]}
              onClick={(event) => trackExternal(event, EXTERNAL_HREFS["facebook"], "Facebook")}
            >
              <Facebook color="secondary" />
            </IconButton>
            <IconButton
              sx={{ padding: 2 }}
              href={EXTERNAL_HREFS["linkedin"]}
              onClick={(event) => trackExternal(event, EXTERNAL_HREFS["linkedin"], "LinkedIn")}
            >
              <LinkedIn color="secondary" />
            </IconButton>
            <IconButton
              sx={{ padding: 2 }}
              href={EXTERNAL_HREFS["rssfeed"]}
              onClick={(event) => trackExternal(event, EXTERNAL_HREFS["rssfeed"], "RSS Feed")}
            >
              <RssFeedIcon color="secondary" />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </>
  );
};
