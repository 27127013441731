import {
  IconButton,
  Button,
  Stack,
  Box,
  Typography,
  Link,
  Dialog,
  FormHelperText,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useEffect, useState, useCallback } from "react";
import CloseIcon from "@mui/icons-material/Close";

import { useUser, useDeskAuth } from "providers";

const TERMS_TIMEOUT = 5 * 60 * 1000; // 5 minutes

let timeoutId = null;

export const DialogTerms = () => {
  const [isTermsDialogOpen, setIsTermsDialogOpen] = useState(false);
  const [showDialog, setShowDialog] = useState(true); // once cleared, don't show again

  const { deskLogout } = useDeskAuth();
  const {
    isLoading,
    user,
    setUser,
    api: { profilePatch, profileDelete },
  } = useUser();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onCloseDialog = useCallback(() => {
    // clear the timeout since we may have gotten here without the timeout
    if (timeoutId) {
      clearTimeout(timeoutId);
      profileDelete(); // delete (temporary-created) user!!!
      setIsTermsDialogOpen(false);
      deskLogout();
    }
  }, [profileDelete, deskLogout]);

  // useEffect(() => {
  //   const termsAcceptedOn = new Date(user.confirmedAt).getTime() / 1000;
  //   if (user?.id) { // we have a user context
  //     console.log(`User ${user.id} '${user.firstName} ${user.lastName}': termsAcceptedOn ${termsAcceptedOn} [${user.confirmedAt}]`);
  //   }
  // }, [user]);

  useEffect(() => {
    if (user && !isLoading) {
      const termsAcceptedOn = new Date(user.confirmedAt).getTime() / 1000;
      const hasAcceptedTerms = termsAcceptedOn !== 0; // ever accepted?
      if (showDialog && !hasAcceptedTerms) {
        setShowDialog(false); // don't show again
        setIsTermsDialogOpen(true);
        // We must only set the timer if not defined AND the dialog is not open,
        // otherwise there is a race condition in acceptTerms that can allow us
        // to set another timer after terms are accepted (and delete the user).
        if (!timeoutId) {
          timeoutId = setTimeout(() => {
            onCloseDialog();
          }, TERMS_TIMEOUT);
        }
      }
    }
  }, [isLoading, showDialog, user, onCloseDialog]);

  const acceptTerms = async () => {
    // close it first, then clear the timeout
    setIsTermsDialogOpen(false);

    // clear the existing timeout so that we don't delete the user
    if (timeoutId) {
      clearTimeout(timeoutId);
      timeoutId = null;
    }

    // we can be optimistic here, the worst can happen is a modal again
    setUser.confirmedAt(new Date());

    profilePatch({ confirmedAt: new Date() })
      .then((updatedUser) => {
        // setUser.confirmedAt(updatedUser.confirmedAt);
        // console.log("profile patch complete: confirmedAt =", updatedUser.confirmedAt);
      })
      .catch((err) => {
        console.log("profile patch error", err);
      });
  };

  // hook the unload event to catch browser window closes too.
  window.onunload = onCloseDialog;

  return (
    <Dialog
      open={isTermsDialogOpen}
      sx={{ zIndex: 1301 }}
      slots={{
        backdrop: () => null,
      }}
    >
      <Box
        pt={2}
        pr={2}
        width="100%"
        display="flex"
        justifyContent="right"
        sx={{ background: "#ffffff" }}
      >
        <IconButton color="dark" onClick={onCloseDialog}>
          <CloseIcon />
        </IconButton>
      </Box>

      <Box
        p={5}
        pt={0}
        sx={{ width: 500, maxWidth: "100%", background: "#ffffff", color: "dark.main" }}
      >
        <Typography variant="h1" textAlign="center">
          One last thing
        </Typography>
        <Stack spacing={2} py={2}>
          <Box display="flex" justifyContent="center">
            <img src={`/terms.png`} alt="Email message" width="323" height="323" />
          </Box>

          <Typography p={1}>
            We need you to agree to our Terms of Service and Privacy Policy before you can access
            your CoinDesk account.
          </Typography>
          <Box>
            <FormControlLabel
              sx={{
                alignItems: "flex-start",
                color: !!errors?.terms ? "error.main" : "inherit",
                marginLeft: 0,
              }}
              control={
                <Checkbox
                  id="terms"
                  name="terms"
                  {...register("terms", { required: "You need to accept terms." })}
                  color="dark"
                  sx={{ pl: 0, pt: 0, color: !!errors?.terms ? "error.main" : "inherit" }}
                />
              }
              label={
                <>
                  I have read and agree to CoinDesk's{" "}
                  <Link
                    href="https://coindesk.com/terms"
                    target="_blank"
                    sx={{
                      color: "dark.main",
                      textDecorationColor: "inherit",
                      zIndex: 3,
                    }}
                  >
                    Terms of Service
                  </Link>{" "}
                  and{" "}
                  <Link
                    href="https://coindesk.com/privacy"
                    target="_blank"
                    sx={{
                      color: "dark.main",
                      textDecorationColor: "inherit",
                      zIndex: 3,
                    }}
                  >
                    Privacy Policy
                  </Link>
                  .*
                </>
              }
            />
            {!!errors?.terms && (
              <FormHelperText error sx={{ pl: 4 }}>
                {errors?.terms?.message}
              </FormHelperText>
            )}
          </Box>

          <Box>
            <Button
              sx={{ width: "100%" }}
              variant="contained"
              color="primary"
              onClick={handleSubmit(acceptTerms)}
            >
              Submit
            </Button>
          </Box>
        </Stack>
      </Box>
    </Dialog>
  );
};
