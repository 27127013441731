import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { useEffect, useState } from "react";
import { clientTZOffset } from "constants";

/**
 *
 * @param {boolean} authRequired If `true`, adds Bearer token to the request header
 * @returns {import("axios").AxiosInstance} Axios instance with configured headers
 */
export const useCoinviseAPI = (authRequired = false) => {
  const { getIdTokenClaims, isLoading, isAuthenticated } = useAuth0();

  const [authToken, setAuthToken] = useState(null);
  const [axiosInstance, setAxiosInstance] = useState(null);

  useEffect(() => {
    if (isLoading || !isAuthenticated || authToken) return;

    getIdTokenClaims().then((claims) => {
      setAuthToken(claims.__raw);
    });
  }, [authToken, getIdTokenClaims, isAuthenticated, isLoading]);

  useEffect(() => {
    if (authRequired && !authToken) return;

    let instance;
    if (!authRequired) instance = axios.create({});
    else if (authToken)
      instance = axios.create({
        headers: {
          Authorization: `Bearer ${authToken}`,
          "X-DESK-TZOFFSET": clientTZOffset,
        },
      });

    setAxiosInstance(() => instance);
  }, [authRequired, authToken]);

  return axiosInstance;
};
