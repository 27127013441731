import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Alert, Box, Typography } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import InfoIcon from "@mui/icons-material/Info";
import { styled } from "@mui/system";

import { DailyCheckinCard } from "components";
import { useCoinviseAPI } from "hooks";
import { Desk } from "icons";
import { apiUrl, hasConsensusHub } from "constants";
import { OnboardingItem } from "providers";

export const DailyCheckin = ({ setBalance }) => {
  const [days, setDays] = useState([
    { day: 0, dayString: "DAY_1", isClaimed: null, isActive: null, isExpired: null },
    { day: 1, dayString: "DAY_2", isClaimed: null, isActive: null, isExpired: null },
    { day: 2, dayString: "DAY_3", isClaimed: null, isActive: null, isExpired: null },
    { day: 3, dayString: "DAY_4", isClaimed: null, isActive: null, isExpired: null },
    {
      day: 4,
      dayString: "DAY_5",
      isClaimed: null,
      isActive: null,
      isExpired: null,
    },
    {
      day: 5,
      dayString: "DAY_6",
      isClaimed: null,
      isActive: null,
      isExpired: null,
    },
    {
      day: 6,
      dayString: "DAY_7",
      isClaimed: null,
      isActive: null,
      isExpired: null,
    },
  ]);

  const [message, setMessage] = useState(null);
  const authenticatedAxios = useCoinviseAPI(true);

  const authRequiredConfig = {
    enabled: Boolean(authenticatedAxios),
  };

  const getClaimedDailyReward = async () => {
    const data = await authenticatedAxios
      //.get(`${coinviseApi}/transaction/daily-reward`)
      .get(`${apiUrl}/api/daily/transactions`)
      .then((res) => res.data);
    // console.log("claimed dailyReward:", data);
    return data;
  };

  const getCurrentDay = async () => {
    const data = await authenticatedAxios
      .get(`${apiUrl}/api/daily/current`)
      .then((res) => res.data);
    // console.log("current day:", data);
    return data;
  };

  const getDailyReward = async () => {
    const data = await authenticatedAxios.get(`${apiUrl}/api/daily/reward`).then((res) => res.data);
    // console.log("Daily reward:", data);
    return data;
  };

  const [currentDay, setCurrentDay] = useState(null);
  const { data: currentDayData } = useQuery("current-day", getCurrentDay, authRequiredConfig);
  useEffect(() => {
    if (currentDayData) setCurrentDay(currentDayData);
  }, [currentDayData]);

  useEffect(() => {
    if (currentDay) {
      setDays((currDays) =>
        currDays.map((day) => {
          if (currentDay.currentDayValue === day.day) {
            return {
              ...day,
              isActive: true,
            };
          } else if (currentDay.currentDayValue > day.day) {
            return {
              ...day,
              isExpired: true,
            };
          }

          return day;
        })
      );
    }
  }, [currentDay]);

  // Detail about the daily reward -- includes the reward amount
  const [dailyReward, setDailyReward] = useState(null);
  const { data: dailyRewardData } = useQuery("daily-reward", getDailyReward, {
    ...authRequiredConfig,
    staleTime: 30 * 60 * 1000,
    cacheTime: 30 * 60 * 1000,
  });
  useEffect(() => {
    if (dailyRewardData) setDailyReward(dailyRewardData);
  }, [dailyRewardData]);

  // daily reward claim transactions for the relative week
  const [claimedDailyReward, setClaimedDailyReward] = useState(null);
  const { data: claimedDailyRewardData } = useQuery(
    "claimed-daily-reward",
    getClaimedDailyReward,
    authRequiredConfig
  );

  useEffect(() => {
    if (claimedDailyRewardData) setClaimedDailyReward(claimedDailyRewardData);
  }, [claimedDailyRewardData]);

  useEffect(() => {
    if (claimedDailyReward) {
      setDays((currDays) =>
        currDays.map((day) => {
          if (claimedDailyReward[day.dayString]?.claimed) {
            return {
              ...day,
              isClaimed: true,
            };
          } else {
            return day;
          }
        })
      );
    }
  }, [claimedDailyReward]);

  return (
    <>
      <StyledBox mt={4}>
        <>
          <Typography sx={{ fontSize: "24px", lineHeight: "30px", fontWeight: "700" }}>
            Your Daily <Desk sx={{ mr: -0.4, mb: -0.8, ml: -0.6, fontSize: 30 }} />
            ESK™
          </Typography>
          <Typography
            sx={{ paddingBottom: "16px" }}
            color={hasConsensusHub ? "consensus.purple" : "success.main"}
            fontWeight="700"
          >
            Claim Now!
          </Typography>
          <OnboardingItem
            onboarding={{
              id: "daily-checkin-main",
              step: 5,
              centerOnStep: true,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: { xs: "flex-start", sm: "center" },
                gap: "24px",
                pb: "16px",
                overflow: { xs: "scroll", sm: "auto" },
                width: { xs: "373px", sm: "100%" },
              }}
            >
              {days.map(({ day, dayString, isClaimed, isActive, isExpired }) => {
                return (
                  dailyReward && (
                    <Box display="inline" key={day}>
                      {isActive ? (
                        <>
                          <OnboardingItem
                            onboarding={{
                              id: "daily-checkin-claim",
                              step: 5,
                              description:
                                "Get a little DESK™ for visiting each day – string together 7 consecutive days for a streak bonus",
                              placement: "top",
                              scrollIntoView: true,
                            }}
                          >
                            <DailyCheckinCard
                              day={day}
                              dayString={dayString}
                              isClaimed={isClaimed}
                              isActive={isActive}
                              isExpired={isExpired}
                              dailyReward={dailyReward.amount}
                              claimedDailyReward={claimedDailyReward}
                              setBalance={setBalance}
                            />
                          </OnboardingItem>
                        </>
                      ) : (
                        <DailyCheckinCard
                          day={day}
                          dayString={dayString}
                          isClaimed={isClaimed}
                          isActive={isActive}
                          isExpired={isExpired}
                          dailyReward={dailyReward.amount}
                          claimedDailyReward={claimedDailyReward}
                          setBalance={setBalance}
                        />
                      )}
                    </Box>
                  )
                );
              })}
            </Box>
          </OnboardingItem>
        </>
      </StyledBox>
      <Box>
        {message && (
          <Box mx={-1}>
            <Alert
              onClose={() => setMessage(null)}
              severity={message.type}
              icon={
                message.type === "error" ? (
                  <ErrorIcon fontSize="inherit" />
                ) : (
                  <InfoIcon fontSize="inherit" />
                )
              }
            >
              <Typography variant="body1">{message.content}</Typography>
            </Alert>
          </Box>
        )}
      </Box>
    </>
  );
};

const StyledBox = styled(Box)((props) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  background: props.theme.palette.mode === "dark" ? "transparent" : "#FFFFFF",
}));
