import SvgIcon from "@mui/material/SvgIcon";

export const Consensus = (props) => (
  <SvgIcon {...props}>
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.34736 5.93489C9.98466 5.57761 10.6928 5.39897 11.4717 5.39897C12.2506 5.39897 12.9705 5.60937 13.6314 6.02223C14.2923 6.43906 14.847 7.09806 15.2954 8.00318L20.5 5.32354C19.8391 3.63239 18.7179 2.32235 17.1365 1.39341C15.5551 0.464474 13.6786 0 11.5071 0C9.33556 0 7.49056 0.424773 5.82651 1.26638C4.16247 2.11195 2.86035 3.28305 1.91621 4.78364C0.972069 6.28424 0.5 8.01112 0.5 9.96427C0.5 11.9174 0.972069 13.6483 1.91621 15.1608C2.86035 16.6733 4.16247 17.8563 5.82651 18.7138C7.49056 19.5713 9.38277 20 11.5071 20C13.6314 20 15.5551 19.5355 17.1365 18.6066C18.7179 17.6776 19.8391 16.3676 20.5 14.6765L15.2954 11.9968C14.847 12.9019 14.2923 13.5609 13.6314 13.9778C12.9705 14.3946 12.2506 14.601 11.4717 14.601C10.6928 14.601 9.98466 14.4224 9.34736 14.0651C8.71007 13.7078 8.21046 13.1838 7.84461 12.4931C7.47876 11.8023 7.2978 10.9567 7.2978 9.95633C7.2978 8.95593 7.47876 8.15403 7.84461 7.47519C8.21046 6.79635 8.71007 6.28027 9.34736 5.92298V5.93489Z"
        fill="#262626"
      />
    </svg>
  </SvgIcon>
);
