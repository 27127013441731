import React from "react";
import { styled } from "@mui/system";

export const StyledCaptcha = React.forwardRef((_, ref) => <StyledDiv ref={ref}></StyledDiv>);

const StyledDiv = styled("div")`
  img {
    width: 80%;
    margin-left: 10%;
  }
  button {
    font-size: 32px;
    cursor: pointer;
    line-height: 30px;
    padding: 0;
    border: none;
    background: transparent;
  }
  input {
    width: 100%;
    border: none;
    text-align: center;
    border-bottom: rgba(0, 0, 0, 0.5) 1px solid;
    outline: none;
    line-height: 26px;
  }
  .error {
    font-size: 0;
    &:before {
      font-size: 13px;
      font-weight: 700;
      content: "Could not load form data correctly";
    }
    &:after {
      font-size: 13px;
      content: " — try clearing your cache, refreshing the page, and trying again. Reach out to the DESK Team at desk@coindesk.com if the issue persists. ";
    }
  }
`;
