import {
  Box,
  Button,
  Link,
  Typography,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Dialog,
  CardHeader,
  CardActionArea,
} from "@mui/material";
import { useState } from "react";
import { styled } from "@mui/system";

import CloseIcon from "@mui/icons-material/Close";

import { Desk } from "icons";

import { useAnalytics } from "providers";

export function RewardCard({
  image = "",
  title = "",
  link = "",
  linkText = "",
  expiry = "",
  reward = 0,
  description = "",
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const analytics = useAnalytics();

  const expiryFormatted = expiry
    ? new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      }).format(new Date(expiry))
    : "";

  function handleOpenModal() {
    setIsModalOpen(true);
    if (analytics) {
      analytics.track("quest_see_details", { quest_name: title, quest_date: expiry });
    }
  }
  function handleCloseModal() {
    setIsModalOpen(false);
  }

  return (
    <>
      <Dialog
        fullWidth
        open={isModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ maxWidth: { xs: "100%", sm: "800px" }, marginLeft: "auto", marginRight: "auto" }}
        PaperProps={{
          sx: {
            maxWidth: "100%",
          },
        }}
      >
        <Box p={{ xs: 4, sm: 7 }}>
          <Box
            sx={{ position: "absolute", top: "0", right: "0", padding: "24px", cursor: "pointer" }}
          >
            <CloseIcon color="contrastInvert" onClick={handleCloseModal}></CloseIcon>
          </Box>
          <Typography sx={{ pb: "34px" }} id="modal-modal-title" variant="h1" component="h1">
            {title}
          </Typography>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",

                width: "100%",
              }}
            >
              <Box sx={{ textAlign: "center" }} pr={1}>
                <Typography sx={{ pr: 1 }} variant="h6">
                  Join the Most Important
                  <br />
                  Conversation in Crypto and Web3
                </Typography>
                <Typography variant="body2">April 26-28 &bull; Austin, Texas</Typography>
              </Box>
              <Box pt={1}>
                <Button
                  component={Link}
                  href="https://consensus.coindesk.com/"
                  variant="outlined"
                  color="contrastInvert"
                  target="_blank"
                >
                  Buy A Ticket
                </Button>
              </Box>
            </Box>
          </Box>

          <Typography id="modal-modal-description" sx={{ pt: "21px", pb: "27px" }}>
            {description}
          </Typography>
          <CardMedia component="img" height="307" image={image} alt="Reward Cover Picture" />
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "0",
            }}
          >
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                lineHeight: "30px",
                fontWeight: "400",
                justifyContent: "space-between",
                width: "100%",
                pt: "32px",
                pb: "16px",
              }}
              component="div"
            >
              {expiry && `Claim before ${expiryFormatted}`}
              <Typography
                component="div"
                sx={{ display: "flex", alignItems: "center", fontWeight: "700" }}
              >
                <IconWrapper sx={{ mr: "8px" }}>
                  <Desk sx={{ fontSize: 18, mt: 0.1 }} />
                </IconWrapper>
                {reward}
              </Typography>
            </Typography>
          </Box>
          <Button
            component={Link}
            href={link}
            target="_blank"
            variant="contained"
            sx={{ width: "100%" }}
            onClick={() => {
              if (analytics) {
                analytics.track("quest_start", {
                  quest_name: title,
                  ticket_cost: "",
                  quest_date: expiry,
                });
              }
            }}
          >
            {linkText}
          </Button>
        </Box>
      </Dialog>

      <Card
        sx={{
          maxWidth: "100%",
          boxShadow: "0 1px 2px 1px rgba(0, 0, 0, 0.3)",
          borderRadius: 0,
          cursor: "pointer",
          "&:hover": {
            boxShadow: "0 2px 3px 2px rgba(0, 0, 0, 0.2)",
          },
        }}
      >
        <CardActionArea onClick={handleOpenModal}>
          <CardHeader
            sx={{
              height: 80,
              ".MuiCardHeader-content": {
                flex: "1 1 auto",
                width: "100%",
              },
            }}
            titleTypographyProps={{
              noWrap: true,
              variant: "h2",
            }}
            title={title}
            subheader={expiry && `Claim before ${expiryFormatted}`}
          >
            <Typography component="span">{title}</Typography>
          </CardHeader>
          <Box
            sx={{
              aspectRatio: "1450/863",
              position: "relative",
              overflow: "hidden",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CardMedia
              component="img"
              image={image}
              alt={`Cover for "${title}" reward.`}
              sx={{
                objectFit: "contain",
                width: "auto",
                maxWidth: "100%",
                height: "100%",
                transform: "translate(-50%,-50%)",
                position: "absolute",
                top: "50%",
                left: "50%",
                zIndex: 2,
              }}
            />
            <CardMedia
              component="img"
              image={image}
              alt={`Blurred background for "${title}" reward image.`}
              sx={{
                minHeight: "100%",
                minWidth: "100%",
                filter: "blur(30px)",
                transform: "scale(1.1) translate(-45%,-45%)",
                position: "absolute",
                top: "50%",
                left: "50%",
              }}
            />
          </Box>
          <CardContent>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{
                WebkitLineClamp: 2,
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                height: 35,
              }}
            >
              {description}
            </Typography>
          </CardContent>
          <CardActions>
            <Button component="div" color="dark" fullWidth sx={{ fontSize: "120%" }}>
              Learn more and get{" "}
              <IconWrapper sx={{ ml: 0.5 }}>
                <Desk sx={{ fontSize: 18, mt: 0.1 }} />
              </IconWrapper>
              {reward} DESK™
            </Button>
          </CardActions>
        </CardActionArea>
      </Card>
    </>
  );
}

const IconWrapper = styled("span")((props) => ({
  display: "inline-block",
  color: "dark",
  height: "20px",
  width: "20px",
  borderRadius: "50%",
  ml: "20px",
  backgroundColor: props.theme.palette.primary.main,
}));
