import React, { useEffect, useState, useContext } from "react";
import Analytics from "analytics";
import googleTagManager from "@analytics/google-tag-manager";
import { gtmId } from "../constants";
import { useLocation } from "react-router-dom";

const isLocalhostTesting = window.location.origin === "http://localhost:3000";

const AnalyticsContext = React.createContext(null);

export const useAnalytics = () => useContext(AnalyticsContext);

const genericPageViewPayload = {
  author: "",
  content_category: "",
  content_iso: "",
  content_language: "",
  content_seo_keywords: "",
  content_tags: "",
  content_type: "",
  create_date: "",
  display_date: "",
  language: "",
  last_modified_date: "",
  page_location: "",
  page_referer: "",
  page_title: "",
  publish_date: "",
};

const AnalyticsProvider = ({ children }) => {
  const [analytics, setAnalytics] = useState(null);
  const { pathname } = useLocation();

  useEffect(() => {
    if (gtmId) {
      const analytics = Analytics({
        app: "desk-app",
        debug: isLocalhostTesting,
        plugins: [
          googleTagManager({
            containerId: gtmId,
          }),
        ],
      });

      analytics.on("initializeEnd", (_) => {
        analytics.track("page_view", {
          ...genericPageViewPayload,
          page_location: window.location.pathname + window.location.search,
        });
      });
      setAnalytics(analytics);
    }
  }, []);

  useEffect(() => {
    if (gtmId && analytics) {
      analytics.track("page_view", {
        ...genericPageViewPayload,
        page_location: window.location.pathname + window.location.search,
      });
    }
  }, [pathname, analytics]);

  return <AnalyticsContext.Provider value={analytics}>{children}</AnalyticsContext.Provider>;
};

export default AnalyticsProvider;
