import React, { useState } from "react";

const UserProvider = React.lazy(() =>
  import(/* webpackChunkName: "providers.user" */ "./UserProvider")
);
const AuthProvider = React.lazy(() =>
  import(/* webpackChunkName: "providers.auth" */ "./AuthProvider")
);
const Web3Providers = React.lazy(() =>
  import(/* webpackChunkName: "providers.web3" */ "./Web3Providers")
);
const QueryClientProvider = React.lazy(() =>
  import(/* webpackChunkName: "providers.query" */ "./QueryClientProvider")
);
const ThemeProvider = React.lazy(() =>
  import(/* webpackChunkName: "providers.theme" */ "./ThemeProvider")
);
const OnboardingProvider = React.lazy(() =>
  import(/* webpackChunkName: "providers.onboarding" */ "./OnboardingProvider")
);
const LandingProvider = React.lazy(() =>
  import(/* webpackChunkName: "providers.landing" */ "./LandingProvider")
);
const AnalyticsProvider = React.lazy(() =>
  import(/* webpackChunkName: "providers.analytics" */ "./AnalyticsProvider")
);

const Providers = ({ children }) => {
  const [redirectCallbackRan, setRedirectCallbackRan] = useState(false);
  return (
    <AnalyticsProvider>
      <AuthProvider setRedirectCallbackRan={setRedirectCallbackRan}>
        <UserProvider>
          <ThemeProvider>
            <LandingProvider redirectCallbackRan={redirectCallbackRan}>
              <Web3Providers>
                <QueryClientProvider>
                  <OnboardingProvider>{children}</OnboardingProvider>
                </QueryClientProvider>
              </Web3Providers>
            </LandingProvider>
          </ThemeProvider>
        </UserProvider>
      </AuthProvider>
    </AnalyticsProvider>
  );
};

export default Providers;
