import { Button, Typography, Box } from "@mui/material";
import { DialogWrapper } from "components";
import { Wallets } from "icons";
import { useConnect } from "wagmi";

const WalletSelectionPre = ({ open, onClose, onExistingWalletSelection, onBitskiSelection }) => {
  const { connect, isLoading, connectors } = useConnect({
    onSuccess() {
      onClose();
    },
  });
  const onClickHandler = () => {
    const connector = connectors?.find((connector) => connector.id === "bitski");
    console.log(connector);
    connect({ connector });
  };

  return (
    <DialogWrapper
      open={open}
      title="Connect Wallet"
      image="/images/deskwallet.png"
      description="Create or connect a wallet to start using DESK™ in places like the Discord community or the NFT marketplace."
      onClose={onClose}
      hasGradient
    >
      <Button
        variant="outlined"
        color="contrastInvert"
        onClick={onExistingWalletSelection}
        disabled={isLoading}
      >
        Connect existing wallet
      </Button>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Button
          variant="outlined"
          color="contrastInvert"
          onClick={onClickHandler}
          disabled={isLoading}
        >
          <Wallets.Bitski />
          &nbsp;Create a new wallet
        </Button>
        <Typography variant="body2" sx={{ textAlign: "center", fontStyle: "italic" }}>
          Easy option for Web3 beginners
        </Typography>
      </Box>
    </DialogWrapper>
  );
};

export { WalletSelectionPre };
