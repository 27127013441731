import { Box, Typography, Button, Grid, IconButton, CircularProgress } from "@mui/material";

import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import { Link } from "react-router-dom";
import { useState, Fragment, useMemo, useEffect, useCallback } from "react";
import { useSearchParams } from "react-router-dom";

import { GradientBorder, JourneyGroup } from "components";
import { JourneyLevelButtons } from "./JourneyLevelButtons";
import { JourneyLevelRewards } from "./JourneyLevelRewards";

import { isDesktop } from "utils";

const JourneyUI = ({
  claimRewardFn,
  isLoading,
  setRewards,
  updateRewardsClaimStatus,
  rewards,
  rootRewardId,
}) => {
  const [, setSearchParams] = useSearchParams();
  useEffect(() => {
    setSearchParams({ journey: "consensus" });
  }, [setSearchParams]);
  const [activeLevelIndex, setActiveLevelIndex] = useState(0);
  const handleNextLevel = () => {
    // Do not progress automatically if there are unclaimed BONUSES
    claimRewardFn(activeLevelId); //.then(() => setActiveLevelIndex(activeLevelIndex + 1));
  };

  const getRewardBodyById = useCallback(
    (id) => rewards.find((reward) => reward && reward.id === id),
    [rewards]
  );

  const getRewardChildrenIdsByParentId = useCallback(
    (id) => getRewardBodyById(id)?.depends || [],
    [getRewardBodyById]
  );

  const getChildrenBodiesByParentId = useCallback(
    (id) => getRewardChildrenIdsByParentId(id).map((childId) => getRewardBodyById(childId)),
    [getRewardChildrenIdsByParentId, getRewardBodyById]
  );

  const levelIds = useMemo(() => {
    return getRewardChildrenIdsByParentId(rootRewardId);
  }, [getRewardChildrenIdsByParentId, rootRewardId]);

  const levelIdsWithoutBonus = useMemo(() => {
    return levelIds?.filter((_, idx) => idx <= 2) || null;
  }, [levelIds]);

  const activeLevelId = useMemo(() => levelIds[activeLevelIndex], [activeLevelIndex, levelIds]);
  const activeLevelBody = useMemo(
    () => getRewardBodyById(activeLevelId),
    [activeLevelId, getRewardBodyById]
  );

  const activeLevelBonuses = useMemo(
    () =>
      rewards.filter(
        (reward) => reward?.type === "JOURNEY_BONUS" && reward?.depends.includes(activeLevelId)
      ),
    [rewards, activeLevelId]
  );

  useEffect(() => {
    if (!isLoading) {
      updateRewardsClaimStatus(activeLevelBonuses);
    }
  }, [isLoading, updateRewardsClaimStatus, activeLevelBonuses]);

  const activeLevelGroupIds = useMemo(() => {
    const activeLevelGroupIds = getChildrenBodiesByParentId(activeLevelId)
      .filter((child) => child?.type !== "JOURNEY_BONUS")
      .map((child) => child.id);

    return activeLevelGroupIds;
  }, [activeLevelId, getChildrenBodiesByParentId]);

  const activeLevelGroupBodies = useMemo(() => {
    return activeLevelGroupIds.map(getRewardBodyById);
  }, [activeLevelGroupIds, getRewardBodyById]);

  useEffect(() => {
    if (!isLoading) {
      updateRewardsClaimStatus(activeLevelGroupBodies);
    }
  }, [isLoading, updateRewardsClaimStatus, activeLevelGroupBodies]);

  const currentLevelGroupsCompleted = useMemo(() => {
    return activeLevelGroupBodies.every(
      (group) => group?.isClaimed || group?.type === "JOURNEY_BONUS"
    );
  }, [activeLevelGroupBodies]);

  const levelBodies = useMemo(() => {
    return levelIds.map(getRewardBodyById);
  }, [levelIds, getRewardBodyById]);

  useEffect(() => {
    if (!isLoading) {
      updateRewardsClaimStatus(levelBodies);
    }
  }, [isLoading, updateRewardsClaimStatus, levelBodies]);

  const levelsWithoutBonusCompleted = useMemo(() => {
    return levelIdsWithoutBonus.every((levelId) => getRewardBodyById(levelId).isClaimed);
  }, [levelIdsWithoutBonus, getRewardBodyById]);

  const isActiveLevelBonus = useMemo(
    () => activeLevelIndex === levelIds.length - 1,
    [activeLevelIndex, levelIds]
  );

  const showFinalBonusUI = useMemo(
    () => levelsWithoutBonusCompleted && isActiveLevelBonus,
    [isActiveLevelBonus, levelsWithoutBonusCompleted]
  );

  return (
    <>
      <Box sx={{ position: "relative" }}>
        {isLoading && (
          <>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%,-50%) rotate(180deg)",
                zIndex: 2,
              }}
            >
              <CircularProgress size={300} thickness={0.5} color="primary" disableShrink />
            </Box>
          </>
        )}

        <Box
          sx={{
            width: 500,
            maxWidth: "100%",
            minHeight: 700,
            margin: "auto",
            borderRadius: 1,
            p: {
              xs: 2,
              sm: 3,
            },
            my: 2,
            textAlign: "center",
            filter: isLoading ? "saturate(0.5) brightness(0.7)" : "none",
            pointerEvents: isLoading ? "none" : "auto",
            backgroundImage:
              "linear-gradient(165.42deg, rgba(77, 79, 80, 1) 0%, rgba(0, 0, 0, 1) 103.19%)",
          }}
        >
          <Typography variant="montserrat_bold" sx={{ fontSize: 24 }}>
            {showFinalBonusUI ? "Journey: Complete" : "Journey: Consensus 2023"}
          </Typography>
          <Typography variant="subtitle2" p={3}>
            {showFinalBonusUI
              ? "To thank you for being one of our most engaged attendees, we want to give you one more chance to attend a free show and grab drink on us!"
              : "Complete each level to unlock DESK™ rewards, access to exclusive events, and more"}
          </Typography>
          <JourneyLevelButtons
            activeLevelIndex={activeLevelIndex}
            isLoading={isLoading}
            setActiveLevelIndex={setActiveLevelIndex}
            levelIdsWithoutBonus={levelIdsWithoutBonus}
            levelsWithoutBonusCompleted={levelsWithoutBonusCompleted}
            levelBodies={levelBodies}
          // allowedLevelIndex={allowedLevelIndex}
          />

          <Typography variant="montserrat_bold" sx={{ fontSize: 18 }}>
            {showFinalBonusUI ? "Final Quest" : "Quests to complete"}
          </Typography>

          <Grid container spacing={2} pt={2} sx={{ justifyContent: "center" }}>
            {activeLevelGroupIds?.map((groupId) => (
              <Fragment key={groupId}>
                <Grid item xs={{ 1: 12, 2: 6, 3: 4, 4: 6 }[activeLevelGroupBodies.length] || 4}>
                  <Box>
                    <JourneyGroup
                      groupId={groupId}
                      claimRewardFn={claimRewardFn}
                      getRewardBodyById={getRewardBodyById}
                    />
                  </Box>
                </Grid>
              </Fragment>
            ))}
            {currentLevelGroupsCompleted && !showFinalBonusUI && (
              <Grid item xs={12}>
                <Button
                  variant={activeLevelBody?.isClaimed ? "outlined" : "contained"}
                  color={activeLevelBody?.isClaimingError ? "error" : "primary"}
                  onClick={handleNextLevel}
                  disabled={activeLevelBody?.isClaimed || activeLevelBody?.isClaimingError}
                  sx={{ width: "100%" }}
                >
                  {activeLevelBody?.isClaimingError ? (
                    "Could not claim level, refresh and try again"
                  ) : activeLevelBody?.isClaiming ? (
                    <CircularProgress size={24} />
                  ) : (
                    <>{!activeLevelBody?.isClaimed ? "Finish this level!" : "Level completed!"}</>
                  )}
                </Button>
              </Grid>
            )}

            {!isDesktop && (
              <Grid item xs={12}>
                <Box
                  component={Link}
                  sx={{ display: "block", cursor: "pointer", textDecoration: "none" }}
                  to="/qr/scan-code"
                >
                  <GradientBorder reverseBorder withHoverBackground>
                    <Box p={2}>
                      <Box>
                        <IconButton sx={{ border: "2px solid", borderColor: "primary.main" }}>
                          <QrCodeScannerIcon color="primary" />
                        </IconButton>
                      </Box>
                      <Typography color="primary" variant="montserrat_bold" sx={{ fontSize: 18 }}>
                        Scan to claim
                      </Typography>
                    </Box>
                  </GradientBorder>
                </Box>
              </Grid>
            )}
            <Grid item xs={12}>
              <JourneyLevelRewards
                isLoading={isLoading}
                activeLevelBody={activeLevelBody}
                activeLevelIndex={activeLevelIndex}
                activeLevelBonuses={activeLevelBonuses}
                claimRewardFn={claimRewardFn}
                setRewards={setRewards}
                setActiveLevelIndex={setActiveLevelIndex}
                showFinalBonusUI={showFinalBonusUI}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export { JourneyUI };
