import { Box, Typography, Stack } from "@mui/material";

const JourneyBonusReward = ({
  bonus,
  index,
  activeLevelBody,
  firstClaimableBonusId,
  setBonusToShowInModal,
  claimedBonusId,
}) => {
  const isDepleted = bonus?.isDepleted;
  const isClaimed = bonus?.isClaimed;

  const levelClaimed = activeLevelBody?.isClaimed;

  const hasRibbon = levelClaimed && (isDepleted || isClaimed);
  const hasRedRibbon = hasRibbon && !isClaimed && isDepleted;
  const hasGreenRibbon = hasRibbon && !isDepleted && isClaimed;

  const isFirstNonDepletedBonus = firstClaimableBonusId === bonus.id;
  const hasClaimedBonus = !!claimedBonusId;
  const isClaimedBonus = hasClaimedBonus && claimedBonusId === bonus.id;

  const isClickable =
    levelClaimed && ((isFirstNonDepletedBonus && !hasClaimedBonus) || isClaimed || isClaimedBonus);

  return (
    <Box
      sx={{
        overflow: "hidden",
        cursor: isClickable ? "pointer" : "default",
      }}
      onClick={() => isClickable && setBonusToShowInModal({ ...bonus, index })}
    >
      <Box
        p={1}
        sx={{
          width: "100%",
          backgroundColor: "dark.main",
          border: "1px solid",
          borderColor: hasRedRibbon
            ? "error.main"
            : hasGreenRibbon
            ? "success.main"
            : isClickable && isFirstNonDepletedBonus && !hasClaimedBonus
            ? "primary.main"
            : "#999",
          opacity: !isClickable ? 0.7 : undefined,
          borderRadius: 1,
          position: "relative",
        }}
      >
        {hasRibbon && (
          <Box
            sx={{
              position: "absolute",
              top: "40%",
              left: "50%",
              transform: "translate(-50%,-50%) rotate(-20deg)",
              zIndex: 2,
              width: "150%",
              backgroundColor: "#000000",
              border: "1px solid",
              borderColor: hasGreenRibbon
                ? "success.main"
                : hasRedRibbon
                ? "error.main"
                : undefined,
            }}
          >
            <Typography variant="montserrat_bold" sx={{ fontSize: 24 }}>
              {hasGreenRibbon ? "You won" : hasRedRibbon ? "All claimed" : undefined}
            </Typography>
          </Box>
        )}

        <Stack mb={-1}>
          <Box
            sx={{
              overflow: "hidden",
              position: "relative",
              width: "100%",
              aspectRatio: "16/9",
              borderRadius: "2px",
              filter: !isClickable ? "saturate(0)" : undefined,
            }}
          >
            <Box
              component="img"
              src={bonus.image}
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%,-50%)",
                filter: isDepleted ? "saturate(0) brightness(0.5)" : undefined,
              }}
            />
          </Box>
          <Box
            sx={{
              height: 60,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Typography
              sx={{
                px: 1,
                fontSize: {
                  xs: 14,
                  sm: "unset",
                },
              }}
            >
              {bonus.linkText}
            </Typography>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};

export { JourneyBonusReward };
