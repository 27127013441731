import SvgIcon from "@mui/material/SvgIcon";

export const Facebook = (props) => (
  <SvgIcon {...props}>
    <svg
      width="24"
      height="24"
      fill="none"
      viewBox="-6 -3 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m9.345 10.607.518-3.413H6.621V4.98c0-.934.453-1.844 1.905-1.844H10V.23S8.662 0 7.383 0c-2.67 0-4.415 1.634-4.415 4.593v2.601H0v3.413h2.968v8.25a11.665 11.665 0 0 0 3.653 0v-8.25h2.724Z"
        fill="#fff"
      />
    </svg>
  </SvgIcon>
);
