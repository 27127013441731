import { useEffect, useState } from "react";

import { useConnect, useAccount } from "wagmi";
import { styled } from "@mui/system";
import { Link } from "react-router-dom";

import {
  Button,
  Box,
  Stack,
  Alert,
  Typography,
  Dialog,
  IconButton,
  AlertTitle,
} from "@mui/material";

import { InlineButtonLink } from "components";
import { EXTERNAL_HREFS, hasConsensusHub } from "constants";
import { calcWalletName, calcWalletIcon, calcGradient } from "utils";

import CloseIcon from "@mui/icons-material/Close";

export const ConnectWallet = ({
  isConnectModalOpened,
  setIsConnectModalOpened,
  setHideModalTemporarily,
}) => {
  const [currentConnector, setCurrentConnector] = useState(null);
  const [connectionFeedback, setConnectionFeedback] = useState(null);
  const [metamaskTriggered, setMetamaskTriggered] = useState(false);

  const { connector, connect, connectors, error, isLoading: loading } = useConnect();

  const { isConnected: connected } = useAccount();

  useEffect(() => {
    if (setIsConnectModalOpened) {
      setConnectionFeedback(null);
    }
  }, [setIsConnectModalOpened]);

  useEffect(() => {
    if (connected) {
      setIsConnectModalOpened(false);
    }
  }, [connected, setIsConnectModalOpened]);

  useEffect(() => {
    if (connector?.name === "MetaMask" && loading === true) {
      setMetamaskTriggered(true);
    }
  }, [connector, loading]);

  useEffect(() => {
    const isSpecialMetaMaskError = metamaskTriggered && !error;
    const warningActiveRequest =
      error?.code === -32002 ||
      error?.name === "ConnectorAlreadyConnectedError" ||
      isSpecialMetaMaskError;
    const infoDeniedRequest = error?.name === "UserRejectedRequestError" || error?.code === 4001;
    const connectionInProgress = connector || loading;

    if (warningActiveRequest) {
      setConnectionFeedback({
        type: "warning",
        title: "There is an active connection request",
        message:
          "If the problem persists, please try to open Wallet manually, unlock it and try again or restart your browser",
      });
    } else if (infoDeniedRequest) {
      setConnectionFeedback({
        type: "info",
        title: "Connection request rejected, please try again",
      });
    } else if (error) {
      setConnectionFeedback({
        type: "error",
        title: "Connection failed",
        message:
          "If the problem persists, please try to open Wallet manually, unlock it and try again or restart your browser",
      });
    } else if (connectionInProgress) {
      setConnectionFeedback({
        type: "success",
        title: "Please continue connection in your Wallet",
      });
    }
  }, [error, loading, connector, metamaskTriggered]);

  useEffect(() => {
    setConnectionFeedback(null);
  }, [isConnectModalOpened]);

  const initiateConnection = (_connector) => {
    setCurrentConnector(_connector);
    setIsConnectModalOpened(false);
  };

  const finalizeConnection = (currentConnector) => {
    connect({ connector: currentConnector });
    setCurrentConnector(null);
    setIsConnectModalOpened(true);
    setHideModalTemporarily(true);
  };

  useEffect(() => {
    if (connected || error) {
      setHideModalTemporarily(false);
    }
  }, [connected, error, setHideModalTemporarily]);

  return (
    <>
      <Dialog open={!!currentConnector} onClose={() => setCurrentConnector(null)}>
        <StyledBox
          sx={{ width: 550, maxWidth: "100%" }}
          px={{ xs: 3, sm: 6, md: 9 }}
          py={2}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <IconButton
            color="contrastInvert"
            sx={{ position: "absolute", top: 24, right: 24 }}
            onClick={() => setCurrentConnector(null)}
          >
            <CloseIcon />
          </IconButton>
          <Box display="flex" justifyContent="center" pt={5} position="relative">
            <img src={`/wallet.png`} alt="Wallet and mobile device" width="140" height="140" />
          </Box>
          <Typography my={2}>
            Some people might have difficulty using their wallet - but not for long.
          </Typography>
          <Typography my={2}>
            We will gather feedback and improve functionality until DESK™ is accessible to people of
            all levels.
          </Typography>
          <Stack spacing={2} py={2} width={250}>
            <Button
              variant="outlined"
              color="contrastInvert"
              onClick={() => finalizeConnection(currentConnector)}
              sx={{ width: "100%" }}
            >
              Continue
            </Button>
            <Button
              variant="outlined"
              sx={{ width: "100%" }}
              color="contrastInvert"
              component={Link}
              to="/edu/howtos/walletConnect"
            >
              View connection tips
            </Button>
          </Stack>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            py={2}
          >
            <Typography>Want to help improve this feature?</Typography>
            <InlineButtonLink
              component="a"
              href={EXTERNAL_HREFS["supportFeedback"]}
              target="_blank"
            >
              Submit feedback
            </InlineButtonLink>
          </Box>
        </StyledBox>
      </Dialog>
      <Stack spacing={2} pb={6} width={240}>
        {connectors
          // .filter((connector) => (isDesktop ? true : connector.id === "walletConnect"))
          .map((_connector) => (
            <Button
              fullWidth
              key={_connector.name}
              onClick={() => initiateConnection(_connector)}
              variant="outlined"
              color="contrastInvert"
              sx={{ display: "block" }}
            >
              <Box
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="center"
                textAlign="left"
              >
                {calcWalletIcon(calcWalletName(_connector))}
                <Box pl={2}>
                  {/*isDesktop*/ true ? calcWalletName(_connector) : "Connect Wallet"}
                </Box>
              </Box>
            </Button>
          ))}
      </Stack>
      {connectionFeedback && (
        <>
          <Alert
            severity={connectionFeedback?.type}
            sx={{ paddingBottom: connectionFeedback?.message ? "16px" : 0 }}
          >
            <AlertTitle>{connectionFeedback?.title}</AlertTitle>
            {connectionFeedback?.message}
          </Alert>
        </>
      )}
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <Typography>Having trouble?</Typography>
        <InlineButtonLink component={Link} to="/edu/howtos/walletConnect">
          Get step-by-step instructions
        </InlineButtonLink>
      </Box>
    </>
  );
};

const StyledBox = styled(Box)`
  background-image: ${calcGradient(hasConsensusHub ? "dark" : "light")};
  background-repeat: no-repeat;
  background-size: cover;
`;
