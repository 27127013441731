import { Box, Grid, Typography, Stack, Button } from "@mui/material";
import { Desk } from "icons";

import { useEffect, useState } from "react";

import { DeskWord } from "./DeskWord";

const target = 1682514000000;

const pad = (n) => (n < 10 ? `0${n}` : n);

const JourneyClock = ({ goToJourneyFn }) => {
  const [timeLeft, setTimeLeft] = useState(["...", "...", "...", "..."]);
  const [showBegin, setShowBegin] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      const distance = target - new Date().getTime();
      if (distance < 0) {
        clearInterval(interval);
        setTimeLeft([0, 0, 0, 0]);
        setShowBegin(true);
      } else {
        setTimeLeft([
          Math.floor(distance / (1000 * 60 * 60 * 24)),
          Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
          Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
          Math.floor((distance % (1000 * 60)) / 1000),
        ]);
      }

      return () => clearInterval(interval);
    }, 1000);
  }, []);

  return (
    <Box
      sx={{
        my: 3,
        py: 2,
        height: 400,
        width: 550,
        maxWidth: "100%",
        backgroundImage: "linear-gradient(to right, #020A0A, #515254)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
      }}
    >
      <Desk
        sx={{
          fontSize: 250,
          position: "absolute",
          bottom: -55,
          right: -30,
          color: "rgba(0,0,0,0.3)",
          zIndex: 1,
        }}
      />
      <Stack
        sx={{
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          maxWidth: "100%",
          position: "relative",
          zIndex: 2,
        }}
      >
        <Box sx={{ fontSize: 30, display: "flex", justifyContent: "center" }}>
          <DeskWord type="promo" TypographyProps={{ sx: { justifyContent: "center", pl: 3 } }} />
          <Box sx={{ mt: -1.5 }}>™</Box>
        </Box>
        <Typography variant="montserrat_bold" pt={3} sx={{ fontSize: 30 }}>
          Journey: Consensus 2023
        </Typography>
        <Typography variant="montserrat" pb={3} sx={{ fontSize: 20 }}>
          A Gamified, Web3 Conference Experience
        </Typography>
        <Grid container sx={{ width: 500, maxWidth: "100%" }}>
          {!showBegin ? (
            <>
              <Grid item xs={12}>
                <Typography variant="montserrat_bold" pb={1} sx={{ fontSize: 24 }}>
                  STARTS IN
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="montserrat_bold" color="primary" sx={{ fontSize: 42 }}>
                  {pad(timeLeft[0])}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="montserrat_bold" color="primary" sx={{ fontSize: 42 }}>
                  {pad(timeLeft[1])}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="montserrat_bold" color="primary" sx={{ fontSize: 42 }}>
                  {pad(timeLeft[2])}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="montserrat_bold" color="primary" sx={{ fontSize: 42 }}>
                  {pad(timeLeft[3])}
                </Typography>
              </Grid>
              <Grid item xs={3} mt={-1}>
                <Typography variant="montserrat">DAYS</Typography>
              </Grid>
              <Grid item xs={3} mt={-1}>
                <Typography variant="montserrat">HOURS</Typography>
              </Grid>
              <Grid item xs={3} mt={-1}>
                <Typography variant="montserrat">MINUTES</Typography>
              </Grid>
              <Grid item xs={3} mt={-1}>
                <Typography variant="montserrat">SECONDS</Typography>
              </Grid>
            </>
          ) : (
            <Grid item xs={12}>
              <Button
                onClick={goToJourneyFn}
                color="primary"
                variant="contained"
                sx={{ textTransform: "uppercase", borderRadius: 0, px: 6 }}
              >
                <Typography variant="montserrat_bold" color="dark" sx={{ fontSize: 36 }}>
                  Begin journey
                </Typography>
              </Button>
            </Grid>
          )}
        </Grid>
      </Stack>
    </Box>
  );
};

export { JourneyClock };
