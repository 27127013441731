import { createContext, useState, useEffect, useCallback, useMemo, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Alert, Snackbar } from "@mui/material";
import { hasOnboardingEnabled, hasDebugLogsEnabled, authLogoutLocation, apiUrl } from "constants";
import { useUser, useDeskAuth, useAnalytics } from "providers";
import { DialogEmail, DialogTerms, LoadingOverlay, AccountWizard } from "components";
import { setupRedirectSEO } from "utils";

import MagicLinkError from "../components/MagicLinkError";

console.debug = hasDebugLogsEnabled ? console.log : () => {};

const setTokenCookie = (token) => {
  const EXPIRY_HOURS = 2400;
  const parts = window.location.hostname.split(".");
  const domain = parts.length < 2 ? null : `${parts[parts.length - 2]}.${parts[parts.length - 1]}`;
  const seconds = EXPIRY_HOURS * 60 * 60;
  let value = `deskToken=${token || ""};Max-Age=${seconds};SameSite=Lax;Path=/;`;
  if (domain) {
    value += `domain=${domain};`;
  }
  document.cookie = value;
  // console.debug(`>>>>> setTokenCookie(${!!token}) for ${domain}`);
};

const LandingContext = createContext({ requestOnboardingStart: false });
export const useLanding = () => useContext(LandingContext);

const formatReturnUrl = (returnToParam) => {
  return returnToParam.startsWith("http") ? returnToParam : window.location.origin + returnToParam;
};

const getParam = (name) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(name) || "";
};

const subToStorageKey = (sub) => {
  const tokens = sub.split("|");
  const id = tokens[tokens.length - 1];
  return `supportReturnTo-${id}`;
};
const setSupportReturnTo = (sub, url) => {
  localStorage.setItem(subToStorageKey(sub), url);
  console.debug("Saved supportReturnTo: ", url, " for ", sub);
};
const getSupportReturnTo = (sub) => {
  const url = localStorage.getItem(subToStorageKey(sub));
  if (url) {
    console.debug("Found supportReturnTo: ", url, " for ", sub);
  }
  return url;
};
const removeSupportReturnTo = (sub) => {
  const key = subToStorageKey(sub);
  if (hasDebugLogsEnabled) {
    const url = localStorage.getItem(key);
    if (url) {
      console.debug("Deleted supportReturnTo: ", url, " for ", sub);
    }
  }
  localStorage.removeItem(key);
};

// as well as returnTo & emailVerification handlers
//
//
//
//
//
//

const LandingProvider = ({ children, redirectCallbackRan }) => {
  const {
    isEmailVerified,
    isAuth0Loading,
    isClaimsLoading,
    isAuthenticated,
    token,
    account: { provider, sub },
    isLoading,
    user,
  } = useUser();
  const { pathname } = window.location;
  const { deskLogin, deskLogout } = useDeskAuth();
  const analytics = useAnalytics();

  const [snack, setSnack] = useState(null);
  const [showEmailDialog, setShowEmailDialog] = useState(false);
  const [showTermsDialog, setShowTermsDialog] = useState(false);
  const [renderApp, setRenderApp] = useState(false);
  const [showOverlay, setShowOverlay] = useState(true);
  const [showAccountWizard, setShowAccountWizard] = useState(false);
  const [magicLinkError, setMagicLinkError] = useState(null);
  const [requestOnboardingStart, setRequestOnboardingStart] = useState(false);
  const [currentActionId, setCurrentActionId] = useState(0);

  const routerNavigate = useNavigate();

  const navigate = useCallback(
    (urlOrPath) => {
      if (urlOrPath.search(window.location.origin) > -1) {
        const navigateTo = urlOrPath.replace(window.location.origin, "");
        routerNavigate(navigateTo);
      } else {
        window.location = urlOrPath;
      }
    },
    [routerNavigate]
  );

  useEffect(() => {
    if (!isAuth0Loading) {
      if (token) {
        setTokenCookie(token);
      } else {
        setTokenCookie(null);
      }
    }
  }, [isAuth0Loading, token]);

  const magicLinkHandler = useCallback(
    ({ nextStep, currentActionId }) => {
      console.debug(currentActionId, "magicLinkHandler");

      if (pathname.startsWith("/ev/")) {
        const words = window.location.pathname.split("/");
        const magicLinkPair = words[words.length - 1];
        const url = `${apiUrl}/api/magiclink/${magicLinkPair}`;
        const options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        };
        fetch(url, options).then(async (response) => {
          const body = await response.json();
          if (!response.ok) {
            let err = new Error(
              `Error ${response.status}: ${body.reason || body?.message || response.statusText || "Unknown error"
              }`
            );
            err.status = response.status;
            throw err;
          }

          window.location.href = body?.callback;
        }).catch((err) => {
          console.error(err.message);
          setMagicLinkError(err.message);
          setShowOverlay(false);
        });
      } else
      if (!pathname.startsWith("/linkerror")) {
        // stop landing process for magiclink error handling too.
        nextStep();
      }
    },
    [pathname]
  );

  const goErrorHandler = useCallback(({ nextStep, currentActionId }) => {
    console.debug(currentActionId, "goErrorHandler");
    const errorParam = getParam("error");
    const descParam = getParam("error_description");

    if ((descParam || errorParam) && errorParam !== "true") {
      const message = descParam || errorParam || "Unknown error";
      console.log("goErrorHandler: >>>", message);
      // setSnack({ open: true, message, x: "error" }); // doesn't display if just redirected below
      alert(message); // block and show, this is important

      // window.history.replaceState(null, null, `${window.location.origin}?error=${errorParam}&error_description=${descParam}`);
      window.history.replaceState(null, null, window.location.origin);
      // we must change the href in order for the browser to restart the LandingProvider from the beginning
      // window.location.href = window.location.origin;
      // nextStep();
      // return;

      // const deskReturnTo = getParam("deskReturnTo");
      // const returnToUrl = formatReturnUrl(deskReturnTo);
      // const returnTo = `${window.location.origin}/go?deskReturnTo=${returnToUrl}`;
      // if (returnTo) {
      //   console.log("goErrorHandler: navigating to ", returnTo);
      //   navigate(returnTo);
      // } else {
        console.log("goErrorHandler: NO returnTo, going to logout location.");
        deskLogout();
        return;
      // }
    } else {
      nextStep();
    }
  }, [navigate]);

  const goLogoutHandler = useCallback(
    ({ nextStep, currentActionId }) => {
      console.debug(currentActionId, "goLogoutHandler");

      const logoutParam = getParam("logout");

      if (pathname === "/go" && logoutParam === "true") {
        setupRedirectSEO();
        const deskReturnTo = getParam("deskReturnTo") || authLogoutLocation;
        window.location.href = deskReturnTo;
      } else {
        nextStep();
      }
    },
    [pathname]
  );

  const logoutHandler = useCallback(
    ({ nextStep, currentActionId }) => {
      console.debug(currentActionId, "logoutHandler");

      if (pathname === "/logout") {
        if (analytics) analytics.track("logout");
        setupRedirectSEO();
        const deskReturnTo = getParam("deskReturnTo");
        const returnToUrl = formatReturnUrl(deskReturnTo);

        setTokenCookie(null); // make sure SSO clients know we're logged out too

        if (token) {
          if (deskReturnTo) {
            const returnTo = `${window.location.origin}/go?logout=true&deskReturnTo=${returnToUrl}`;
            deskLogout({ to: returnTo });
            return;
          } else {
            deskLogout();
            return;
          }
        } else {
          navigate(deskReturnTo ? returnToUrl : "/");
          return;
        }
      } else {
        nextStep();
      }
    },
    [deskLogout, pathname, token, navigate, analytics]
  );

  const loginHandler = useCallback(
    ({ nextStep, currentActionId }) => {
      console.debug(currentActionId, "loginHandler");

      if (pathname === "/login") {
        setupRedirectSEO();
        const deskReturnTo = getParam("deskReturnTo") || "";
        const returnToUrl = formatReturnUrl(deskReturnTo);

        if (token) {
          navigate(deskReturnTo ? returnToUrl : window.location.origin + "/", {
            replace: true,
          });
          return;
        }

        if (deskReturnTo) {
          deskLogin({ returnToUrl });
          return;
        } else {
          navigate("/");
          return;
        }
      }

      nextStep();
    },
    [deskLogin, pathname, token, navigate]
  );

  const deskLoginHandler = useCallback(
    ({ nextStep, currentActionId }) => {
      console.debug(currentActionId, "deskLoginHandler");

      if (!isAuth0Loading) {
        if (!isAuthenticated) {
          deskLogin();
          return;
        }
        nextStep();
      }
    },
    [deskLogin, isAuth0Loading, isAuthenticated]
  );

  const saveReturnToHandler = useCallback(
    ({ nextStep, currentActionId }) => {
      console.debug(currentActionId, "saveReturnToHandler");
      // we need an Auth0 context for this to work
      if (isAuthenticated && !isClaimsLoading) {
        // thats not doing anything now:
        // const signupParam = getParam("supportSignUp");
        // const forgotParam = getParam("supportForgotPassword");

        const deskReturnTo = getParam("deskReturnTo");
        if (deskReturnTo) {
          if (sub) {
            setSupportReturnTo(sub, deskReturnTo);
            nextStep();
            return;
          }
        } else {
          nextStep();
        }
      }
    },
    [isAuthenticated, isClaimsLoading, sub]
  );

  const emailVerificationHandler = useCallback(
    ({ nextStep, currentActionId }) => {
      console.debug(currentActionId, "emailVerificationHandler");

      if (isAuthenticated && !isClaimsLoading) {
        if (isEmailVerified) {
          nextStep();
        } else {
          const assumeEmailVerified =
            getParam("message").search("verified") > -1 && getParam("success") === "true";

          if (assumeEmailVerified) {
            window.history.replaceState(null, null, window.location.pathname);
            deskLogin({ trySilent: true });
            return;
          }
          if (!isEmailVerified) {
            setShowEmailDialog(true);
          }
        }
      }
    },
    [deskLogin, isAuthenticated, isClaimsLoading, isEmailVerified]
  );

  const notificationHandler = useCallback(({ nextStep, currentActionId }) => {
    console.debug(currentActionId, "notificationHandler");

    const successText = getParam("success");
    const message = getParam("message");

    if (message && successText) {
      const severity =
        successText === "true" ? "success" : successText === "false" ? "error" : "info";
      setSnack({ open: true, message, severity });
    }

    nextStep();
  }, []);

  const termsOfServiceHandler = useCallback(
    ({ nextStep, currentActionId }) => {
      console.debug(currentActionId, "termsOfServiceHandler");

      if (!isLoading && user?.id) {
        const termsAcceptedOn = new Date(user?.confirmedAt).getTime() / 1000;
        if (termsAcceptedOn === 0) {
          setShowTermsDialog(true);
        } else {
          setShowTermsDialog(false);
          nextStep();
        }
      }
    },
    [isLoading, user?.confirmedAt, user?.id, setShowTermsDialog]
  );

  const analyticsSignUpHandler = useCallback(
    ({ nextStep, currentActionId }) => {
      console.debug(currentActionId, "analyticsSignUpHandler");

      if (analytics) {
        if (user?.id) {
          if (user?.progress <= 0) {
            // progress<=0 happens once, then profilePatch({ progress: -1 }) is called in UserProvider
            analytics.track("signUp", {
              method: provider,
              userId: sub,
            });
            nextStep();
          } else {
            nextStep();
          }
        } else {
          // wait for user id
        }
      } else {
        nextStep();
      }
    },
    [analytics, provider, sub, user?.id, user?.progress]
  );

  const analyticsLoginHandler = useCallback(
    ({ nextStep, currentActionId }) => {
      console.debug(currentActionId, "analyticsLoginHandler");
      if (analytics && redirectCallbackRan) {
        if (provider && sub) {
          analytics.track("login", {
            method: provider,
          });
          nextStep();
        } else {
          // wait for provider and sub
        }
      } else {
        nextStep();
      }
    },
    [analytics, provider, redirectCallbackRan, sub]
  );

  const goHandler = useCallback(
    ({ nextStep, currentActionId }) => {
      console.debug(currentActionId, "goHandler");

      if (pathname === "/go") {
        setupRedirectSEO();
        const deskReturnTo = getParam("deskReturnTo");
        console.log("goHandler: deskReturnTo:", deskReturnTo);

        if (sub) {
          removeSupportReturnTo(sub);

          navigate(deskReturnTo || "/");
          return;
        }
      }

      nextStep();
    },
    [pathname, navigate, sub]
  );

  const returnToHandler = useCallback(
    ({ nextStep, currentActionId }) => {
      console.debug(currentActionId, "returnToHandler");

      // we need an Auth0 context for this to work
      if (isAuthenticated && !isClaimsLoading && sub) {
        const deskReturnTo = getParam("deskReturnTo");
        const returnToUrl = formatReturnUrl(deskReturnTo);
        const supportReturnTo = getSupportReturnTo(sub);

        if (supportReturnTo || deskReturnTo) {
          const successText = getParam("success");
          const message = getParam("message");

          let _returnToParam = deskReturnTo;
          let _returnToUrl = formatReturnUrl(deskReturnTo);

          if (supportReturnTo) {
            console.debug(">>> Restoring savedReturnTo: ", supportReturnTo);
            _returnToParam = supportReturnTo;
            _returnToUrl = formatReturnUrl(_returnToParam);
            removeSupportReturnTo(sub);
          }

          if (_returnToParam) {
            let _target = _returnToUrl;
            // don't pass on the message+success to other sites that can't handle it.
            if (!returnToUrl.startsWith(window.location.origin)) {
              if (message) {
                _target += `?message=${message}`;
                if (successText) {
                  _target += `&success=${successText}`;
                }
              }
            }
            window.location.href = _target;
          }
        } else {
          nextStep();
        }
      }
    },
    [isAuthenticated, isClaimsLoading, sub]
  );

  const renderAppHandler = useCallback(({ nextStep, currentActionId }) => {
    console.debug(currentActionId, "renderAppHandler");

    setRenderApp(true);

    nextStep();
  }, []);

  const accountWizardHandler = useCallback(
    ({ nextStep, currentActionId }) => {
      console.debug(currentActionId, "accountWizardHandler");

      if (user?.id) {
        const wasWizardClosed = user?.progress >= 1;

        if (!wasWizardClosed) {
          setShowAccountWizard(true);
        }
        nextStep();
      }
    },
    [user?.id, user?.progress]
  );

  const hideOverlayHandler = useCallback(({ nextStep, currentActionId }) => {
    console.debug(currentActionId, "hideOverlayHandler");

    document.body.style.background = "#ffffff";
    setShowOverlay(false);

    nextStep();
  }, []);

  const onboardingHandler = useCallback(
    ({ nextStep, currentActionId }) => {
      console.debug(currentActionId, "onboardingHandler");

      if (user?.id) {
        if (pathname !== "/") {
          nextStep();
        } else if (!hasOnboardingEnabled) {
          nextStep();
        } else if (showTermsDialog || user?.progress >= 2) {
          nextStep();
        } else if (user?.progress === 1) {
          setRequestOnboardingStart(true);
        } else if (user?.progress >= 2 && requestOnboardingStart) {
          setRequestOnboardingStart(false);
          nextStep();
        }
      }
    },
    [showTermsDialog, user?.id, user?.progress, requestOnboardingStart, pathname]
  );

  const newActions = useMemo(
    () => [
      magicLinkHandler,
      goErrorHandler,
      goLogoutHandler,
      logoutHandler,
      loginHandler,
      deskLoginHandler,
      saveReturnToHandler,
      emailVerificationHandler,
      notificationHandler,
      termsOfServiceHandler,
      analyticsSignUpHandler,
      analyticsLoginHandler,
      goHandler,
      returnToHandler,
      renderAppHandler,
      accountWizardHandler,
      hideOverlayHandler,
      onboardingHandler,
    ],
    [
      magicLinkHandler,
      goErrorHandler,
      goLogoutHandler,
      logoutHandler,
      loginHandler,
      deskLoginHandler,
      saveReturnToHandler,
      emailVerificationHandler,
      notificationHandler,
      termsOfServiceHandler,
      analyticsSignUpHandler,
      analyticsLoginHandler,
      goHandler,
      returnToHandler,
      renderAppHandler,
      accountWizardHandler,
      hideOverlayHandler,
      onboardingHandler,
    ]
  );

  useEffect(() => {
    const nextStep = () => setCurrentActionId(currentActionId + 1);
    const currentAction = newActions[currentActionId];

    if (currentActionId < newActions.length) {
      currentAction({ nextStep, currentActionId });
    } else {
      console.debug(currentActionId, "FINITO");
    }
  }, [currentActionId, newActions]);

  return (
    <LandingContext.Provider value={{ requestOnboardingStart }}>
      {showOverlay && <LoadingOverlay showFeedback useTopWithId="LandingProvider" />}
      {showEmailDialog && <DialogEmail />}
      {showTermsDialog && <DialogTerms />}
      {magicLinkError && <MagicLinkError message={magicLinkError} />}
      {renderApp && (
        <>
          <Snackbar
            open={snack?.open}
            severity={snack?.severity}
            message={snack?.message}
            autoHideDuration={snack?.timeout || 6000}
            onClose={() => setSnack(null)}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert variant="filled" severity={snack?.severity} onClose={() => setSnack(null)}>
              {snack?.message}
            </Alert>
          </Snackbar>
          {children}
          {showAccountWizard && <AccountWizard />}
        </>
      )}
    </LandingContext.Provider>
  );
};

export default LandingProvider;
