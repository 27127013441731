import { Box, Link, Grid } from "@mui/material";
import { styled } from "@mui/system";
import { JourneyClock } from "./JourneyClock";

import { EXTERNAL_HREFS, hasJourneyClock } from "constants";

const ConsensusHomeTab = ({ goToJourneyFn }) => {
  return (
    <>
      {hasJourneyClock ? (
        <>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Link
              href={EXTERNAL_HREFS["consensus_register"]}
              rel="noopener"
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Box
                my={1}
                component="img"
                alt="Consensus 2023"
                sx={{
                  maxWidth: "100%",
                  objectFit: "contain",
                  content: {
                    xs: `url("/images/C23Ad-narrow.png")`,
                    md: `url("/images/C23Ad-wide.png")`,
                  },
                }}
              />
            </Link>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <JourneyClock goToJourneyFn={goToJourneyFn} />
          </Box>
        </>
      ) : (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Grid container spacing={4} pt={4} pb={4} sx={{ maxWidth: 1200 }}>
            <Grid item xs={12} sm={6}>
              <Box
                onClick={goToJourneyFn}
                component="img"
                alt="DESK Treasure Hunt"
                src="/images/ui_cards/DESK_Journey_UICard.png"
                sx={{ cursor: "pointer", width: "100%" }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Link href="https://go.coindesk.com/Clue0" rel="noopener" target="_blank">
                <Box
                  component="img"
                  alt="DESK Treasure Hunt"
                  src="/images/ui_cards/DESK_TreasureHunt_UICard.png"
                  sx={{ width: "100%" }}
                />
              </Link>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Link href="https://go.coindesk.com/quests" rel="noopener" target="_blank">
                <Box
                  component="img"
                  alt="#DESKChallenge"
                  src="/images/ui_cards/DESK_Challenges_UICard.png"
                  sx={{ width: "100%" }}
                />
              </Link>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Link
                href="https://consensus.coindesk.com/agenda/event/-desk-roundtable-260"
                rel="noopener"
                target="_blank"
              >
                <Box
                  component="img"
                  alt="DESK Roundtable"
                  src="/images/ui_cards/DESK_Roundtable_UICard.png"
                  sx={{ width: "100%" }}
                />
              </Link>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Link
                href="https://consensus.coindesk.com/agenda/event/-desk-happy-hour-241"
                rel="noopener"
                target="_blank"
              >
                <Box
                  component="img"
                  alt="DESK Happy Hour"
                  src="/images/ui_cards/DESK_HappyHour_UICard.png"
                  sx={{ width: "100%" }}
                />
              </Link>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Link href="https://consensus.coindesk.com/desk/" rel="noopener" target="_blank">
                <Box
                  component="img"
                  alt="Check out all DESK Events "
                  src="/images/ui_cards/DESK_Events_UICard.png"
                  sx={{ width: "100%" }}
                />
              </Link>
            </Grid>
          </Grid>
        </Box>
      )}
      {/* <Grid container spacing={4} pt={4} pb={4} sx={{ maxWidth: 1200 }}> */}
      {/* <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Link href={EXTERNAL_HREFS["consensus_register"]} rel="noopener">
                <Box
                  my={1}
                  component="img"
                  sx={{ maxWidth: "100%", objectFit: "contain" }}
                  src="/images/consensus/C23_CMC_TwoDay_50.png"
                  alt="Microcosms, Mint Now"
                />
              </Link>
            </Box>
          </Grid> */}
      {/* <Grid item xs={12} md={8} sx={{ display: "flex", alignItems: "center" }}> */}
      {/* <Box
          onClick={() => window.open(EXTERNAL_HREFS["consensus_mint"], "_blank", "noopener")}
          mt={3}
          sx={{
            width: "100%",
            maxWidth: 550,
            border: "1.5px solid #ffffff",
            outline: "10px solid #000000",
            backgroundColor: "#000000",
            cursor: "pointer",
            aspectRatio: "16/9",
          }}
          p={3}
        >
          <Grid container spacing={1}>
            <Grid item xs={8} sm={6}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                <Box>
                  <img src="/images/consensus/c23_logo.svg" alt="Consensus 2023 Logo" />
                </Box>
                <Typography
                  variant="montserrat_bold"
                  pt={2}
                  sx={{
                    fontSize: { xs: 17, sm: 25 },
                    textTransform: "uppercase",
                  }}
                >
                  Now minting
                </Typography>
                <Typography
                  variant="montserrat_bold"
                  color="primary"
                  mt={-1.5}
                  pb={1}
                  sx={{
                    fontSize: { xs: 24, sm: 34 },

                    textTransform: "uppercase",
                  }}
                >
                  Microcosms
                </Typography>
                <Typography
                  variant="montserrat"
                  pb={2}
                  sx={{
                    fontSize: { xs: 13, sm: 16 },
                    lineHeight: 1,
                    textTransform: "uppercase",
                  }}
                >
                  A multi-tiered event, rewards and generative art experience for Consensus
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={4} sm={6} textAlign="right">
              <Box
                component="img"
                src="/images/consensus/microcosms.png"
                alt="Microcosms, mint now!"
                sx={{ maxWidth: "100%" }}
              />
            </Grid>
            <Grid item xs={12}>
              <Box>
                <Button
                  color="primary"
                  variant="contained"
                  sx={{
                    fontSize: "20px",
                    py: 0,
                    mt: 1,
                    textTransform: "uppercase",
                    borderRadius: 0,
                    fontFamily: "'Montserrat', sans-serif",
                    fontWeight: 800,
                    width: { xs: "100%", sm: "auto" },
                  }}
                >
                  Mint now
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box> */}
      {/* </Grid> */}
      {/* <Grid item xs={12} md={6} sx={{ display: "flex", alignItems: "center" }}>
            <Box
              sx={{
                width: "100%",
                border: "1.5px solid #ffffff",
                outline: "10px solid #000000",
                backgroundColor: "#000000",
                aspectRatio: "16/10",
              }}
              p={3}
            >
              <Typography
                variant="h3"
                display="flex"
                alignItems="center"
                fontSize={30}
                color="primary"
                sx={{
                  fontFamily: "'Montserrat', sans-serif",
                  fontWeight: 800,
                  textTransform: "uppercase",
                }}
              >
                Marketplace
              </Typography>
              <Typography
                pb={2}
                sx={{
                  fontFamily: "'Montserrat', sans-serif",
                  fontWeight: 500,
                  textTransform: "uppercase",
                }}
              >
                Buy NFTs with DESK™
              </Typography>
              <Box display="flex" justifyContent="space-between" mx={2}>
                <MarketItem component="a" href={EXTERNAL_HREFS.nftmarket} target="_blank">
                  <Stack>
                    <img src={`/nft_1.gif`} alt="DESK™ Marketplace" />
                    <Box display="flex" alignItems="center" justifyContent="center" py={1}>
                      <Button
                        color="primary"
                        variant="contained"
                        sx={{
                          fontSize: "20px",
                          py: 0,
                          mt: 1,
                          textTransform: "uppercase",
                          borderRadius: 0,
                          fontFamily: "'Montserrat', sans-serif",
                        }}
                      >
                        Collect
                      </Button>
                    </Box>
                  </Stack>
                </MarketItem>
                <MarketItem
                  alter="alter"
                  component="a"
                  href={EXTERNAL_HREFS.nftmarket}
                  target="_blank"
                >
                  <Stack>
                    <img src={`/nft_2b.png`} alt="DESK™ Marketplace" />
                    <Box display="flex" alignItems="center" justifyContent="center" py={1}>
                      <Button
                        color="primary"
                        variant="contained"
                        sx={{
                          fontSize: "20px",
                          py: 0,
                          mt: 1,
                          textTransform: "uppercase",
                          borderRadius: 0,
                          fontFamily: "'Montserrat', sans-serif",
                          fontWeight: 800,
                        }}
                      >
                        Collect
                      </Button>
                    </Box>
                  </Stack>
                </MarketItem>
              </Box>
            </Box>
          </Grid> */}
      {/* <Grid item xs={6}>
            
          </Grid> */}
      {/* </Grid> */}
      {/* </Box> */}
    </>
  );
};

export { ConsensusHomeTab };

const MarketItem = styled(Box)`
  background-color: #000000;
  cursor: pointer;
  /* box-shadow: 0px 0px 10px 5px transparent; */
  transition: 0.5s;
  transform: translateY(0) scale(1);
  width: 48%;
  color: #ffffff;
  text-decoration: none;
  img {
    width: 90%;
    height: auto;
    margin: 0 auto;
  }
`;
