import { IconButton, Alert, Button, Stack, Box, Typography, Dialog } from "@mui/material";
import { useEffect, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import RedoIcon from "@mui/icons-material/Redo";

import { InlineButtonLink } from "components";
import { EXTERNAL_HREFS, publicUrl } from "constants";
import { useDeskAuth, useUser } from "providers";

const message = new URLSearchParams(window.location.search).get("message") || "";
const successText = new URLSearchParams(window.location.search).get("success") || "";
const assumeEmailVerified = message.search("verified") > -1 && successText === "true";

export const DialogEmail = () => {
  const {
    api: { resendEmail },
    account: { email },
    isEmailVerified,
  } = useUser();
  const { deskLogout, deskLoginTrySilent } = useDeskAuth();
  const [isVerifyEmailDialogOpen, setIsVerifyEmailDialogOpen] = useState(false);
  const [resendRequested, setResendRequested] = useState(false);
  const [message, setMessage] = useState(null);

  const requestEmailResend = () => {
    setResendRequested(true);
    resendEmail()
      .then(() => {
        setMessage({
          type: "success",
          content: "A verification email has been requested on your behalf.",
        });
      })
      .catch((err) => {
        setMessage({
          type: "error",
          content: `Problem requesting email resend. ${err.message} Please contact support.`,
        });
      });
  };

  useEffect(() => {
    if (!isEmailVerified) {
      if (assumeEmailVerified) {
        deskLoginTrySilent();
      } else {
        setIsVerifyEmailDialogOpen(true);
      }
    }
  }, [isEmailVerified, deskLoginTrySilent]);

  return (
    <>
      <Dialog
        open={!assumeEmailVerified && isVerifyEmailDialogOpen}
        onClose={deskLogout}
        sx={{ zIndex: 1302 }}
      >
        <Box pt={2} pr={2} width="100%" display="flex" justifyContent="right">
          <IconButton color="contrastInvert" onClick={deskLogout}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box p={5} pt={0} sx={{ width: 500, maxWidth: "100%" }}>
          <Typography variant="h2" textAlign="center">
            Check your Inbox
          </Typography>
          <Stack spacing={2} py={2}>
            <Box display="flex" justifyContent="center">
              <img
                src={`${publicUrl}/auth-email.png`}
                alt="Email message"
                width="195"
                height="195"
              />
            </Box>
            <Typography variant="h2" textAlign="center">
              Verify your Email
            </Typography>
            <Typography>
              We sent an email to <strong>{email || "your Inbox"}</strong>. Use the link to finish
              creating your account.
            </Typography>
            <Typography>Can't find the email? Check your spam folder.</Typography>
            <Box>
              <Button
                sx={{ width: "100%" }}
                variant="outlined"
                color="contrastInvert"
                component="a"
                href={EXTERNAL_HREFS["coindesk"]}
              >
                Go to CoinDesk
              </Button>
              <Button
                disabled={resendRequested}
                size="large"
                sx={{ width: "100%" }}
                color="success"
                startIcon={<RedoIcon />}
                onClick={requestEmailResend}
              >
                Re-send email
              </Button>

              {message && (
                <Alert severity={message.type} onClose={() => setMessage(null)}>
                  {message.content}
                </Alert>
              )}
            </Box>

            <Typography pt={2} textAlign="center">
              Need more help?{" "}
              <InlineButtonLink component="a" href={EXTERNAL_HREFS["help"]} target="_blank">
                Contact us
              </InlineButtonLink>
            </Typography>
          </Stack>
        </Box>
      </Dialog>
    </>
  );
};
