import { Box, Dialog, IconButton, Typography } from "@mui/material";
import { useState } from "react";

import CloseIcon from "@mui/icons-material/Close";

import { ConnectWallet, InlineButtonLink } from "components";
import { isDesktop } from "utils";

export const WalletSelection = ({ isConnectModalOpened, setIsConnectModalOpened }) => {
  // this is to avoid clash with WalletConnect modal
  const [hideModalTemporarily, setHideModalTemporarily] = useState(false);

  return (
    <Dialog
      open={isConnectModalOpened}
      keepMounted
      onClose={() => setIsConnectModalOpened(false)}
      componentsProps={{
        root: {
          sx: {
            display: hideModalTemporarily ? "none" : undefined,
          },
        },
      }}
    >
      <Box
        sx={{ width: 550, maxWidth: "100%" }}
        px={{ xs: 3, sm: 6, md: 9 }}
        pt={9}
        pb={5}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <IconButton
          color="contrastInvert"
          sx={{ position: "absolute", top: 24, right: 24 }}
          onClick={() => setIsConnectModalOpened(false)}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="h1">Connect to redeem</Typography>
        {true /*isDesktop*/ ? (
          <>
            <Typography pt={3} pb={1}>
              On desktop, DESK™ works with MetaMask, Coinbase, and Polygon-enabled wallets available
              through WalletConnect. WalletConnect Tip:
            </Typography>
            <Typography pt={1} pb={1}>
              <strong>WalletConnect Tip:</strong> Scan the QR code from your mobile wallet to
              connect after clicking “Connect Wallet.”
            </Typography>
          </>
        ) : (
          <>
            <Typography pt={3} pb={1}>
              On mobile, DESK™ works with Polygon-enabled wallets available through WalletConnect.
            </Typography>
            <Typography pt={1} pb={1}>
              <strong>Tip:</strong> right now Coinbase Wallet users and some MetaMask users will
              need to use desktop when connecting or transferring.
            </Typography>
          </>
        )}

        <Typography pt={1} pb={6}>
          <InlineButtonLink
            component="a"
            href="https://walletconnect.com/explorer?type=wallet&chains=eip155%3A1"
            target="_blank"
          >
            See all compatible wallets
          </InlineButtonLink>
        </Typography>

        <ConnectWallet
          isConnectModalOpened={isConnectModalOpened}
          setHideModalTemporarily={setHideModalTemporarily}
          setIsConnectModalOpened={setIsConnectModalOpened}
        />
      </Box>
    </Dialog>
  );
};
