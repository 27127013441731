import { useState } from "react";
import { Typography, Button, Box, Dialog, IconButton } from "@mui/material";

import InfoIcon from "@mui/icons-material/Info";
import { InlineButtonLink } from "components/InlineButtonLink";
import CloseIcon from "@mui/icons-material/Close";

export const EmailPermanentHint = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  return (
    <>
      <Box display="flex">
        <InfoIcon sx={{ color: "grey.500" }} />
        <Typography color="grey.500" pl={1}>
          Email is not editable.{" "}
          <InlineButtonLink onClick={() => setIsDialogOpen(true)}>Why?</InlineButtonLink>
        </Typography>
      </Box>
      <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
        <Box p={2} pb={6}>
          <Box width="100%" display="flex" justifyContent="right">
            <IconButton color="contrastInvert" onClick={() => setIsDialogOpen(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box textAlign="center">
            <Typography variant="h2">Why can't I change my Email?</Typography>

            <Typography p={{ xs: 2, sm: 8 }}>
              We can't change your email because it's associated with the provider you used to sign up.
            </Typography>
            <Button
              variant="contained"
              sx={{ width: 300, maxWidth: "100%" }}
              onClick={() => setIsDialogOpen(false)}
            >
              OK
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};
