import { LoadingOverlay } from "components";
import { createContext, useState, useContext, useMemo, useEffect } from "react";

const LoadingContext = createContext({ loadersRunning: { initial: true }, setProps: () => {} });

export const useLoading = () => useContext(LoadingContext);

let timeout;

const LoadingProvider = ({ children }) => {
  const [loadersRunning, setLoadersRunning] = useState({});
  const [shouldShowLoader, setShouldShowLoader] = useState(true);

  const isAnyLoading = useMemo(
    () => Object.values(loadersRunning).some((isTrue) => isTrue),
    [loadersRunning]
  );

  useEffect(() => {
    if (isAnyLoading) {
      setShouldShowLoader(true);
    } else {
      clearTimeout(timeout);
      timeout = setTimeout(() => setShouldShowLoader(false), 0);
    }
  }, [isAnyLoading]);

  return (
    <LoadingContext.Provider value={{ loadersRunning, setLoadersRunning }}>
      {shouldShowLoader && <LoadingOverlay isTop showFeedback />}
      {children}
    </LoadingContext.Provider>
  );
};

export default LoadingProvider;
