import {
  CircularProgress,
  Dialog,
  Paper,
  Box,
  Typography,
  IconButton,
  Stack,
  CardMedia,
  Button,
} from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import { LoadingOverlay, SpinningCoin } from "components";
import { useEffect, useState, useCallback } from "react";
import { useUser } from "providers";
import axios from "axios";
import { apiUrl } from "constants";

import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/system";

const ClaimReward = () => {
  const navigate = useNavigate();
  const { rewardId } = useParams();
  const { token } = useUser();
  const [reward, setReward] = useState(null);
  const [isClaimed, setIsClaimed] = useState(false);
  const [isClaiming, setIsClaiming] = useState(false);

  const redirectTarget = reward?.type === "JOURNEY" ? "/?journey=consensus" : "/quest";

  const onCloseDialog = useCallback(() => {
    navigate(redirectTarget);
  }, [navigate, redirectTarget]);

  useEffect(() => {
    if (token) {
      axios
        .get(`${apiUrl}/api/claim/${rewardId}`, { headers: { Authorization: `Bearer ${token}` } })
        .then(({ data }) => {
          setIsClaimed(data.isClaimed);
          setReward(data.reward);
        })
        .catch((err) => {
          console.log(err);
          onCloseDialog();
        });
    }
  }, [onCloseDialog, rewardId, token]);

  const handleClaimReward = (t) => {
    axios
      .post(
        `${apiUrl}/api/claim/${rewardId}`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .catch((err) => {
        console.log(err.response.data.reason || err.response.data.message || err);
        alert(
          err.response.data.reason ||
            err.response.data.message ||
            "Something went wrong. Please try again later."
        );
      })
      .then(({ data }) => {
        console.log(data);
      });

    setIsClaiming(true);
    setTimeout(() => {
      setIsClaiming(false);
      setIsClaimed(true);
    }, t);
  };

  return (
    <Dialog
      open
      sx={{ zIndex: 1301 }}
      onClose={() => {}}
      PaperComponent={!reward ? () => <CircularProgress color="dark" /> : Paper}
      PaperProps={{
        sx: {
          margin: { xs: 0, sm: 2 },
          maxHeight: { xs: "100vh", sm: "calc(100vh - 32px)" },
        },
      }}
      slots={{
        backdrop: LoadingOverlay,
      }}
    >
      <Box pt={2} pr={2} width="100%" display="flex" justifyContent="right">
        <IconButton color="contrastInvert" onClick={onCloseDialog}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box
        p={5}
        py={0}
        sx={{
          width: 500,
          maxWidth: "100vw",
        }}
      >
        <Typography variant="h2" textAlign="center">
          {reward?.title}
        </Typography>
        <Stack spacing={6} pt={4} pb={5}>
          <Box display="flex" justifyContent="center">
            <CardMedia
              component="img"
              image={reward?.image || "/images/big_coin.png"}
              alt={`Cover for "${reward?.title} reward.`}
              sx={{ maxWidth: 200, maxHeight: 200, objectFit: "contain" }}
            />
          </Box>
          <Typography variant="body1" textAlign="center" sx={{ whiteSpace: "pre-wrap" }}>
            {reward?.description}
          </Typography>
          <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
            {isClaimed ? (
              <>
                {reward?.amount === "0" && (
                  <Typography variant="subtitle2" sx={{ textAlign: "center", fontStyle: "italic" }}>
                    The experience has been marked as claimed!
                  </Typography>
                )}
                <Button component={Link} to={redirectTarget} variant="contained" fullWidth>
                  View more ways to earn!
                </Button>
              </>
            ) : (
              <>
                {reward?.amount !== "0" ? (
                  <RewardButton
                    variant="contained"
                    onClick={() => handleClaimReward(5400)}
                    data-amount={reward?.amount}
                    className={isClaiming ? "claiming" : ""}
                    disabled={isClaiming}
                    sx={{ position: "relative", zIndex: 1 }}
                    isClaiming={isClaiming}
                  >
                    Claim {reward?.amount}{" "}
                    <SpinningCoin
                      stopped={!isClaiming}
                      once
                      levitating
                      small
                      claimed={isClaiming}
                    />
                    ESK™
                  </RewardButton>
                ) : (
                  <Button
                    variant="contained"
                    onClick={() => handleClaimReward(1000)}
                    color="primary"
                  >
                    {isClaiming ? (
                      <CircularProgress size={24} color="dark" />
                    ) : (
                      "Claim this experience!"
                    )}
                  </Button>
                )}
              </>
            )}
            {/* <InlineButtonLink
              component={Link}
              to="/edu/faqs/general"
              sx={{ fontWeight: 400, fontSize: 14, pt: 2 }}
            >
              What is DESK™?
            </InlineButtonLink> */}
          </Box>
        </Stack>
      </Box>
    </Dialog>
  );
};

export { ClaimReward };

const RewardButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "isClaiming",
})(
  ({ isClaiming }) =>
    isClaiming &&
    `

  animation: disappear 0.3s forwards;
  animation-delay: 5.1s;

  :after {
    color: #222222;
    content: "+" attr(data-amount);
    display: block;
    position: absolute;

    margin-top: -12px;
    left: calc(100% - 16px);
    background-color: #ffffff;
    z-index: 10004;
    padding: 12px;
    font-size: 24px;
    border-radius: 16px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    animation: pill 4s forwards;
    opacity: 0;
    pointer-events: none;
    width: 100px;
    height: 66px;
    left: 50%;
    top: -80px;


    @keyframes pill {
      0% {
        transform: translateY(0);
        opacity: 1;
      }
      50% {
        transform: translateY(-6px);
        opacity: 1;
      }
      100% {
        transform: translateY(-12px);
        opacity: 0;
      }
    }

    @keyframes disappear {
      0% {
        opacity: 1;
        transform: scale(1);
      }
      100% {
        opacity: 0;
        transform: scale(0.7);
      }
    }
  }
`
);
