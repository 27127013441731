import { Fragment, useEffect, useState, useMemo } from "react";
import { Box, Typography, Button } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

const JourneyLevelButtons = ({
  levelsWithoutBonusCompleted,
  levelIdsWithoutBonus,
  activeLevelIndex,
  setActiveLevelIndex,
  isLoading,
  levelBodies,
}) => {
  const [allowedLevel, setAllowedLevel] = useState(0);
  const [allowedLevelDefined, setAllowedLevelDefined] = useState(false);

  const claimDataLoaded = useMemo(() => {
    return (
      levelBodies?.length > 1 && levelBodies.every((levelBody) => levelBody.isClaimed !== undefined)
    );
  }, [levelBodies]);

  useEffect(
    function findUnclaimedLevel() {
      if (!isLoading && claimDataLoaded) {
        const index = levelIdsWithoutBonus.findIndex(
          (levelId) => !levelBodies.find((levelBody) => levelBody.id === levelId).isClaimed
        );
        if (index !== -1) {
          setAllowedLevel(index);
        } else {
          const goTo = levelsWithoutBonusCompleted
            ? levelIdsWithoutBonus.length
            : levelIdsWithoutBonus.length - 1;
          setAllowedLevel(goTo);
        }
      }
    },
    [
      levelIdsWithoutBonus,
      levelsWithoutBonusCompleted,
      setActiveLevelIndex,
      isLoading,
      claimDataLoaded,
      levelBodies,
    ]
  );

  useEffect(() => {
    if (allowedLevel && claimDataLoaded && !allowedLevelDefined) {
      setActiveLevelIndex(allowedLevel);
      setAllowedLevelDefined(true);
    }
  }, [allowedLevel, setActiveLevelIndex, claimDataLoaded, allowedLevelDefined]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "auto",
        pb: 3,
      }}
    >
      {(isLoading ? [0, 1, 2] : levelIdsWithoutBonus).map((reward, idx, arr) => {
        return (
          <Fragment key={reward?.id || idx}>
            <Button
              // color="primary"
              color={!levelsWithoutBonusCompleted && idx > allowedLevel ? "white" : "primary"}
              variant={idx === activeLevelIndex ? "contained" : "outlined"}
              // disabled={!levelsWithoutBonusCompleted && idx > allowedLevel}
              onClick={() => {
                setActiveLevelIndex(idx);
              }}
              sx={{
                whiteSpace: "nowrap",
                minWidth: 0,
                px: levelsWithoutBonusCompleted ? 1 : 2,
              }}
            >
              {!levelsWithoutBonusCompleted ? (
                <>Level {idx + 1}</>
              ) : (
                <>
                  {idx + 1} <CheckIcon />
                </>
              )}
            </Button>
            {idx !== arr.length - 1 && (
              <Typography
                px={1}
                sx={{
                  whiteSpace: "nowrap",
                }}
              >
                ---
              </Typography>
            )}
          </Fragment>
        );
      })}
      {levelsWithoutBonusCompleted && (
        <>
          <Typography px={1}>---</Typography>
          <Button
            color="success"
            variant="contained"
            onClick={() => {
              setActiveLevelIndex(levelIdsWithoutBonus.length);
            }}
          >
            Bonus!
          </Button>
        </>
      )}
    </Box>
  );
};

export { JourneyLevelButtons };
