import { Box, Typography, Button, CircularProgress } from "@mui/material";

import { Link } from "react-router-dom";
import { useMemo } from "react";

import CheckIcon from "@mui/icons-material/Check";

import { GradientBorder } from "components";

// const MAGIC_SUBTRACTION_MAP = {
//   "Total of this reward will be subtracted in the UI":
//     "Need claims of this reward will be the amount to subtract",
//   // Booths:
//   "f6e2746c-e6c0-425f-aec6-9e57eb3c4df0": "70c8dd80-3a0d-4707-8263-244476686724",
//   "19ffc746-89fd-4cd5-840d-97a0052a0564": "f6e2746c-e6c0-425f-aec6-9e57eb3c4df0",
//   "f861cc87-d0fb-4b70-bc5e-88b9ebe3a836": "19ffc746-89fd-4cd5-840d-97a0052a0564",
//   // Sessions:
//   "8b579f71-7900-4b88-8a8d-74c72860af5e": "2d43a7c3-b0c3-4779-b405-328be7c3e437",
//   "f2ce699d-0e4b-41a3-a77c-cfccc653d8bd": "8b579f71-7900-4b88-8a8d-74c72860af5e",
//   "7f43167b-d805-4cf1-99bf-218eba003fb0": "f2ce699d-0e4b-41a3-a77c-cfccc653d8bd",
// };

const JourneyGroup = ({ groupId, getRewardBodyById, claimRewardFn }) => {
  const groupBody = useMemo(() => getRewardBodyById(groupId), [groupId, getRewardBodyById]);

  const totalCount = useMemo(
    () =>
      !groupBody.depends?.length
        ? 1
        : groupBody.isOrdered
        ? groupBody.depends.length
        : groupBody.needClaims,
    [groupBody]
  );

  const handleClaimGroup = () => {
    claimRewardFn(groupBody.id);
  };

  const doneCount = useMemo(() => {
    const count = groupBody.isClaimed ? totalCount : groupBody.childrenClaimed;
    return count;
  }, [groupBody, totalCount]);

  // const magicSubtraction = useMemo(() => {
  //   const magicSubtractionId = MAGIC_SUBTRACTION_MAP[groupBody.id];
  //   if (!magicSubtractionId) return 0;
  //   const magicSubtractionBody = getRewardBodyById(magicSubtractionId);
  //   if (!magicSubtractionBody) return 0;
  //   return magicSubtractionBody.needClaims;
  // }, [groupBody, getRewardBodyById]);

  // const magicDoneCount = useMemo(
  //   () => Math.max(0, doneCount - magicSubtraction),
  //   [doneCount, magicSubtraction]
  // );
  // const magicTotalCount = useMemo(
  //   () => Math.max(0, totalCount - magicSubtraction),
  //   [totalCount, magicSubtraction]
  // );

  // const doneCount = useMemo(() => {
  //   const count =
  //     groupBody.depends?.length === 0
  //       ? groupBody.isClaimed
  //         ? totalCount
  //         : 0
  //       : groupChildren.reduce((acc, groupChild) => {
  //           return acc + groupChild.isClaimed;
  //         }, 0);
  //   return isNaN(count) ? "..." : count;
  // }, [groupBody, totalCount, groupChildren]);

  const isGroupComplete = useMemo(() => {
    if (groupBody.depends?.length === 0) return groupBody.isClaimed;

    return doneCount >= totalCount;
  }, [groupBody, doneCount, totalCount]);

  return (
    <GradientBorder withBackground>
      <Box
        component="img"
        src={groupBody.image || "/images/desk_logo.svg"}
        sx={{
          maxWidth: "100%",
          borderRadius: "50%",
          p: {
            xs: 0.5,
            sm: 2,
          },
        }}
      />
      <Box
        sx={{
          minHeight: {
            xs: 85,
            sm: 75,
          },
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          my: {
            xs: -1,
            sm: 0,
          },
        }}
      >
        <Typography
          px={{
            xs: 1.5,
            sm: 2,
          }}
          mx={-1}
          sx={{
            fontSize: {
              xs: 14,
              sm: "unset",
            },
          }}
        >
          {groupBody.description}
        </Typography>
        {totalCount > 0 && (
          <Typography
            sx={{
              fontSize: {
                xs: 14,
                sm: "unset",
              },
            }}
          >
            ({doneCount}/{totalCount})
          </Typography>
        )}
      </Box>
      <Box
        p={2}
        mx={{
          xs: -1,
          sm: 0,
        }}
      >
        {isGroupComplete ? (
          !groupBody.isClaimed ? (
            <Button
              variant="outlined"
              color="primary"
              disabled={groupBody.isClaiming && !groupBody.isClaimingError}
              onClick={handleClaimGroup}
              sx={{
                width: "100%",
                px: 0,
                lineHeight: 0.9,
                height: 36,
              }}
            >
              {groupBody.isClaimingError ? (
                "Try again"
              ) : groupBody.isClaiming ? (
                <CircularProgress size={24} />
              ) : (
                "Mark as done!"
              )}
            </Button>
          ) : (
            <Button variant="outlined" disabled sx={{ width: "100%", px: 0 }}>
              <CheckIcon />
            </Button>
          )
        ) : (
          <Button
            component={Link}
            to={groupBody.link}
            disabled={!groupBody.link}
            color="primary"
            variant="contained"
            sx={{ width: "100%", px: 0 }}
          >
            {groupBody.linkText || "ERROR"}
          </Button>
        )}
      </Box>
    </GradientBorder>
  );
};

export { JourneyGroup };
