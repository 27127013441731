import { Box, Button, Typography } from "@mui/material";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import { useAccount, useDisconnect } from "wagmi";
import { DialogWrapper } from "components";
import { calcWalletIcon, calcWalletName } from "utils";

export const WalletDisconnect = ({ isDisconnectModalOpened, setIsDisconnectModalOpened }) => {
  const { connector } = useAccount();
  const { disconnect } = useDisconnect();

  const disconnectWallet = () => {
    disconnect();
    setIsDisconnectModalOpened(false);
  };

  return (
    <DialogWrapper
      open={isDisconnectModalOpened}
      onClose={() => setIsDisconnectModalOpened(false)}
      title="Disconnect Wallet"
      description="Disconnecting your wallet will erase your quest and ledger history"
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          py: 9,
        }}
      >
        {connector && calcWalletIcon(calcWalletName(connector))}{" "}
        <Typography fontSize={{ xs: 18, sm: 24 }} fontWeight={700} pl={2}>
          {connector?.name.replace("(deprecated)", "").toUpperCase()}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Button
          color="contrastInvert"
          variant="outlined"
          onClick={disconnectWallet}
          startIcon={<LinkOffIcon />}
          sx={{ width: 240 }}
        >
          Disconnect Wallet
        </Button>
      </Box>
    </DialogWrapper>
  );
};
