import {
  Typography,
  Button,
  Box,
  Dialog,
  TextField,
  MenuItem,
  IconButton,
  Chip,
  InputAdornment,
  CircularProgress,
  LinearProgress,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import { useForm } from "react-hook-form";
import { readAndCompressImage } from "browser-image-resizer";
import { knowledgeLevelsMaps, allInterests, userIdentifications } from "constants";

import { useUser } from "providers";
import { useState, useEffect } from "react";
import { Stack } from "@mui/system";

const AVATAR_SIZE = 100; // avatar images are never larger than 100x100
const PADDED_SIZE = AVATAR_SIZE + 2; // add 2px padding to the image size
const AVATAR_HEIGHT = `${PADDED_SIZE}px`;
const AVATAR_WIDTH = `${PADDED_SIZE}px`;

export const SettingDialog = ({ fieldToEdit, setFieldToEdit, setSnack }) => {
  const {
    user,
    setUser,
    api: { profilePatch, getUploadPath },
  } = useUser();

  const isKnowledge = fieldToEdit === "knowledge";
  const isInterests = fieldToEdit === "interests";
  const isAvatarImage = fieldToEdit === "avatarImage";
  const currentFieldValue = user[fieldToEdit];

  const defaultValues = {
    [fieldToEdit]: isKnowledge ? knowledgeLevelsMaps[currentFieldValue] : currentFieldValue,
  };

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { errors, isDirty, isValid, isSubmitted },
  } = useForm({
    defaultValues,
  });

  const fieldLabelsMap = {
    firstName: "First Name",
    lastName: "Last Name",
    shortName: "Username",
    knowledge: "Knowledge Level",
    userIdentification: "Tell us about yourself",
    bio: "Bio",
    avatarImage: "Avatar Image",
    interests: "Interests",
  };

  const [fileToUpload, setFileToUpload] = useState(false);
  const [imageData, setImageData] = useState(null);
  const [previewURL, setPreviewURL] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    if (fieldToEdit === "userIdentification" || fieldToEdit === "avatarImage") {
      register(fieldToEdit, {
        required: "This field is required.",
      });
    }
  }, [register, fieldToEdit]);

  const uploadToS3 = async (file, imageData) => {
    if (!file || !imageData) {
      return;
    }

    try {
      const form = new FormData();
      form.append("name", file.name);
      form.append("type", file.type);
      form.append("image", imageData, file.name);

      const result = await getUploadPath(form, {
        "Content-Type": undefined,
      });

      const upload = new FormData();
      Object.entries(result.fields).forEach(([key, value]) => {
        upload.append(key, value);
      });
      upload.append("file", imageData);

      const res =  await fetch(result.url, {
        method: "POST",
        body: upload,
      });

      if (res.status === 204) {
        const url = `${result.url}${result.fields.key}`;
        setValue("avatarImage", url, { shouldDirty: true });
        return url;
      }
      return null;

    } catch (err) {
      return null;
    }
  };

  const onSubmitHandler = async (data) => {
    if (data.knowledge) data.knowledge = knowledgeLevelsMaps[data.knowledge];

    let fieldValue = data[fieldToEdit];
    if (isAvatarImage && fileToUpload && imageData) {
      setIsUploading(true);
      fieldValue = await uploadToS3(fileToUpload, imageData);
      // We don't know the new value for data["avatarImage"] until the upload is complete
      // so we need to grab it here and save it for profilePatch below.
      setIsUploading(false);
    }

    if (fieldValue !== null) {
      profilePatch({ [fieldToEdit]: fieldValue })
        .then((result) => {
          setSnack({ open: true, type: "success", message: `${fieldLabelsMap[fieldToEdit]} updated.` });
          setUser[fieldToEdit](fieldValue);
        })
        .catch((err) => {
          if (err.status === 409) {
            const fieldToReport = fieldToEdit === "shortName" ? "username" : fieldToEdit;
            setSnack({ open: true, type: "error", message: `Sorry, that ${fieldToReport} is already in use.` });
          } else {
            setSnack({ open: true, type: "error", message: "Try it again: " + err.message });
          }
        })
        .finally(() => {
          setFieldToEdit(null);
        });
    } else if (isAvatarImage) {
        setSnack({ open: true, type: "error", message: "Invalid image type." });
        setFieldToEdit(null);
    }
  };

  const onInterestToggle = (interest, isCurrentlySelected) => {
    const updatedInterests = !isCurrentlySelected
      ? [...getValues("interests"), interest]
      : getValues("interests").filter((i) => i !== interest);

    setValue("interests", updatedInterests, { shouldDirty: true });
  };

  const onIdentificationChange = (e) => {
    setValue("userIdentification", e.target.value, { shouldDirty: true });
  };

  const onFileChange = async (e) => {
    const files = e.target.files || e.dataTransfer.files;
    if (!files.length) return;

    const file = files[0];
    let resizedImage = null;
    let objectURL;
    if (file.name.endsWith(".gif")) {
      // don't resize/scale-down GIF files
      objectURL = window.URL.createObjectURL(file);
      setImageData(file);
    } else {
      // convert everything else to a single image of maximum dimensions (scale down)
      resizedImage = await readAndCompressImage(file, {
        maxWidth: AVATAR_SIZE,
        maxHeight: AVATAR_SIZE,
        quality: 0.9,
        mimeType: file.type,
      });
      objectURL = window.URL.createObjectURL(resizedImage);
      setImageData(resizedImage);
    }
    setPreviewURL(objectURL);
    setFileToUpload(file);
    // This isn't the actual final value but this enables the Submit button.
    setValue("avatarImage", file.name, { shouldDirty: true });
  };

  const onFileRemove = () => {
    setFileToUpload("");
    setValue("avatarImage", "", { shouldDirty: true });
    onSubmitHandler({ avatarImage: "" });
  };

  return (
    <>
      <Dialog
        open
        onClose={() => setFieldToEdit(null)}
        transitionDuration={0}
        PaperProps={{ sx: { maxHeight: "100%", m: 0 } }}
      >
        <form onSubmit={handleSubmit(onSubmitHandler)}>
          <Box p={2} pb={5}>
            <Box width="100%" height="24px" display="flex" justifyContent="right">
              <IconButton color="contrastInvert" onClick={() => setFieldToEdit(null)}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Box textAlign="center">
              {fieldToEdit === "firstName" && (
                <Box>
                  <Typography variant="h2" pb={2}>
                    First Name
                  </Typography>

                  <Typography variant="subtitle2">Your name appears on your Profile.</Typography>
                  <Box p={3} sx={{ width: 400, maxWidth: "100%", minHeight: 150 }}>
                    <TextField
                      sx={{ width: "100%" }}
                      label="First Name"
                      variant="standard"
                      {...register("firstName", {
                        required: "First Name is required.",
                        minLength: {
                          value: 2,
                          message: "Enter at least 2 characters.",
                        },
                        maxLength: {
                          value: 50,
                          message: "Maximum length is 50 characters.",
                        },
                        validate: {
                          hasNoNumber: (value) => {
                            if (/\d/.test(value)) {
                              return "Numbers are not allowed.";
                            }
                          },
                          hasNoIllegalCharacter: (value) => {
                            if (/[^\p{L} ,.'-]/u.test(value)) {
                              return `Allowed special characters: space, comma, period, apostrophe, hyphen.`;
                            }
                          },
                        },
                      })}
                      error={!!errors?.firstName}
                      helperText={errors?.firstName?.message}
                    />
                  </Box>
                </Box>
              )}

              {fieldToEdit === "lastName" && (
                <Box>
                  <Typography variant="h2" pb={2}>
                    Last Name
                  </Typography>

                  <Typography variant="subtitle2">Your name appears on your Profile.</Typography>
                  <Box p={3} sx={{ width: 400, maxWidth: "100%", minHeight: 150 }}>
                    <TextField
                      fullWidth
                      label="Last Name"
                      variant="standard"
                      {...register("lastName", {
                        required: "Last Name is required.",
                        minLength: {
                          value: 2,
                          message: "Enter at least 2 characters.",
                        },
                        maxLength: {
                          value: 50,
                          message: "Maximum length is 50 characters.",
                        },
                        validate: {
                          hasNoNumber: (value) => {
                            if (/\d/.test(value)) {
                              return "Numbers are not allowed.";
                            }
                          },
                          hasNoIllegalCharacter: (value) => {
                            if (/[^\p{L} ,.'-]/u.test(value)) {
                              return `Allowed special characters: space, comma, period, apostrophe, hyphen.`;
                            }
                          },
                        },
                      })}
                      error={!!errors?.lastName}
                      helperText={errors?.lastName?.message}
                    />
                  </Box>
                </Box>
              )}

              {fieldToEdit === "shortName" && (
                <Box>
                  <Typography variant="h2" pb={2}>
                    Username
                  </Typography>

                  <Typography variant="subtitle2">
                    Your username appears on your Profile.
                  </Typography>
                  <Box p={3} sx={{ width: 400, maxWidth: "100%", minHeight: 150 }}>
                    <TextField
                      sx={{ width: "100%" }}
                      label="Username"
                      variant="standard"
                      InputLabelProps={{ shrink: !!watch("shortName") }}
                      {...register("shortName", {
                        required: "Username is required.",
                        maxLength: {
                          value: 50,
                          message: "Maximum length is 50 characters.",
                        },
                        pattern: {
                          value: /^[a-zA-Z0-9\s._-]*$/,
                          message: "Only letters, numbers, spaces '-', '_' and '.' are allowed.",
                        },
                      })}
                      error={!!errors?.shortName}
                      helperText={errors?.shortName?.message}
                    />
                  </Box>
                </Box>
              )}

              {fieldToEdit === "userIdentification" && (
                <Box>
                  <Typography variant="h2" pb={2}>
                    Tell us about yourself
                  </Typography>
                  <Typography variant="subtitle2">
                    Which of the following best describes you?
                  </Typography>
                  <Box
                    p={3}
                    sx={{
                      width: 400,
                      maxWidth: "100%",
                      minHeight: 150,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="subtitle1" mr={1}>
                      I am
                    </Typography>
                    <TextField
                      select
                      onChange={onIdentificationChange}
                      value={watch("userIdentification") || ""}
                      sx={{ minWidth: 150 }}
                      SelectProps={{
                        MenuProps: {
                          MenuListProps: {
                            sx: {
                              borderRadius: "5px",
                              border: "1px solid #cccccc",
                            },
                          },
                        },
                      }}
                      size="small"
                      error={!!errors?.userIdentification}
                      helperText={errors?.userIdentification?.message}
                    >
                      {userIdentifications.map((userIdentification) => (
                        <MenuItem key={userIdentification} value={userIdentification}>
                          {userIdentification}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Box>
                </Box>
              )}

              {fieldToEdit === "bio" && (
                <Box>
                  <Typography variant="h2" pb={2}>
                    Bio
                  </Typography>

                  <Typography variant="subtitle2">500 characters max</Typography>
                  <Box p={3} sx={{ width: 500, maxWidth: "100%", minHeight: 150 }}>
                    <TextField
                      sx={{ width: "100%" }}
                      multiline
                      maxRows={8}
                      variant="outlined"
                      InputProps={{
                        sx: {
                          paddingBottom: "32px",
                        },
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            sx={{ position: "absolute", right: 16, bottom: 16 }}
                          >
                            {watch("bio").length}/500
                          </InputAdornment>
                        ),
                      }}
                      {...register("bio", {
                        validate: {
                          maxRows: (value) =>
                            value.split("\n").length <= 12 || "Maximum of 12 lines.",
                        },
                        maxLength: {
                          value: 500,
                          message: "Max 500 characters.",
                        },
                      })}
                      error={!!errors?.bio}
                      helperText={errors?.bio?.message}
                    />
                  </Box>
                </Box>
              )}

              {isInterests && (
                <Box sx={{ mx: { xs: -4, sm: 0 } }}>
                  <Typography variant="h2" pb={2}>
                    What are you interested in?
                  </Typography>

                  <Typography variant="subtitle2"> Help us create more of what you like</Typography>
                  <Box
                    p={3}
                    sx={{ width: 550, maxWidth: "100%", minHeight: 150 }}
                    display="flex"
                    flexWrap="wrap"
                    justifyContent="center"
                  >
                    {allInterests ? (
                      allInterests.map((interest, index) => {
                        const isCurrentlySelected = watch("interests").indexOf(interest) > -1;

                        return (
                          <Chip
                            key={interest}
                            color={isCurrentlySelected ? "primary" : "default"}
                            label={interest}
                            clickable
                            sx={{
                              marginBottom: { xs: 1, sm: 2 },
                              height: { xs: 24, sm: 32 },
                              span: {
                                paddingLeft: { xs: 1, sm: 1.5 },
                                paddingRight: { xs: 1, sm: 1.5 },
                              },
                            }}
                            onClick={() => onInterestToggle(interest, isCurrentlySelected)}
                          />
                        );
                      })
                    ) : (
                      <CircularProgress />
                    )}
                  </Box>
                </Box>
              )}
              {isAvatarImage && (
                <Box>
                  <Typography variant="h2" pb={2}>
                    Choose your avatar image
                  </Typography>

                  <Typography variant="subtitle2"> Upload a custom user profile image.</Typography>
                  {fileToUpload && (
                    <>
                      <Box
                        mt={3}
                        p={0}
                        sx={{
                          width: AVATAR_WIDTH,
                          height: AVATAR_HEIGHT,
                          marginLeft: "auto",
                          marginRight: "auto",
                          border: "1px dashed",
                        }}
                      >
                        <img
                          src={previewURL}
                          alt="to upload"
                          style={{
                            objectFit: "contain",
                            alignSelf: "center",
                            width: "100px",
                            height: "100px",
                            margin: "0",
                            padding: "0",
                          }}
                        />
                      </Box>
                      {isUploading && <LinearProgress />}
                    </>
                  )}
                  <Box
                    px={3}
                    my={2}
                    // sx={{ width: 64, height: 64 }}
                    display="flex"
                    flexWrap="wrap"
                  >
                    {fileToUpload ? (
                      <Stack sx={{ marginLeft: "auto", marginRight: "auto" }}>
                        <Typography variant="caption">{fileToUpload.name}</Typography>
                      </Stack>
                    ) : (
                      <input type="file" onChange={onFileChange} accept=".png,.jpg,image/*" />
                    )}
                  </Box>
                </Box>
              )}
              <Box px={3} mb={2} display="flex" flexWrap="wrap" sx={{ justifyContent: "center" }}>
                <Button
                  type="submit"
                  variant="contained"
                  disabled={(!isDirty || (isSubmitted && !isValid)) && !fileToUpload}
                  sx={{ width: 300, maxWidth: "100%" }}
                >
                  Update
                </Button>
              </Box>
              {isAvatarImage && (
                <Box px={3} my={2} display="flex" flexWrap="wrap">
                  <Button
                    variant="contained"
                    disabled={!user?.avatarImage}
                    onClick={onFileRemove}
                    sx={{ width: 300, maxWidth: "100%" }}
                  >
                    Remove
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        </form>
      </Dialog>
    </>
  );
};
