import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import {
  authDomain,
  authClientId,
  authRedirectUri,
  authReturnTo,
  authLogoutLocation,
} from "constants";
import { useAnalytics } from "providers";

const onRedirectCallback = async (appState) => {
  if (appState?.returnTo) {
    const current = window.location.href;
    // we don't need to redirect through the /go route after login because we have a path in appState.returnTo
    // const newTarget = appState.returnTo ? `${window.location.origin}/go?deskReturnTo=${appState.returnTo}` : current;
    const newTarget = appState.returnTo || current;
    if (newTarget !== current) {
      console.log(">>>> onRedirectCallback (new):", appState, " >> ", newTarget);
      // Do we need to preserve anything else in appState?
      // delete appState.returnTo;
      // window.history.replaceState(appState, null, newTarget);
      window.history.replaceState(null, null, newTarget);
      // we must change the href in order for the browser to restart the LandingProvider from the beginning
      window.location.href = newTarget;
    } else {
      console.log(">>>> onRedirectCallback (same):", appState);
    }
  }
};

export const useDeskAuth = () => {
  const { logout, loginWithRedirect } = useAuth0();
  const analytics = useAnalytics();

  const deskLogout = ({ to = "" } = {}) => {
    // This variant of logout() is usually called directly from an event rather than code.
    if (analytics) analytics.track("logout");
    const returnTo = to || authLogoutLocation;
    logout({ returnTo });
  };
  const deskLogoutTo = (returnToURL) => {
    if (analytics) analytics.track("logout");
    // This variant of logout() is called from code and is passed a returnTo URL.
    let returnTo = returnToURL || authLogoutLocation;
    logout({ returnTo });
  };

  const deskLogin = ({ returnToUrl, trySilent } = {}) => {
    if (trySilent) {
      loginWithRedirect({
        appState: { returnTo: window.location.href },
      });
    } else {
      const returnTo = returnToUrl
        ? `${window.location.origin}/go?deskReturnTo=${returnToUrl}`
        : authReturnTo;

      loginWithRedirect({
        prompt: "select_account",
        appState: { returnTo },
      });
    }
  };

  const deskLoginTrySilent = () => {
    deskLogin({ trySilent: true });
  };

  return {
    deskLogin,
    deskLogout,
    deskLogoutTo,
    deskLoginTrySilent,
  };
};

const AuthProvider = ({ children, setRedirectCallbackRan }) => {
  return (
    <Auth0Provider
      domain={authDomain}
      clientId={authClientId}
      redirectUri={authRedirectUri}
      onRedirectCallback={(appState) => {
        setRedirectCallbackRan(true);
        onRedirectCallback(appState);
      }}
      cacheLocation="localstorage"
    >
      {children}
    </Auth0Provider>
  );
};

export default AuthProvider;
