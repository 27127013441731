import { Box, Dialog, IconButton, Typography, Stack, Button } from "@mui/material";
import { Link } from "react-router-dom";
import { EXTERNAL_HREFS, hasQuestsEnabled } from "constants";
import CloseIcon from "@mui/icons-material/Close";

import { InlineButtonLink } from "components";
import { CoinDesk, Desk } from "icons";

export const InsufficientBalance = ({ open, close }) => {
  return (
    <Dialog open={open} keepMounted onClose={close}>
      <Box
        sx={{ width: 550, maxWidth: "100%" }}
        px={{ xs: 3, sm: 6, md: 9 }}
        pt={9}
        pb={5}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <IconButton color="dark" sx={{ position: "absolute", top: 24, right: 24 }} onClick={close}>
          <CloseIcon />
        </IconButton>
        <Typography variant="h1">Earn more to transfer</Typography>
        <Box my={2}>
          <CoinDesk sx={{ fontSize: 64 }} />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Desk
            color="dark"
            sx={{
              backgroundColor: "primary.main",
              borderRadius: "50%",
              width: 20,
              height: 20,
              marginRight: 0.5,
            }}
          />
          <Typography variant="h2">≥ 4</Typography>
        </Box>

        <Typography pt={3} pb={1}>
          You need a balance of at least 4 DESK™ to transfer into your wallet.
        </Typography>
        <Typography pt={1} pb={1}>
          If you've already completed a transfer but can't see the tokens in your wallet, check out
          our troubleshooting tips.
        </Typography>
        <Stack spacing={2} py={2} width={250}>
          {hasQuestsEnabled ? (
            <Button
              component={Link}
              variant="outlined"
              color="contrastInvert"
              to="/quest"
              sx={{ width: "100%" }}
            >
              View more ways to earn
            </Button>
          ) : (
            <Button
              variant="outlined"
              color="contrastInvert"
              sx={{ width: "100%" }}
              href={EXTERNAL_HREFS["consensus_magazine"]}
            >
              View more ways to earn
            </Button>
          )}
          <Button
            component={Link}
            variant="outlined"
            color="contrastInvert"
            sx={{ width: "100%" }}
            to="/edu/overview/transfers"
          >
            View troubleshooting tips
          </Button>
        </Stack>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          pt={2}
        >
          <Typography>Still having issues?</Typography>
          <InlineButtonLink component="a" href={EXTERNAL_HREFS.support} target="_blank">
            Get help
          </InlineButtonLink>
        </Box>
      </Box>
    </Dialog>
  );
};
