import SvgIcon from "@mui/material/SvgIcon";

export const Coinbase = (props) => (
  <SvgIcon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 291.3 291.3">
      <g transform="translate(-471 -134.3)">
        <circle cx="616.7" cy="280" r="145.7" fill="#0052ff" />
        <path d="M667 289.5a51 51 0 1 1 0-19h51.3a101.8 101.8 0 1 0 0 19z" fill="#ffffff" />
      </g>
    </svg>
  </SvgIcon>
);
