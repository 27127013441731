import CloseIcon from "@mui/icons-material/Close";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Button, Modal, Stack, Typography } from "@mui/material";
import { styled } from "@mui/system";

import { calcWalletIcon, calcWalletName } from "utils";

import { CoinDesk, Desk } from "icons";
import { useEffect, useState } from "react";
import { useAccount } from "wagmi";
import { hasConsensusHub } from "constants";

const modalStyle = {
  position: "absolute",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "flex-start",
  textAlign: "center",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: { sm: "560px" },
  bgcolor: "background.paper",
  borderRadius: "5px",
  p: "56px",
  padding: { xs: "26px", sm: "56px" },
  paddingTop: { xs: "56px" },
  width: { xs: "100%" },
  minHeight: { xs: "100%", sm: "574px" },
};

export const TransferModal = ({ open, close, balance, withdraw, walletIcon, inProgress }) => {
  const [loading, setLoading] = useState(false);
  const { connector } = useAccount();

  useEffect(() => {
    setLoading(inProgress);
  }, [inProgress]);

  return (
    <Modal
      open={open}
      onClose={close}
      aria-labelledby="connect-wallet"
      aria-describedby="connect-wallet-options"
    >
      <Box sx={modalStyle}>
        <Box sx={{ position: "absolute", top: "0", right: "0", padding: "24px" }}>
          <CloseIcon onClick={close} sx={{ cursor: "pointer" }}></CloseIcon>
        </Box>
        {balance ? (
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                minHeight: "462px",
              }}
            >
              <Box>
                <Typography sx={{ pb: "26px" }} id="modal-modal-title" variant="h1" component="h1">
                  Transfer Balance
                </Typography>
                <Typography sx={{ pb: "26px" }} id="modal-modal-description" variant="subtitle2">
                  Transfer your DESK™ tokens from CoinDesk to your wallet to use them in the
                  marketplace or Discord community
                </Typography>
                <Typography id="modal-modal-description" variant="subtitle2">
                  Transfers can take up to 5 minutes
                </Typography>
              </Box>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-evenly"
                sx={{ width: "100%" }}
              >
                <CoinDesk fill={hasConsensusHub ? "#ffffff" : "#262626"} sx={{ fontSize: 64 }} />
                <MoreHorizIcon sx={{ fontSize: 64 }} />
                {/* <WalletConnect sx={{ fontSize: 64 }} /> */}
                <Box sx={{ "*": { fontSize: 64 } }}>
                  {calcWalletIcon(calcWalletName(connector))}
                </Box>
              </Stack>
              <Box>
                <div id="recaptcha-v2" className="g-recaptcha" />
              </Box>
              <Box>
                <LoadingButton
                  variant="outlined"
                  color="contrastInvert"
                  fullWidth
                  loading={loading}
                  onClick={(e) => withdraw(e)}
                >
                  Transfer
                </LoadingButton>
              </Box>
              <Box>
                <Typography sx={{ py: 0, my: 0 }} variant="caption">
                  This site is protected by reCAPTCHA and the
                  <br />
                  Google{" "}
                  <a
                    href="https://policies.google.com/privacy"
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: "#1a8917" }}
                  >
                    Privacy Policy
                  </a>
                  &nbsp; and{" "}
                  <a
                    href="https://policies.google.com/terms"
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: "#1a8917" }}
                  >
                    Terms of Service
                  </a>{" "}
                  apply.
                </Typography>
              </Box>
            </Box>
          </>
        ) : (
          <>
            <Box>
              <Typography sx={{ pb: "34px" }} id="modal-modal-title" variant="h1" component="h1">
                No Balance to Transfer
              </Typography>
              <Typography id="modal-modal-description">
                Collect your Daily DESK™ or visit the Quests page to earn more.
              </Typography>
              <IconWrapper sx={{ mr: "0" }}>
                <Desk sx={{ fontSize: "120px" }} />
              </IconWrapper>
              <Button variant="outlined" sx={{ width: "100%" }} onClick={close}>
                OK
              </Button>
            </Box>
          </>
        )}
      </Box>
    </Modal>
  );
};

const IconWrapper = styled("span")((props) => ({
  display: "inline-block",
  color: "dark",
  height: "120px",
  width: "120px",
  borderRadius: "50%",
  margin: "120px 0 120px 0",
  backgroundColor: props.theme.palette.primary.main,
}));
