import React, { useState, useEffect, useContext, useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { allInterests, apiUrl } from "constants";
import { useSubscribe } from "hooks/useSailthru";
import { readCookie } from "utils";
import { useCoinviseAPI } from "hooks";

const initialUserContext = {
  isAuthenticated: false,
  isError: false,
  isLoading: true,
  isAuth0Loading: true,
  isLoggedIn: false,
  isEmailVerified: false,
  token: "",
  user: {
    id: "",
    firstName: "",
    lastName: "",
    shortName: "",
    bio: "",
    avatarImage: "",
    knowledge: 0,
    userIdentification: "",
    interests: [],
    confirmedAt: 0,
    progress: 0,
    balance: null,
  },
  account: {
    email: "",
    provider: "",
  },
  setUser: {
    id: () => {},
    firstName: () => {},
    lastName: () => {},
    shortName: () => {},
    bio: () => {},
    avatarImage: () => {},
    knowledge: () => {},
    interests: () => {},
    confirmedAt: () => {},
    userIdentification: () => {},
    progress: () => {},
  },
  api: {
    login: () => {},
    profilePatch: () => {},
    profileDelete: () => {},
    resendEmail: () => {},
    acceptTerms: () => {},
    getAllUsers: () => {},
    patchUser: () => {},
    verifyCaptchaV3: () => {},
    verifyCaptchaV2: () => {},
    getConfig: () => {},
    setConfig: () => {},
    getUploadPath: () => {},
  },
  setIsError: () => {},
};

const UserContext = React.createContext(initialUserContext);

export const useUser = () => useContext(UserContext);

const UserProvider = ({ children }) => {
  const {
    isAuthenticated,
    isLoading: isAuth0Loading,
    getIdTokenClaims,
    error: errorAuth0,
  } = useAuth0();

  //data
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isClaimsLoading, setIsClaimsLoading] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [token, setToken] = useState("");
  const [permissions, setPermissions] = useState(false);

  //account
  const [email, setEmail] = useState("");
  const [sub, setSub] = useState("");
  const [provider, setProvider] = useState("");

  //user
  const [id, setId] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [shortName, setShortName] = useState("");
  const [bio, setBio] = useState("");
  const [knowledge, setKnowledge] = useState(0);
  const [interests, setInterests] = useState([]);
  const [confirmedAt, setConfirmedAt] = useState(0);
  const [userIdentification, setUserIdentification] = useState("");
  const [progress, setProgress] = useState(0);
  const [balance, setBalance] = useState(null);
  const [avatarImage, setAvatarImage] = useState("");

  const authenticatedAxios = useCoinviseAPI(true);

  const { subscribe, ready } = useSubscribe({
    lists: ["SSO_Signup_C23_Discount"],
    source: "DESK",
    onSuccess: () => {
      console.log("sailthru_success");
    },
    onError: () => {
      console.log("sailthru_error");
    },
  });

  useEffect(() => {
    const isMoEngageSignup = readCookie("moengage_campaign");
    if (email && progress === 1 && isMoEngageSignup) {
      if (ready) {
        subscribe(email);
      }
    }
  }, [progress, email, ready, subscribe]);

  const apiRequest = useCallback(
    async (url, method, body, headers = {} ) => {
      let json = "{}";
      if (method === "GET") {
        json = undefined;
      } else if (body instanceof FormData) {
        json = body;
      } else {
        json = JSON.stringify(body);
      }
      const options = {
        method,
        headers: Object.entries(Object.assign({
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        }, headers)).reduce((acc, [key, value]) => {
            if (value !== undefined) {
              acc[key] = value;
            }
          return acc;
        }, {}),
        body: json,
      };

      try {
        let response = await fetch(url, options);
        const responseBody = await response.json();

        if (response.status === 503) {
          alert(responseBody.reason || responseBody.message);
        }

        if (!response.ok) {
          let err = new Error(
            `Error ${response.status}: ${
              responseBody?.message || response.statusText || responseBody.reason || "Unknown error"
            }`
          );
          err.status = response.status;
          throw err;
        }
        if (responseBody?.message && !responseBody?.success) {
          throw new Error(responseBody.message);
        }

        return responseBody;
      } catch (err) {
        setIsError(true);
        throw err;
      }
    },
    [token]
  );

  const CHECKIN_ROUTE = `${apiUrl}/api/checkin`;
  const PROFILE_ROUTE = `${apiUrl}/api/profile`;
  const RESEND_ROUTE = `${apiUrl}/api/profile/resend`;
  const TERMS_ROUTE = `${apiUrl}/api/terms`;
  const IDENTITY_STATS_ROUTE = `${apiUrl}/api/admin/stats/identity`;
  const IDENTITY_HISTORY_ROUTE = `${apiUrl}/api/admin/history/identity`;
  const REWARD_STATS_ROUTE = `${apiUrl}/api/admin/stats/reward`;
  const REWARD_HISTORY_ROUTE = `${apiUrl}/api/admin/history/reward`;
  const GEOSTATS_ROUTE = `${apiUrl}/api/admin/geostats`;
  const ALL_USERS_ROUTE = `${apiUrl}/api/admin/users`;
  const PATCH_USER_ROUTE = `${apiUrl}/api/admin/users/`;
  const VERIFY_CAPTCHA3_ROUTE = `${apiUrl}/api/wallet/v3`;
  const VERIFY_CAPTCHA2_ROUTE = `${apiUrl}/api/wallet/v2`;
  const TRANSFER_ROUTE = `${apiUrl}/api/wallet/withdraw`;
  const WALLET_CONNECT_ROUTE = `${apiUrl}/api/wallet/connect`;
  const REDEEM_ROUTE = `${apiUrl}/api/wallet/redeem`;

  const checkin = useCallback(() => apiRequest(CHECKIN_ROUTE, "POST"), [apiRequest, CHECKIN_ROUTE]);
  const profilePatch = useCallback(
    (body) => apiRequest(PROFILE_ROUTE, "PATCH", body),
    [PROFILE_ROUTE, apiRequest]
  );
  const profileDelete = (body) => apiRequest(PROFILE_ROUTE, "DELETE", body);
  const resendEmail = () => apiRequest(RESEND_ROUTE, "POST", {});
  const acceptTerms = (body) => apiRequest(TERMS_ROUTE, "PATCH", body);
  const getIdentityStats = () => apiRequest(IDENTITY_STATS_ROUTE, "GET");
  const getRewardStats = () => apiRequest(REWARD_STATS_ROUTE, "GET");
  const getIdentityHistory = (days) => apiRequest(IDENTITY_HISTORY_ROUTE + "?days=" + days, "GET");
  const getRewardHistory = (days) => apiRequest(REWARD_HISTORY_ROUTE + "?days=" + days, "GET");
  const getGeoStats = (days) => apiRequest(GEOSTATS_ROUTE + "?days=" + days, "GET");
  const connectWallet = (body) => apiRequest(WALLET_CONNECT_ROUTE, "POST", body);
  const searchAllUsers = (adminsOnly, pattern, start, count) =>
    apiRequest(
      ALL_USERS_ROUTE + `?search=${pattern}&skip=${start}&take=${count}&adminsOnly=${adminsOnly}`,
      "GET"
    );
  const patchUser = (id, body) => apiRequest(PATCH_USER_ROUTE + id, "PATCH", body);
  const verifyCaptchaV3 = (body) => apiRequest(VERIFY_CAPTCHA3_ROUTE, "POST", body);
  const verifyCaptchaV2 = (body) => apiRequest(VERIFY_CAPTCHA2_ROUTE, "POST", body);
  const transferWithdraw = (body) => apiRequest(TRANSFER_ROUTE, "POST", body);
  const getUploadPath = (body, headers = {}) => apiRequest(`${apiUrl}/upload`, "POST", body, headers);
  
  const getRedeem = (productId) => apiRequest(`${REDEEM_ROUTE}/${productId}`, "GET");
  const postRedeem = (productId, body = null) =>
    apiRequest(`${REDEEM_ROUTE}/${productId}`, "POST", body);

  const getConfig = (key) => apiRequest(`${apiUrl}/api/admin/config/${key}`, "GET");
  const setConfig = (key, value) =>
    apiRequest(`${apiUrl}/api/admin/config/${key}`, "PATCH", { value });

  const [serverVersion, setServerVersion] = useState("");

  useEffect(() => {
    // const sessionClaims = sessionStorage.getItem("auth0:claims");
    // if (sessionClaims) {
    //   const { email, email_verified, __raw } = JSON.parse(sessionClaims);

    //   setToken(__raw);
    //   setEmail(email);
    //   setIsEmailVerified(email_verified);
    // } else
    if (!isAuth0Loading && isAuthenticated) {
      getIdTokenClaims()
        .catch(() => setIsError(true))
        .then((claims) => {
          if (claims) {
            const { email, email_verified, sub, __raw } = claims;

            setToken(__raw);
            setEmail(email);
            setSub(sub);
            setIsEmailVerified(email_verified);
            setIsClaimsLoading(false);
            // console.log(`Sub: ${sub} User: ${email} verified: ${email_verified}`);

            // sessionStorage.setItem(
            //   "auth0:claims",
            //   JSON.stringify({
            //     email,
            //     email_verified,
            //     __raw,
            //   })
            // );
          }
        });
    }
  }, [isAuthenticated, isAuth0Loading, getIdTokenClaims, isLoading]);

  useEffect(() => {
    if (serverVersion) {
      console.log(`DESK API Server: ${serverVersion}`);
      console.log(`DESK API Server: ${apiUrl}`);
    }
  }, [serverVersion]);

  useEffect(() => {
    if (token && isEmailVerified) {
      checkin()
        .catch(() => setIsError(true))
        .then((profile) => {
          if (!profile) {
            setIsError(true);
            return;
          }
          const {
            server_version,
            user: {
              id,
              firstName,
              lastName,
              shortName,
              bio,
              avatarImage,
              knowledge,
              interests,
              confirmedAt,
              userIdentification,
              progress,
              adminLevel,
            },
            account: { subProvider: provider, email, sub },
          } = profile;

          // temporary or permanent fix for the interests
          const filteredInterests = interests.filter(
            (interest) => allInterests.indexOf(interest) !== -1
          );
          setPermissions(adminLevel > 0);
          setServerVersion(server_version);
          setId(id);
          setFirstName(firstName);
          setLastName(lastName);
          setShortName(shortName);
          setBio(bio);
          setAvatarImage(avatarImage);
          setKnowledge(knowledge);
          setInterests(filteredInterests);
          setProvider(provider);
          setEmail(email);
          setSub(sub);
          setConfirmedAt(confirmedAt);
          setUserIdentification(userIdentification);

          if (!authenticatedAxios) {
            setBalance(null);
          } else {
            try {
              authenticatedAxios.get(`${apiUrl}/api/transaction/user/balance`).then((res) => {
                setBalance(res?.data?.balance || "0");
              });
            } catch (error) {
              console.error("error getting balance:", error);
            }
          }

          if (progress === 0) {
            profilePatch({ progress: -1 }); // one off signUp detection for analytics
          } else {
            setProgress(progress);
          }

          setIsLoggedIn(true);
          setIsLoading(false);
        });
    }
  }, [checkin, token, isEmailVerified, profilePatch, authenticatedAxios]);

  const setUser = {
    id: (value) => setId(value),
    firstName: (value) => setFirstName(value),
    lastName: (value) => setLastName(value),
    shortName: (value) => setShortName(value),
    bio: (value) => setBio(value),
    avatarImage: (value) => setAvatarImage(value),
    knowledge: (value) => setKnowledge(value),
    interests: (value) => setInterests(value),
    confirmedAt: (value) => setConfirmedAt(value),
    userIdentification: (value) => setUserIdentification(value),
    progress: (value) => setProgress(value),
  };

  const value = {
    isAuthenticated,
    isError: isError || !!errorAuth0,
    isLoading,
    isAuth0Loading,
    isClaimsLoading,
    isLoggedIn: isLoggedIn,
    isEmailVerified,
    token,
    permissions,
    user: {
      id,
      firstName,
      lastName,
      shortName,
      bio,
      avatarImage,
      knowledge,
      interests,
      confirmedAt,
      userIdentification,
      progress,
      permissions,
      balance,
    },
    account: {
      email,
      sub,
      provider,
    },
    api: {
      checkin,
      connectWallet,
      profilePatch,
      profileDelete,
      resendEmail,
      acceptTerms,
      getIdentityStats,
      getIdentityHistory,
      getRewardStats,
      getRewardHistory,
      getGeoStats,
      searchAllUsers,
      patchUser,
      verifyCaptchaV2,
      verifyCaptchaV3,
      transferWithdraw,
      getConfig,
      setConfig,
      getUploadPath,
      getRedeem,
      postRedeem,
    },
    setUser,
    setIsError,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

// TODOS:
// 1. add ErrorBoundary
// 2. make this hook to call API from here instead of additional code in components
// 3. clean up coinvise code
// 4. clean up user isauthenticated & isloading states
// 5. make loading overlay a singleton with hook
// 6. analyze blocking time
// 7. sideload web3 providers?
// 8. prevent coinvise stuff from loading on: (1) email not verified, (2) not logged in, (3) terms not accepted

export default UserProvider;
