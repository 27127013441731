import { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { styled } from "@mui/system";
import {
  AppBar,
  Toolbar,
  Button,
  Divider,
  Stack,
  Box,
  Link,
  Menu,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import "./Navbar.css"

import useMediaQuery from "@mui/material/useMediaQuery";

import SearchIcon from "@mui/icons-material/Search";
import PersonIcon from "@mui/icons-material/Person";
import MenuIcon from "@mui/icons-material/Menu";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CloseIcon from "@mui/icons-material/Close";

import { DeskWord } from "components";
import { EXTERNAL_HREFS, publicUrl } from "constants";
import { useDeskAuth, useUser } from "providers";

const SUBMENU_ID = "submenu";
const SUBMENU_OVERLAY_ID = "submenu-overlay";

const NavButton = ({
  page,
  label,
  children,
  fontWeight = 700,
  color = "inherit",
  onClick,
  cornerButton = false,
  flexGrow = 1,
}) => (
  <Button
    component={page && Link}
    href={page && EXTERNAL_HREFS[page]}
    variant="contrastMatch"
    onClick={onClick}
    sx={{
      px: 3,
      height: "100%",
      borderRadius: 0,
      fontWeight,
      color,
      minWidth: "auto",
      maxWidth: cornerButton ? 64 : "auto",
      textTransform: "capitalize",
      flexGrow,
    }}
  >
    {children || label || page}
  </Button>
);

const NavDivider = ({ borderColor = "grey.200" }) => (
  <Divider
    orientation="vertical"
    flexItem
    variant="middle"
    sx={{ width: "1px", borderColor, margin: 0 }}
  />
);

const UserMenu = () => {
  const { isAuthenticated, isLoading: isProfileLoading } = useUser();
  const { deskLogin, deskLogout } = useDeskAuth();
  const [userMenuAnchor, setUserMenuAnchor] = useState(null);

  useEffect(() => {
    if (isProfileLoading || !isAuthenticated) return;
  }, [isAuthenticated, isProfileLoading]);

  const onUserMenuClosed = () => {
    setUserMenuAnchor(null);
  };

  const onLogoutClick = () => {
    onUserMenuClosed();
    deskLogout();
  };

  return (
    <>
      {isProfileLoading ? (
        <CircularProgress size="1rem" color="primary" />
      ) : (
        <>
          {isAuthenticated ? (
            <>
              <Button
                id="user-menu"
                color="contrastInvert"
                variant="contrastMatch"
                endIcon={<ArrowDropDownIcon />}
                aria-controls={userMenuAnchor ? "logged-in-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={userMenuAnchor ? "true" : undefined}
                onClick={(e) => setUserMenuAnchor(e.currentTarget)}
                sx={{
                  maxWidth: 240,
                  height: "100%",
                  borderRadius: 0,
                  color: "inherit",
                  padding: 0,
                  margin: 0,
                }}
              >
                  <StyledUser sx={{ marginBottom: "1px" }}>
                  <Box
                    sx={{
                      display: "inline",
                      maxWidth: "100%",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                    }}
                  >
                    My Account
                  </Box>
                </StyledUser>
              </Button>
              <StyledMenu
                id="logged-in-menu"
                anchorEl={userMenuAnchor}
                open={!!userMenuAnchor}
                onClose={onUserMenuClosed}
                MenuListProps={{
                  "aria-labelledby": "user-menu",
                }}
                sx={{ overflowY: "auto", overscrollBehavior: "contain" }}
              >
                  <MenuItem href="/profile" component={Link} selectedTab="profile" onClick={onUserMenuClosed}>
                  User Profile
                </MenuItem>
                <MenuItem component={Link} href="/account" onClick={onUserMenuClosed}>
                  Account Settings
                </MenuItem>
                <MenuItem component={Link} href="/desk" onClick={onUserMenuClosed}>
                  <DeskWord color="contrastInvert" />™ Hub
                </MenuItem>
                <Divider sx={{ marginTop: 0, marginBottom: 0 }} />
                <MenuItem onClick={onLogoutClick}>Logout</MenuItem>
              </StyledMenu>
            </>
          ) : (
            <Button color="primary" variant="contained" onClick={deskLogin}>
              Log In / Create Account
            </Button>
          )}
        </>
      )}
    </>
  );
};

const SubMenuLink = ({ onClick, linkTo, page, label, children }) => {
  const sx = {
    display: "flex",
    textTransform: "capitalize",
    "&:hover": { textDecoration: "underline" },
    textAlign: "left",
    justifyContent: "left",
  };

  const localLinkProps = !!linkTo && {
    component: RouterLink,
    to: linkTo,
    onClick,
    sx,
  };

  const externalLinkProps = !!page && {
    component: Link,
    href: EXTERNAL_HREFS[page],
    sx,
  };

  const buttonProps = !!onClick && {
    sx,
    onClick,
  };

  const labelProps = {
    sx: {
      ...sx,
      pointerEvents: "none",
    },
  };

  const props = localLinkProps || externalLinkProps || buttonProps || labelProps;

  return (
    <Button variant="link" color="dark" {...props}>
      {children || label || page}
    </Button>
  );
};

const SubMenu = ({ setSubMenuExpanded }) => {
  const lg = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const { deskLogin, deskLogout } = useDeskAuth();
  const { isAuthenticated } = useUser();

  return (
    <>
      <Box
        id={SUBMENU_OVERLAY_ID}
        display="flex"
        justifyContent="center"
        sx={{
          position: "absolute",
          left: 0,
          right: 0,
          top: "100%",
          height: "100vh",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          cursor: "pointer",
          overflow: "hidden",
          zIndex: 9901,
        }}
        onClick={() => setSubMenuExpanded(false)}
      ></Box>
      <Box
        id={SUBMENU_ID}
        display="flex"
        justifyContent="center"
        className="safari_nav_padding"
        sx={{
          position: "absolute",
          left: 0,
          right: 0,
          top: "100%",
          backgroundColor: "#222222",
          zIndex: 9902,
          // height:"auto",
          minWidth: "340px",
          maxHeight: "100vh",
          overflow: "auto",
          overscrollBehavior: "contain",
        }}
      >
        <Box sx={{
          color: "#ffffff",
          maxWidth: 1600,
          height: "auto",
          textAlign: "left",
        }} p={2}>
          <Stack
            sx={{ overflow: "hidden" }}
            height={ lg ? "320px" : "960px"}  // large enough for the main content of the submenu
            direction={{ xs: "column", lg: "row" }}
            spacing={2}
            alignItems="top"
            divider={
              lg ? (
                <NavDivider borderColor={"grey.700"} />
              ) : (
                <Divider sx={{ borderColor: "grey.700" }} />
              )
            }
          >
            {!lg && (
              <>
                <Box display="flex" flexDirection="column">
                  {isAuthenticated ? (
                    <>
                      <SubMenuLink
                        linkTo="/account"
                        label="My Account"
                        onClick={() => setSubMenuExpanded(false)}
                      />
                      <SubMenuLink linkTo="/desk" onClick={() => setSubMenuExpanded(false)}>
                        <DeskWord color="white" />™ Hub
                      </SubMenuLink>
                      <SubMenuLink label="Logout" onClick={deskLogout} />
                    </>
                  ) : (
                    <SubMenuLink label="Login" onClick={deskLogin} />
                  )}
                </Box>
              </>
            )}
            <Box display="flex" flexDirection="row" flexBasis={{ lg: "30%" }}>
              <Box display="flex" flexDirection="column" flexBasis="50%">
                <SubMenuLink page="prices" label="Crypto Prices" />
              </Box>
              <Box display="flex" flexDirection="column" flexBasis="50%">
                <SubMenuLink page="indices_cmi" label="CoinDesk Market Index" />
              </Box>
            </Box>
            <Box display="flex" flexDirection="row" flexBasis={{ lg: "30%" }}>
              <Box display="flex" flexDirection="column" flexBasis="50%">
                <SubMenuLink page="tv" label="TV & Videos" />
                <SubMenuLink page="newsletters" />
                <SubMenuLink page="podcasts" />
                <SubMenuLink page="consensus_magazine" label="Consensus Magazine" />
                <SubMenuLink page="learn" />
              </Box>

              <Box display="flex" flexDirection="column" flexBasis="50%">
                <SubMenuLink page="calculator" label="Bitcoin Calculator" />
                <SubMenuLink page="events" />
                <SubMenuLink page="indices" />
                <SubMenuLink page="about" />
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              flexBasis={{ lg: "30%" }}
            >
              <Box display="flex" flexDirection="column" flexBasis="50%">
                <SubMenuLink page="markets" />
                <SubMenuLink page="business" label="Finance" />
                <SubMenuLink page="tech" label="Technology" />
              </Box>
              <Box display="flex" flexDirection="column" flexBasis="50%">
                <SubMenuLink page="web3" />
                <SubMenuLink page="policy" />
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              flexBasis={{ lg: "30%" }}
            >
              {!lg && <Divider sx={{ borderColor: "grey.700", my: 2 }} />}
              <Box display="flex" flexDirection="column" flexBasis={{ xs: "100%", lg: "50%" }}>
                <SubMenuLink page="sponsored" label="Sponsored Content" />
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection={{ lg: "column" }}
              alignItems="left"
              justifyContent="top"
              flexBasis={{ lg: "20%" }}
              pb={{xs: 8, lg: 2}}
            >
              <Box pl={2}>
                <Box ml={-2}>
                  <SubMenuLink label="Upcoming Event" />
                </Box>

                <Link
                  href={EXTERNAL_HREFS["consensus23"]}
                  underline="hover"
                  sx={{ color: "white.main", fontWeight: 700 }}
                >
                  <Box>
                    <img src="/images/upcoming_event.jpg" alt="Upcoming Event" />
                  </Box>
                  Consensus 2023: Join The Most Important Conversation In Crypto And Web3
                </Link>
              </Box>
            </Box>
          </Stack>
        </Box>
      </Box>
    </>
  );
};

export const Navbar = () => {
  const lg = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const [subMenuExpanded, setSubMenuExpanded] = useState(false);

  useEffect(() => {
    const html = document.getElementsByTagName("html")[0];

    if (html) {
      html.style.overflowY = subMenuExpanded ? 'hidden' : 'inherit';
    }
  }, [subMenuExpanded]);

  return (
    <Box
      display="flex"
      alignItems="stretch"
      flexDirection="column"
      justifyContent="center"
      bgcolor="contrastMatch.main"
      sx={{
        position: "relative",
        zIndex: 1051,
        width: "100%",
      }}
      mb={lg ? 9 : 2}
    >
      <Box sx={{ width: "100%" }}>
        <AppBar color="secondary">
          <Toolbar
            sx={{
              alignItems: "stretch",
              "&&": { paddingLeft: 0, paddingRight: 0, minHeight: lg ? 58 : 45, height: lg? 58: 44 },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexBasis: "33%",
                justifyContent: "left",
                alignItems: "stretch",
              }}
            >
              <NavButton onClick={() => setSubMenuExpanded(!subMenuExpanded)} cornerButton>
                {subMenuExpanded ? (
                  <CloseIcon />
                ) : (
                  <MenuIcon />
                )}
              </NavButton>

              <NavDivider />
              {lg && (
                <>
                  <NavButton flexGrow={0} page="tv" label="TV & Videos" />
                  <NavDivider />
                  <NavButton flexGrow={0} page="newsletters" />
                  <NavButton flexGrow={0} page="podcasts" />
                </>
              )}
            </Box>

            <Box
              sx={{
                display: "flex",
                flexBasis: "33%",
                flexGrow: 2,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Link href={EXTERNAL_HREFS["coindesk"]}>
                <Button>
                  <NavLogo
                    src={`${publicUrl}/logo.svg`}
                    height="23"
                    width="122"
                    sx={{ width: { xs: 120, lg: 210 }, height: { xs: 23, lg: 40 } }}
                  />
                </Button>
              </Link>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexBasis: "33%",
                justifyContent: "right",
                alignItems: "center",
              }}
            >
              {lg ? (
                <>
                  <NavButton flexGrow={0} page="indices" />
                  <NavButton flexGrow={0} page="consensus23" label="Consensus" />
                  <NavDivider />
                  <UserMenu />
                </>
              ) : (
                <>
                  <NavDivider />
                  <StyledUser to="/" sx={{marginBottom: "4px"}} >
                    <PersonIcon />
                  </StyledUser>
                </>
              )}
              <NavDivider />
              <NavButton page="search" cornerButton>
                <SearchIcon />
              </NavButton>
            </Box>
            {subMenuExpanded && <SubMenu setSubMenuExpanded={setSubMenuExpanded} />}
          </Toolbar>
          <Divider sx={{ borderColor: "grey.200" }} />
          {lg && (
            <>
              <Toolbar
                sx={{ alignItems: "stretch", justifyContent: "stretch", "&&": { minHeight: 50 } }}
              >
                <Box display="flex" width="100%" justifyContent="stretch">
                  <NavButton fontWeight={400} page="markets" />
                  <NavButton fontWeight={400} page="business" label="Finance" />
                  <NavButton fontWeight={400} page="policy" />
                  <NavButton fontWeight={400} page="tech" label="Technology" />
                  <NavButton fontWeight={400} page="web3" />
                  <NavButton fontWeight={400} page="learn" />
                  <NavButton fontWeight={400} page="consensus_magazine" label="Consensus Magazine" />
                  <NavButton fontWeight={400} page="sponsored" label="Sponsored Content" />
                </Box>
              </Toolbar>{" "}
            </>
          )}
          <Divider sx={{ borderColor: "grey.200" }} />

          <Toolbar
            sx={{
              justifyContent: "stretch",
              "&&": { minHeight: 0, paddingLeft: 0, paddingRight: 0 },
            }}
          >
            <Button
              component={Link}
              href={EXTERNAL_HREFS["prices"]}
              variant="contained"
              color="contrastMatch"
              sx={{
                flexGrow: 1,
                fontWeight: { xs: 400, lg: 700 },
                py: 0.5,
              }}
            >
              Crypto Prices &nbsp; <ArrowForwardIcon />
            </Button>
            <NavDivider />
            <Button
              component={Link}
              href={EXTERNAL_HREFS["indices_cmi"]}
              variant="contained"
              color="contrastMatch"
              sx={{
                flexGrow: 1,
                fontWeight: { xs: 400, lg: 700 },
                py: 0.5,
              }}
            >
              CoinDesk Market Index &nbsp; <ArrowForwardIcon />
            </Button>
          </Toolbar>
          <Divider sx={{ borderColor: "grey.200" }} />
        </AppBar>
        <Toolbar /> {/* Empty Toolbar to push content down for absolute AppBar/Toolbar above. */}
      </Box>
    </Box>
  );
};

const NavLogo = styled("img")(
  ({ theme }) => `
  filter: ${theme.palette.mode === "dark" ? "invert(1) hue-rotate(180deg) brightness(3)" : "none"};
`
);

const StyledMenu = styled(Menu)(({ theme }) => {
  const dark = theme.palette.mode === "dark";
  return `
  .MuiPaper-root {
    border: 1px solid rgba(${dark ? "255,255,255,0.3" : "38, 38, 38, 0.3"});
    border-radius: 5px;
    box-shadow: ${dark ? "none" : "3px 3px 3px 2px rgba(0, 0, 0, 0.17)"};
  }
  .MuiList-root {
    margin: 0;
  }
  .MuiMenuItem-root {
    padding-left: 24px;
    padding-right:32px;
  }

`;
});

const StyledUser = styled(RouterLink)(() => {
  const lg = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  return `
  color: inherit;
  text-color: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-decoration: none;
  height: 44px;
  padding: 0 12px;
  gap: 0 10px;
  &:hover {
    background-color: ${lg ? 'transparent' : 'rgba(0, 0, 0, 0.04)'};
  }`;
});
