import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Dialog,
  Box,
  Typography,
  Stack,
  Button,
  Chip,
  MenuItem,
  TextField,
  useMediaQuery,
  useTheme,
  Paper,
  Grid,
  Collapse,
  styled,
  Backdrop,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

import { userIdentifications, allInterests, hasConsensusHub } from "constants";
import { useUser } from "providers";
import { LoadingOverlay } from "components/LoadingOverlay";
import { DeskWord } from "components/DeskWord";
import { calcGradient } from "utils";

export const AccountWizard = () => {
  const [isAccountWizardOpened, setIsAccountWizardOpened] = useState(true);
  const [currentStep] = useState(0);
  const [, setError] = useState(""); // not implemented

  const {
    user: { id: userId, userIdentification = "", interests = [] },
    setUser,
    api: { profilePatch },
  } = useUser();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      interests,
      userIdentification,
    },
  });

  useEffect(() => {
    register("interests");
    register("userIdentification", {
      required: "Please skip the form if you wish not to answer.",
    });
  }, [register]);

  const onInterestToggle = (interest, isCurrentlySelected) => {
    const updatedInterests = !isCurrentlySelected
      ? [...getValues("interests"), interest]
      : getValues("interests").filter((i) => i !== interest);

    setValue("interests", updatedInterests);
  };

  const onIdentificationChange = (e) => {
    setValue("userIdentification", e.target.value);
  };

  const onSubmitHandler = (data) => {
    const { interests, userIdentification } = data;
    if (userIdentification) {
      profilePatch({ userIdentification })
        .then(() => {
          setUser.userIdentification(userIdentification);
          setUser.interests(interests);
        })
        .catch((err) => {
          console.log("Error saving user identification/interests:", err.message);
          setError("We are sorry, but there was a problem updating your profile.");
        });
      onWizardClose(); //setCurrentStep(1);
    }

    if (interests.length > 0) {
      profilePatch({ interests: interests })
        .then(() => {
          setUser.userIdentification(userIdentification);
          setUser.interests(interests);
        })
        .catch((err) => {
          console.log("Error saving user profile:", err.message);
          setError("We are sorry, but there was a problem updating your profile.");
        });
    }
  };

  const onWizardClose = () => {
    setUser.progress(1);
    profilePatch({ progress: 1 });
    setIsAccountWizardOpened(false);
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));

  return (
    <>
      <Dialog
        open={isAccountWizardOpened}
        sx={{ zIndex: 1301, overflow: "scroll", overscrollBehavior: "contain" }}
        onClose={() => {}}
        fullScreen={isMobile}
        PaperComponent={
          isMobile
            ? Paper
            : ({ children }) => (
                <>
                  <Box
                    sx={{
                      position: "relative",
                      height: "100%",
                      mt: 2,
                    }}
                  >
                    <Box
                      display="flex"
                      sx={{
                        width: "100%",
                        justifyContent: "center",
                        mb: 2,
                      }}
                    >
                      <svg width="200" height="38">
                        <path
                          fill={hasConsensusHub ? "#ffffff" : "#262626"}
                          d="M56 26.8c-3.7 0-6-3.3-6-7.9 0-4.5 1.9-7.9 6-7.9 2.6 0 4.3 1.7 4.6 4h6.2A9.7 9.7 0 0 0 63 8a11.5 11.5 0 0 0-7-2.2c-7.5 0-12.3 5.6-12.3 13 0 7.5 5 13 12.3 13 3.5 0 6-1 8-2.9a9.8 9.8 0 0 0 3-6.3h-6.2c-.3 2.4-2.1 4.1-4.9 4.1ZM78.3 13a9 9 0 0 0-9.5 9.5c0 5.3 3.8 9.4 9.5 9.4a9 9 0 0 0 9.5-9.4 9 9 0 0 0-9.5-9.5Zm0 14.9c-2.5 0-3.8-2.2-3.8-5.4 0-3.3 1.3-5.5 3.8-5.5 2.4 0 3.8 2.2 3.8 5.5 0 3.2-1.4 5.4-3.8 5.4Zm17.6-16.8a3.1 3.1 0 0 0-4.4-4.4 3.1 3.1 0 0 0 4.4 4.4Zm-7.2 6.4h2.4v13.8h5.7V13.5h-8v4Zm22-4.5a6 6 0 0 0-5.5 3h-.1v-2.5h-5.5v17.8h5.7v-9.8c0-2.2 1.2-3.8 3.1-3.8 1.9 0 2.8 1.3 2.8 3.2v10.4h5.7V19.7c0-4-2.3-6.7-6.2-6.7Zm19.5-6.6h-10.6v25h10.2a13 13 0 0 0 7.6-2.3c2.8-2.2 4.3-5.7 4.3-10 0-7.7-4.6-12.7-11.5-12.7Zm-.8 19.7h-3.7V11.6h3.7c3.9 0 6 2.9 6 7.4 0 4.6-2 7.1-6 7.1Zm29.1-10.8a8.8 8.8 0 0 0-6-2.2c-5.5 0-9.2 4-9.2 9.4s3.6 9.5 9.5 9.5c2.3 0 4-.6 5.6-1.7 1.5-1 2.6-2.6 3-4.2h-5.6c-.5 1.1-1.4 1.8-3 1.8-2.3 0-3.6-1.5-4-3.9h12.9c0-3.7-1-6.7-3.2-8.7Zm-9.6 5.3c.4-2.1 1.5-3.5 3.6-3.5 1.9 0 3.2 1.4 3.4 3.5h-7Zm23.9-.4c-2.2-.4-4.2-.6-4.2-1.9 0-1 1-1.6 2.4-1.6 1.6 0 2.7.5 2.9 2h5.2c-.3-3.4-3-5.6-8-5.6-4.2 0-7.7 2-7.7 5.7 0 4.2 3.3 5 6.4 5.5 2.4.4 4.5.6 4.5 2.2 0 1.2-1 1.8-2.8 1.8-1.8 0-3-.9-3.2-2.7h-5.4c.2 4 3.4 6.4 8.7 6.4 4.6 0 8-2 8-6 0-4.5-3.6-5.3-6.8-5.8Zm20.2 0 6.3-6.7h-6.6l-5.3 6V6.4H182v25h5.5v-5.5l1.8-1.9 4.2 7.3h6.6l-7-11.1Zm-174.2.4a1.7 1.7 0 1 0 0-3.4 1.7 1.7 0 0 0 0 3.4Zm13.7 0a1.7 1.7 0 1 0 0-3.4 1.7 1.7 0 0 0 0 3.4Zm0 13.8a1.7 1.7 0 1 0 0-3.5 1.7 1.7 0 0 0 0 3.5Z"
                        />
                        <path
                          fill={hasConsensusHub ? "#ffffff" : "#262626"}
                          d="M37.5 4.8a5.1 5.1 0 0 0-8.8-3.2 4 4 0 0 1-2.9 1.3h-.4c-1.1 0-2.2-.5-3-1.3a5.1 5.1 0 0 0-7.3 0 4 4 0 0 1-3 1.3h-.3a4 4 0 0 1-3-1.3l-.4-.4a5.1 5.1 0 0 0-6.7.1 5.2 5.2 0 0 0-.2 7.5V9a4 4 0 0 1 1.3 3v.4c0 1-.5 2.2-1.3 3a5.2 5.2 0 0 0 0 7.3 4 4 0 0 1 1.3 3v.5A4 4 0 0 1 1.5 29C.5 30 0 31.3 0 32.9a5.1 5.1 0 0 0 8.8 3.3c.8-.8 1.8-1.3 3-1.3h.4a4 4 0 0 1 2.9 1.3 5.1 5.1 0 0 0 8.8-3.2 5.1 5.1 0 0 0-8.7-4c-.8.8-1.9 1.3-3 1.3h-.5c-1 0-2.1-.5-3-1.3a4.2 4.2 0 0 1-1.3-3v-.5c0-1.1.5-2.2 1.3-3a5.2 5.2 0 0 0 0-7.3 4.2 4.2 0 0 1-1.3-3v-.4c0-1.1.5-2.2 1.3-3 .9-.9 2-1.3 3-1.3h.5a4 4 0 0 1 3 1.3 5.1 5.1 0 0 0 7.3 0c.7-.9 1.8-1.3 3-1.3h.3a4 4 0 0 1 3 1.3 5 5 0 0 0 8.7-4Z"
                        />
                      </svg>
                    </Box>

                    <Paper sx={{ marginBottom: "56px" }}>{children}</Paper>
                  </Box>
                </>
              )
        }
        slots={{
          backdrop: hasConsensusHub ? StyledBackdropBox : LoadingOverlay,
        }}
      >
        <Box
          px={{ xs: 1, sm: 10 }}
          py={{ xs: 2, sm: 4 }}
          sx={{
            maxWidth: "757px",
            backgroundImage: calcGradient(hasConsensusHub ? "dark" : "light"),
          }}
          display="flex"
          alignItems="center"
          flexDirection="column"
        >
          {currentStep === 0 && (
            <form onSubmit={handleSubmit(onSubmitHandler)}>
              <Collapse
                in={!watch("userIdentification")}
                sx={{ display: { xs: "block", sm: "none" } }}
              >
                <Typography variant="h2" textAlign="center" py={2}>
                  Tell us about yourself
                </Typography>
              </Collapse>
              <Typography
                variant="h2"
                textAlign="center"
                py={2}
                sx={{ display: { xs: "none", sm: "block" } }}
              >
                Tell us about yourself
              </Typography>
              <Stack spacing={{ sm: 2 }} py={{ xs: 2, sm: 4 }} textAlign="center" maxWidth="100%">
                <Box maxWidth="100%">
                  <Box
                    sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
                  >
                    <Typography
                      component="span"
                      variant="subtitle2"
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      1/2 {!!watch("userIdentification") && <CheckIcon />}
                    </Typography>
                    {!!watch("userIdentification") && (
                      <>
                        <Typography variant="h5">{watch("userIdentification")}</Typography>
                        <Typography
                          variant="subtitle2"
                          onClick={() => setValue("userIdentification", "")}
                          sx={{ cursor: "pointer", "&:hover": { textDecoration: "underline" } }}
                        >
                          change
                        </Typography>
                      </>
                    )}
                  </Box>

                  <Collapse in={!watch("userIdentification")}>
                    <Typography textAlign="left">
                      Which of the following best describes you?
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      pt={2}
                    >
                      <Typography variant="subtitle1" mr={1}>
                        I am
                      </Typography>
                      <TextField
                        select
                        onChange={onIdentificationChange}
                        value={watch("userIdentification")}
                        sx={{ minWidth: 150 }}
                        SelectProps={{
                          MenuProps: {
                            sx: {
                              zIndex: 1302,
                            },
                            MenuListProps: {
                              sx: {
                                borderRadius: "5px",
                                border: "1px solid #cccccc",
                              },
                            },
                          },
                        }}
                        size="small"
                      >
                        {userIdentifications.map((userIdentification) => (
                          <MenuItem key={userIdentification} value={userIdentification}>
                            {userIdentification}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Box>
                    <Box pb={2}>
                      <Typography variant="subtitle2">
                        {errors?.userIdentification?.message}
                      </Typography>
                    </Box>
                  </Collapse>
                </Box>

                <Box pt={1}>
                  <Typography variant="subtitle2" textAlign="left">
                    2/2
                  </Typography>
                  <Typography textAlign="left">What topics interest you?</Typography>
                  <Box p={{ xs: 1, sm: 3 }} display="flex" flexWrap="wrap" justifyContent="center">
                    {allInterests.map((interest, index) => {
                      const isCurrentlySelected = watch("interests").indexOf(interest) > -1;

                      return (
                        <Chip
                          key={index}
                          color={isCurrentlySelected ? "primary" : "default"}
                          label={interest}
                          clickable
                          sx={{
                            marginBottom: { xs: 1, sm: 1.5, md: 2 },
                            height: { xs: 24, sm: 28, md: 32 },
                            span: {
                              paddingLeft: { xs: 1, sm: 1.5 },
                              paddingRight: { xs: 1, sm: 1.5 },
                            },
                          }}
                          onClick={() => onInterestToggle(interest, isCurrentlySelected)}
                        />
                      );
                    })}
                  </Box>
                </Box>

                <Box display="flex" justifyContent="center">
                  <Stack spacing={1}>
                    <Button type="submit" variant="contained" sx={{ width: 300, maxWidth: "100%" }}>
                      Next
                    </Button>
                    <Button
                      variant="outlined"
                      color="contrastInvert"
                      margin="narrow"
                      onClick={onWizardClose}
                      sx={{ width: 300, maxWidth: "100%" }}
                    >
                      Maybe Later
                    </Button>
                  </Stack>
                </Box>
              </Stack>
            </form>
          )}
          {currentStep === 1 && (
            <>
              <Typography variant="h2" textAlign="center" py={2}>
                Welcome to CoinDesk
              </Typography>
              <Stack spacing={{ xs: 1, sm: 2 }} py={{ xs: 2, sm: 4 }} maxWidth="100%">
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <img src="/images/wizard/1_you.png" alt="For You" width="62" height="62.5" />
                  </Grid>
                  <Grid item xs={9}>
                    <Typography variant="subtitle1">For You</Typography>
                    <Typography>
                      A personalized content feed specifically based on your interest
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <img src="/images/wizard/2_lists.png" alt="My Lists" width="62" height="62.5" />
                  </Grid>
                  <Grid item xs={9}>
                    <Typography variant="subtitle1">My Lists</Typography>
                    <Typography>Bookmark content and create your own lists</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <img src="/images/wizard/3_earn.png" alt="Earn Desk" width="62" height="69" />
                  </Grid>
                  <Grid item xs={9}>
                    <Typography variant="subtitle1">
                      Earn&nbsp;
                      <DeskWord />™
                    </Typography>
                    <Typography>
                      Get rewarded by engaging with content and special quests
                    </Typography>
                  </Grid>

                  <Grid item xs={3}>
                    <img
                      src="/images/wizard/4_rewards.png"
                      alt="Real Rewards"
                      width="62"
                      height="62.5"
                    />
                  </Grid>
                  <Grid item xs={9}>
                    <Typography variant="subtitle1">Real Rewards</Typography>
                    <Typography>
                      Use&nbsp;
                      <DeskWord />™ &nbsp;to purchase NFTs and physical items
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <img
                      src="/images/wizard/5_community.png"
                      alt="Tokenized Community"
                      width="47.5"
                      height="62.5"
                    />
                  </Grid>
                  <Grid item xs={9}>
                    <Typography variant="subtitle1">Tokenized Community</Typography>
                    <Typography>
                      Join an evolving community available only to&nbsp;
                      <DeskWord />™ &nbsp;holders
                    </Typography>
                  </Grid>
                </Grid>
              </Stack>
              <Button
                variant="contained"
                onClick={onWizardClose}
                sx={{ width: 300, maxWidth: "100%", my: { xs: 2, sm: 4 } }}
              >
                Explore Coindesk
              </Button>
            </>
          )}
        </Box>
      </Dialog>
    </>
  );
};

export default AccountWizard;

const StyledBackdropBox = styled(Backdrop)(({ theme }) => {
  const dark = theme.palette.mode === "dark";
  if (dark) {
    return `
    background-color: ${theme.palette.background.default};
    background-image: url("/Waves.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-width: 100vw;
    min-height: 100vh;
  `;
  }
});
